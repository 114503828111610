<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="card-toolbar">
        <ion-buttons slot="start">
          <ion-button @click="$router.go(-1)">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ $t('direccion') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <div style="margin-top: 5em">
        <div>
          <label
            ><h5>{{ $t('alias-de-direccion') }}</h5></label
          >
          <input
            v-model="address.name"
            :placeholder="$t('ej-casa-trabajo-casa-de-mis-padres')"
            type="text"
          />
        </div>

        <div>
          <label
            ><h5>{{ $t('direccion') }} *</h5></label
          >
          <input
            v-model="address.address_line_1"
            :placeholder="$t('calle') + '...'"
            type="text"
          />
        </div>

        <div>
          <label for="password"
            ><h5>{{ $t('nombre-y-apellido') + '*' }}</h5></label
          >
          <input
            v-model="address.client"
            :placeholder="$t('nombre-y-apellido')"
            type="text"
          />
        </div>

        <div>
          <label for="password_confirmation"
            ><h5>{{ $t('ciudad') }}</h5></label
          >
          <input v-model="address.city" :placeholder="$t('ciudad') + '*'" type="text" />
        </div>

        <div>
          <label for="address"
            ><h5>{{ $t('codigo-postal') }} *</h5></label
          >
          <input
            v-model="address.zip_code"
            :placeholder="$t('direccion')"
            name="address"
            type="text"
          />
        </div>

        <div style="margin-bottom: 22px">
          <label for="city"
            ><h5>{{ $t('telefono-de-contacto-para-la-entrega') }} *</h5></label
          >
          <input v-model="address.phone" :placeholder="$t('telefono')" type="text" />
        </div>
        <label style="margin-bottom: 35px; display: block; text-align: center"
          >* {{ $t('datos-obligatorios') }}</label
        >
      </div>

      <t-button
        :disabled="disabled"
        style="width: 90%; height: 43px; margin-bottom: 35px"
        @click="address.id ? updateAddress() : setAddress()"
        >{{ $t('guardar-direccion') }}</t-button
      >
      <t-button
        v-if="address.id"
        :disabled="addresses.length == 1"
        style="background: #e28e9d; width: 90%; height: 43px; margin-bottom: 35px"
        @click="deleteAddress()"
        >{{ $t('elimina-direccion') }}</t-button
      >

      <!-- <div style="display: flex; flex-direction: column;">
      <button>Regístrate con E-mail</button>
      <button>Regístrate con E-mail</button>
    </div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonContent, IonPage, toastController } from '@ionic/vue';
import {
  ellipse,
  square,
  triangle,
  chevronBack,
  closeCircleOutline,
} from 'ionicons/icons';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';

export default defineComponent({
  name: 'AddressPage',

  components: {
    IonPage,
    IonContent,
    TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      ellipse,
      square,
      disabled: ref(false),
      triangle,
      addresses: ref({}),
      address: ref({
        user_id: ref(timblo.user.id),
      }),
      timblo,
      chevronBack,
      closeCircleOutline,
    };
  },

  created() {
    this.getAddresses();
    this.init();
  },

  methods: {
    init() {
      const { id } = this.$route.params;
      if (id) this.getAddress(id);
    },

    getAddresses() {
      this.$axios.get('addresses').then(({ data }) => {
        this.addresses = data.data;
      });
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        message: `${header} ${message}`,
        position: 'top',
        color: color,
        duration: 3000,
        icon: closeCircleOutline,
      });

      await toast.present();
    },

    setAddress() {
      if (!this.address.name) {
        this.openToastOptions(
          'Error al añadir la dirección',
          'Tiene que introducir el Alias de la dirección para continuar.'
        );
      } else if (!this.address.address_line_1) {
        this.openToastOptions(
          'Error al añadir la dirección',
          'Tiene que introducir la Dirección.'
        );
      } else if (!this.address.city) {
        this.openToastOptions(
          'Error al añadir la dirección',
          'Tiene que introducir la Ciudad.'
        );
      } else if (!this.address.zip_code || this.address.zip_code?.length != 5) {
        this.openToastOptions(
          'Error al añadir la dirección',
          'Introduzca un Código postal valido para continuar.'
        );
      } else {
        this.disabled = true;
        this.$axios
          .post('/addresses', {
            address: {
              ...this.address,
            },
          })
          .then(({ data }) => {
            // this.$router.replace({ name:'address', params: {id: data.id}})
            this.timblo.user.current_address = data.current_address;
            this.disabled = false;
            if (this.addresses.length == 0) this.$router.go(-3);
            else this.$router.go(-1);
            this.init();
          })
          .catch(() => {
            this.disabled = false;
          });
      }
    },

    updateAddress() {
      this.$axios
        .put('/addresses', {
          address: {
            ...this.address,
          },
        })
        .finally(() => {
          this.$router.go(-1);
          // this.init()
        });
    },

    getAddress(address) {
      this.$axios.get(`/addresses/${address}`).then(({ data }) => {
        this.address = data;
      });
    },

    deleteAddress() {
      this.$axios.delete(`/addresses/${this.address.id}`).then(({ data }) => {
        this.timblo.user.current_address = data.current_address;
        this.$router.go(-1);
      });
    },
  },
});
</script>

<style scoped>
h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: var(--ion-color-primary);
}

h3 {
  color: var(--ion-color-primary);
}

h5 {
  margin-top: 20px !important;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

hr {
  border: 0.5px solid #d6d6d6 !important;
}

.itemAccount {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-top: 20px;
  align-items: center;
}
</style>
