<template>
  <div class="ion-padding">
    <ion-progress-bar
      style="height: 10px; border-radius: 5px"
      :value="slides?.progress || 0"
    />
  </div>

  <swiper @swiper="homeOnboarding" style="height: calc(100% - 10px)">
    <!-- MOVER A PANTALLA APARTE -->
    <swiper-slide v-if="false">
      <div style="width: 100%; padding: 16px">
        <h2>{{ $t('tu-suscripcion-esta-caducada') }}</h2>
      </div>
      <div style="width: 100%; padding: 16px">
        <p>{{ $t('al-pausar-el-servicio-timblo') }}</p>
        <p>{{ $t('reactiva-el-servicio') }}</p>
      </div>
      <div style="text-align: center">
        <img
          src="/assets/images/NoAddress.svg"
          style="width: max-content; max-width: 100%"
        />
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="slides.slideNext()">{{ $t('activar-suscripcion') }}</t-button>
        <p style="font-size: small">
          {{ $t('para-cualquier-informacion') }} <u>info@timblo.es</u>
        </p>
      </div>
    </swiper-slide>
    <!--=============================== CONGRATULATIONS =============================-->
    <swiper-slide v-if="this.timblo.user.oncological && !relatives" class="swiper-slide">
      <div style="width: 100%; padding: 0" v-if="this.timblo.user.oncological">
        <div style="margin: 0 32px 48px; text-align: justify; text-justify: inter-word">
          <div style="display: flex; justify-content: center">
            <img src="/assets/images/ventajas-alimentacion.svg" />
          </div>
          <p class="ion-text-center">
            <b>{{ $t('alimentacion-personalizada') }}</b>
          </p>
          <p class="ion-text-center">
            {{ $t('un-plan-completo-validado-por-tu-oncologo') }}
          </p>
          <div style="display: flex; justify-content: center">
            <img src="/assets/images/ventajas-entrenamiento.svg" />
          </div>
          <p class="ion-text-center">
            <b>{{ $t('informacion-validada') }}</b>
          </p>
          <p class="ion-text-center">{{ $t('explora-consejos-y-recursos') }}</p>
          <div style="display: flex; justify-content: center">
            <img src="/assets/images/ventajas-cuidado.svg" />
          </div>
          <p class="ion-text-center">
            <b>{{ $t('medicina-integrativa') }}</b>
          </p>
          <p class="ion-text-center">{{ $t('bloomi-te-acompaña') }}</p>
          <div
            class="fixed-footer"
            style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
          >
            <t-button @click="slides.slideNext()">{{ $t('empezar') }}</t-button>
          </div>
        </div>
      </div>
    </swiper-slide>

    <swiper-slide
      v-if="relatives == false && !this.timblo.user.oncological"
      class="swiper-slide"
    >
      <div style="width: 100%; padding: 0">
        <h1 style="color: var(--ion-color-primary)">{{ $t('que-te-ofrecemos') }}</h1>

        <div style="margin: 0 32px 48px; text-align: justify; text-justify: inter-word">
          <div style="text-align: center">
            <img
              src="/assets/onboarding/goals.svg"
              style="width: max-content; max-width: 100%"
            />
          </div>

          <p class="ion-text-center">
            {{
              $t(
                'fija-tus-objetivos-y-recibe-tu-plan-personalizado-para-conseguir-una-vida-mas-sana-y-saludable'
              )
            }}
          </p>

          <div style="text-align: center">
            <img
              src="/assets/onboarding/goals2.svg"
              style="width: max-content; max-width: 100%"
            />
          </div>

          <p class="ion-text-center">
            {{
              $t(
                'consulta-tu-plan-semanal-y-realiza-la-compra-con-recetas-sanas-y-nutricionalmente-equilibradas'
              )
            }}
          </p>

          <div style="text-align: center">
            <img
              src="/assets/onboarding/goals3.svg"
              style="width: max-content; max-width: 100%"
            />
          </div>

          <p class="ion-text-center">
            {{
              $t(
                'completa-los-datos-de-facturacion-y-dinos-donde-y-cuando-quieres-recibir-tu-pedido'
              )
            }}
          </p>

          <div style="text-align: center">
            <img
              src="/assets/onboarding/goals4.svg"
              style="width: max-content; max-width: 100%"
            />
          </div>

          <p class="ion-text-center">
            {{
              $t(
                'descubre-expertos-cerca-de-ti-que-te-ayudaran-a-alcanzar-tus-objetivos-de-forma-mas-rapida-y-eficaz'
              )
            }}
          </p>

          <div
            class="fixed-footer"
            style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
          >
            <t-button @click="slides.slideNext()">{{ $t('empezar') }}</t-button>
          </div>
        </div>
      </div>
    </swiper-slide>

    <!--=============================== CONGRATULATIONS =============================-->
    <swiper-slide
      v-if="relatives == false && !this.timblo.user.oncological"
      class="swiper-slide"
    >
      <div style="width: 100%; padding: 16px">
        <div>
          <img src="/assets/logo/logo.svg" />
        </div>

        <h1 style="color: var(--ion-color-primary)">{{ $t('enhorabuena') }}</h1>

        <div style="margin: 0 32px 48px; text-align: justify; text-justify: inter-word">
          <p>
            {{ $t('ahora-queremos-concocerte-un-poco-mas') }}
            {{ $t('estas-listo-para-iniciar-tu-camino') }}
          </p>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="slides.slideNext()">{{ $t('empezar') }}</t-button>
      </div>
    </swiper-slide>

    <!--=============================== ABOUT YOU =============================-->
    <swiper-slide class="swiper-slide">
      <div style="width: 100%; padding: 16px">
        <img
          @click="relatives ? $emit('closeSwiper') : slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('hablanos-de-ti') }}</h1>

        <div style="text-align: left; margin: 0 0 48px">
          <div v-if="relatives == true">
            <label
              ><h5>{{ $t('nombre') }}</h5></label
            >
            <ion-input
              v-model="mealplan.data.name"
              class="t-input"
              :placeholder="$t('nombre')"
              type="text"
            />
          </div>

          <div v-if="relatives == true">
            <label
              ><h5>{{ $t('apellido') }}</h5></label
            >
            <ion-input
              v-model="mealplan.data.last_name"
              class="t-input"
              :placeholder="$t('apellido')"
              type="text"
            />
          </div>

          <div>
            <label
              ><h5>{{ $t('sexo') }}</h5></label
            >
            <ion-select
              interface="action-sheet"
              v-model="mealplan.data.gender"
              class="t-input"
              :placeholder="$t('sexo')"
              :interface-options="{
                alignment: 'start',
              }"
            >
              <ion-select-option :value="2">{{ $t('hombre') }}</ion-select-option>
              <ion-select-option :value="1">{{ $t('mujer') }}</ion-select-option>
            </ion-select>
          </div>
          <div>
            <label
              ><h5>{{ $t('fecha-de-nacimiento') }}</h5></label
            >
            <ion-input
              v-model="mealplan.data.birth_date"
              class="t-input"
              type="date"
              :placeholder="$t('fecha-de-nacimiento')"
            />
          </div>
          <div>
            <label
              ><h5>{{ $t('altura-cm') }}</h5></label
            >
            <ion-input
              v-model="mealplan.data.height"
              class="t-input"
              :placeholder="$t('altura-cm')"
              type="tel"
              inputmode="numeric"
            />
          </div>
          <div>
            <label
              ><h5>{{ $t('peso-kg') }}</h5></label
            >
            <ion-input
              v-model="mealplan.data.weight"
              class="t-input"
              :placeholder="$t('peso-kg')"
              type="tel"
            />
          </div>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 1)">{{ $t('siguiente') }}</t-button>
      </div>
    </swiper-slide>

    <swiper-slide class="swiper-slide" v-if="this.timblo.user.oncological && !relatives">
      <div style="width: 100%; padding: 16px">
        <img
          @click="relatives ? $emit('closeSwiper') : slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('hablanos-de-ti') }}</h1>
        <p>
          <b>{{
            $t('timblo-es-un-servicio-personalizado-que-requiere-algunas-preguntas')
          }}</b>
          {{ $t('para-brindarte-la-mejor-ayuda') }}
          <b>{{ $t('no-compartimos-tu-informacion') }}</b>
          {{ $t('despues-de-registrate') }}
        </p>
        <div style="text-align: left; margin: 0 0 48px">
          <div>
            <label
              ><h5>{{ $t('que-tipo-de-cancer') }}</h5></label
            >

            <ion-select
              v-model="mealplan.cancer_types.selected"
              placeholder="Seleccione un tipo"
            >
              <ion-select-option
                v-for="(type, index) in mealplan.cancer_types.list"
                :key="index"
                :value="type.id"
              >
                {{ type.name }}
              </ion-select-option>
            </ion-select>
          </div>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 9)">{{ $t('siguiente') }}</t-button>
      </div>
    </swiper-slide>
    <!--=============================== GOAL =============================-->
    <swiper-slide class="swiper-slide" v-if="!this.timblo.user.oncological || relatives">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('elige-tu-objetivo') }}</h1>

        <p>{{ $t('que-quieres-conseguir') }}</p>
        <div style="width: 100%; margin: 0 0 48px">
          <ion-radio-group v-model="mealplan.goals.selected">
            <ion-item
              v-for="(goal, index) in mealplan.goals.list"
              :key="`goal-${index}`"
              lines="none"
              class="item-border"
            >
              <ion-label class="ion-text-wrap">{{
                goal?.name[timblo.lang] || goal?.name['es']
              }}</ion-label>
              <ion-radio slot="end" :value="goal.id"> </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 3)">{{ $t('siguiente') }}</t-button>
      </div>
    </swiper-slide>

    <swiper-slide class="swiper-slide" v-else>
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('elige-tu-objetivo') }}</h1>

        <h3>{{ $t('elige-tu-objetivo-principal') }}</h3>

        <div style="width: 100%; margin: 0 0 48px">
          <ion-radio-group v-model="mealplan.goals.selected">
            <ion-item
              v-for="(goal, index) in mealplan.goals.principales"
              :key="`goal-${index}`"
              lines="none"
              class="item-border"
            >
              <ion-label class="ion-text-wrap">{{
                goal?.name[timblo.lang] || goal?.name['es']
              }}</ion-label>
              <ion-radio slot="end" :value="goal.id"> </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>

        <h3>{{ $t('elige-tu-objetivo-secundario') }}</h3>
        <p>{{ $t('podras-adaptar-tus-dietas') }}</p>
        <div style="width: 100%; margin: 0 0 48px">
          <ion-item
            v-for="(goal, index) in mealplan.goals.secundarios"
            :key="`goal-${index}`"
            lines="none"
            class="item-border"
          >
            <!-- <ion-label class="ion-text-wrap">{{ goal?.name[timblo.lang] || goal?.name['es'] }}</ion-label>
        <ion-checkbox
          slot="end"
          :value="goal.id"
          :checked="isSelected(goal.id)"
          @ionChange="toggleSelected(goal.id)"
        ></ion-checkbox> -->
            <ion-label class="ion-text-wrap">{{
              goal?.name[timblo.lang] || goal?.name['es']
            }}</ion-label>
            <ion-radio slot="end" :value="goal.id"> </ion-radio>
          </ion-item>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 3)">{{ $t('siguiente') }}</t-button>
      </div>
    </swiper-slide>
    <!--=============================== FOOD TYPE =============================-->
    <swiper-slide class="swiper-slide">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('alimentacion') }}</h1>

        <div style="text-align: left; margin: 0 0 48px">
          <ion-radio-group v-model="mealplan.lifestyles.selected">
            <ion-item
              lines="none"
              v-for="(ls, index) in mealplan.lifestyles.list"
              :key="`ls-${index}`"
              class="item-border"
            >
              <ion-img
                :src="`assets/onboarding/${(
                  ls?.name[timblo.lang] || ls?.name['es']
                )?.toLowerCase()}.svg`"
                style="max-width: 100px"
                slot="start"
              ></ion-img>
              <ion-label>
                {{ ls.name[timblo.lang] || ls.name['es'] }}
              </ion-label>

              <ion-radio slot="end" :value="ls.id"> </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>

        <ion-accordion-group value="first" v-if="mealplan.lifestyles.selected == 2">
          <ion-accordion value="first">
            <ion-item lines="none" class="item-border" slot="header">
              <ion-label>{{ $t('preferencias') }}</ion-label>
            </ion-item>

            <template v-for="(subtype, index) in subtypes" :key="`ls-${index}`">
              <ion-item
                lines="none"
                slot="content"
                class="item-border"
                style="margin: 0 0 48px; margin: 0 10px 30px 10px"
              >
                <ion-label>
                  {{ subtype.text }}
                </ion-label>

                <ion-checkbox slot="end" v-model="subtype.active" />
              </ion-item>
            </template>
          </ion-accordion>
        </ion-accordion-group>
        <ion-item lines="none" class="item-border">
          <ion-label> {{ $t('anadir-alergias-e-intolerancias') }} </ion-label>

          <ion-checkbox
            slot="end"
            v-model="openAllergensModal"
            @ionChange="checkedAllergens"
          />
        </ion-item>
        <!-- <p>
          Si tienes alergias puedes configurarlas después desde:<br />
          Menu -> Familiares -> "Nombre" -> Alimentación -> Alergias
        </p> -->
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 2)">{{ $t('siguiente') }}</t-button>
      </div>
    </swiper-slide>

    <!--=============================== FOOD TIMES =============================-->
    <swiper-slide class="swiper-slide" v-if="!this.timblo.user.oncological">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary); margin-top: -0.5em">Dieta</h1>

        <p>{{ $t('cuantas-veces-sueles-comer-a-la-semana') }}</p>

        <div style="width: 100%; margin: 0 0 48px">
          <template v-for="(ft, index) in CopyFoodTimes" :key="`ft-${index}`">
            <ion-item
              lines="none"
              class="item-border"
              v-if="ft.flag.includes(mealplan.lifestyles.selected)"
            >
              <ion-label>{{ ft.label }}</ion-label>

              <ion-label slot="end" style="display: flex; align-items: center">
                <ion-button
                  fill="clear"
                  @click="
                    () => {
                      ft.value = Math.max(0, ft.value - 1);
                    }
                  "
                >
                  -</ion-button
                >
                {{ ft.value }}
                <ion-button
                  fill="clear"
                  @click="
                    () => {
                      if (ft.value < 7) {
                        ft.value += 1;
                      }
                    }
                  "
                  >+</ion-button
                >
              </ion-label>
            </ion-item>
          </template>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="slides.slideNext()" style="margin-top: -0.5em">{{
          $t('siguiente')
        }}</t-button>
      </div>
    </swiper-slide>

    <!--=============================== EXERCISE =============================-->
    <swiper-slide class="swiper-slide">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />

        <h1 style="color: var(--ion-color-primary)">{{ $t('ejercicio-a-la-semana') }}</h1>

        <p>{{ $t('cuantos-dias-a-la-semana-haces-ejercicio') }}</p>

        <div style="width: 100%; margin: 0 0 48px">
          <ion-radio-group v-model="mealplan.exercises.selected">
            <ion-item
              v-for="(exercise, index) in mealplan.exercises.list"
              :key="`exercise-${index}`"
              lines="none"
              class="item-border"
            >
              <ion-label>{{ exercise.label }}</ion-label>
              <ion-radio slot="end" :value="exercise.value"> </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>

        <t-button @click="checkSlideReqs(slides, 4)">{{ $t('siguiente') }}</t-button>
      </div>
    </swiper-slide>

    <!--=============================== ACTIVITY LEVELS =============================-->
    <swiper-slide class="swiper-slide">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />

        <h1 style="color: var(--ion-color-primary); margin-bottom: 20px">
          {{
            !this.timblo.user.oncological
              ? $t('como-es-tu-trabajo')
              : $t('como-es-tu-dia')
          }}
        </h1>

        <div style="width: 100%; margin: 0 0 48px">
          <ion-radio-group v-model="mealplan.levels.selected">
            <ion-item
              v-for="(level, index) in mealplan.levels.list"
              :key="`level-${index}`"
              lines="none"
              class="item-border"
            >
              <ion-label text-wrap>
                <div style="font-weight: bold">
                  {{ level?.name[timblo.lang] || level?.name['es'] }}
                </div>
                <div style="font-size: 12px">
                  {{ level?.description[timblo.lang] || level?.description['es'] }}
                </div>
              </ion-label>
              <ion-radio slot="end" :value="level.id"> </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 5)">{{ $t('siguiente') }}</t-button>
      </div>
    </swiper-slide>

    <!--=============================== CONFIGURE =============================-->
    <swiper-slide class="swiper-slide" v-if="!this.timblo.user.oncological">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('configura-el-servicio') }}</h1>

        <p>
          {{ $t('cuantas-comidas-a-la-semana-quieres-que-timblo-planifique-por-ti') }}
        </p>
        <p style="font-size: small; font-weight: 200">
          {{ $t('un-usuario-timblo-suele-pedir-una-media-de-3-cenas-0') }}
        </p>

        <div style="width: 100%; margin: 0 0 48px">
          <ion-item
            v-for="(fn, index) in mealplan.food_number"
            :key="`fn-${index}`"
            lines="none"
            class="item-border"
          >
            <ion-label>{{ fn.label }}</ion-label>

            <ion-label slot="end" style="display: flex; align-items: center">
              <ion-button fill="clear" @click="fn.value = Math.max(0, fn.value - 1)"
                >-</ion-button
              >
              {{ fn.value }}
              <ion-button
                fill="clear"
                @click="
                  () => {
                    if (fn.value < 7) {
                      fn.value += 1;
                      fn[index].value = fn.value;
                    }
                  }
                "
                >+</ion-button
              >
            </ion-label>
          </ion-item>
        </div>

        <p v-if="!relatives">{{ $t('para-cuantas-personas') }}</p>

        <div v-if="!relatives" style="width: 100%; margin: 0 0 48px">
          <ion-item
            v-for="(p, index) in mealplan.people"
            :key="`p-${index}`"
            lines="none"
            class="item-border"
          >
            <ion-label>{{ p.label }}</ion-label>

            <ion-label slot="end" style="display: flex; align-items: center; gap: 8px">
              <ion-button fill="clear" @click="p.value = Math.max(p.min, p.value - 1)"
                >-</ion-button
              >
              {{ p.value }}
              <ion-button
                fill="clear"
                @click="
                  () => {
                    if (p.value < 7) {
                      p.value += 1;
                    }
                  }
                "
                >+</ion-button
              >
            </ion-label>
          </ion-item>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 6, relatives)">{{
          relatives ? $t('terminar') : $t('siguiente')
        }}</t-button>
      </div>
    </swiper-slide>

    <swiper-slide class="swiper-slide" v-else>
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('configura-el-servicio') }}</h1>

        <p>
          {{ $t('cuantas-comidas-a-la-semana-quieres-que-timblo-planifique-por-ti') }}
        </p>

        <div style="width: 100%; margin: 0 0 48px">
          <ion-item
            v-for="(fn, index) in mealplan.food_number"
            :key="`fn-${index}`"
            lines="none"
            class="item-border"
          >
            <ion-label>{{ fn.label }}</ion-label>

            <ion-label slot="end" style="display: flex; align-items: center">
              <ion-button fill="clear" @click="fn.value = Math.max(0, fn.value - 1)"
                >-</ion-button
              >
              {{ fn.value }}
              <ion-button
                fill="clear"
                @click="
                  () => {
                    if (fn.value < 7) {
                      fn.value += 1;
                      fn[index].value = fn.value;
                    }
                  }
                "
                >+</ion-button
              >
            </ion-label>
          </ion-item>
        </div>
        <p>{{ $t('podras-modificar-el-numero-de-propuestas') }}</p>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="checkSlideReqs(slides, 6, relatives)">{{
          relatives ? $t('terminar') : $t('siguiente')
        }}</t-button>
      </div>
    </swiper-slide>
    <!--=============================== SUSCRIPCIÓN =============================-->
    <swiper-slide class="swiper-slide" v-if="this.timblo.user.oncological">
      <img
        @click="slides.slidePrev()"
        style="float: left; margin-left: 10px"
        src="assets/icons/ChevronLeft.svg"
      />
      <h1 style="color: var(--ion-color-primary)">{{ $t('suscripcion') }}</h1>
      <div style="width: 100%; padding: 16px">
        <p>{{ $t('suscripcion-header') }}</p>
        <!-- <div style="display:flex;justify-content:end; background-color:orange;text-color:white;">La mas conveniente</div> -->

        <div style="width: 100%; margin: 0 0 48px">
          <div style="display: flex; justify-content: flex-end">
            <div
              style="
                flex-shrink: 0;
                background-color: orange;
                color: white;
                font-size: 14px;
                border-radius: 15px;
                padding: 5px 14px;
              "
            >
              {{ $t('la-mas-conveniente') }}
            </div>
          </div>
          <ion-radio-group v-model="subscription_plan.selected">
            <ion-item
              v-for="(plan, index) in subscription_plan.list"
              :key="`plan-${index}`"
              lines="none"
              class="item-border"
            >
              <ion-label class="ion-text-wrap bold">{{ plan?.type }}</ion-label>
              <ion-label class="ion-text-wrap" slot="end"
                >{{ plan?.price }}€/{{ plan?.periodo }}</ion-label
              >
              <ion-radio slot="end" :value="plan.id"> </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>
        <p>{{ $t('suscripcion-footer') }}</p>
        <div
          class="fixed-footer"
          style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
        >
          <t-button @click="checkSlideReqs(slides, 8)">{{ $t('siguiente') }}</t-button>
        </div>
      </div>
    </swiper-slide>

    <swiper-slide class="swiper-slide" v-if="this.timblo.user.oncological">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('metodo-de-pago') }}</h1>

        <div>
          <label
            ><h5>{{ $t('titular-de-la-tarjeta') }} *</h5></label
          >
          <input
            v-model="paymentMethod.name"
            :placeholder="$t('titular-de-la-tarjeta')"
            type="text"
            style="width: 100%"
          />
        </div>

        <div>
          <label
            ><h5>{{ $t('numero-de-la-tarjeta') }} *</h5></label
          >
          <input
            v-model="paymentMethod.number"
            :placeholder="$t('numero-de-tarjeta')"
            type="tel"
            class="t-input"
            v-mask="'#### #### #### ####'"
            style="width: 100%"
          />
        </div>

        <div style="position: relative; width: 100%">
          <label for="password"
            ><h5>{{ $t('fecha-expiracion') }} *</h5></label
          >
          <div style="display: flex; align-items: center; width: 100%">
            <input
              v-model="paymentMethod.exp_month"
              placeholder="MM"
              type="tel"
              class="t-input"
              style="
                width: calc(50% - 2.5px);
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                margin-right: -1px;
                text-align: center;
              "
              maxlength="2"
            />
            <span style="margin: 0 5px">/</span>
            <input
              v-model="paymentMethod.exp_year"
              placeholder="YY"
              type="tel"
              class="t-input"
              style="
                width: calc(50% - 2.5px);
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-left: -1px;
                text-align: center;
              "
              maxlength="2"
            />
          </div>
        </div>
        <div>
          <label for="paymentMethod"
            ><h5>{{ $t('codigo-de-seguridad') }} *</h5></label
          >
          <input
            v-model="paymentMethod.cvc"
            :placeholder="$t('codigo-de-seguridad')"
            name="name"
            type="tel"
            class="t-input"
            v-mask="'####'"
            style="width: 100%"
          />
        </div>
        <br />
        <label style="margin-bottom: 35px; display: block; text-align: center"
          >* {{ $t('datos-obligatorios') }}</label
        >

        <t-button
          style="width: 90%; height: 43px; margin-left: 30px; margin-bottom: 35px"
          @click="checkSlideReqs(slides, 7)"
          >{{ $t('guarda-metodo-de-pago') }}</t-button
        >
      </div>
    </swiper-slide>
    <!-- checkSlideReqs(slides,7) -->
    <!--=============================== DONE =============================-->
    <swiper-slide
      v-if="relatives == false && !this.timblo.user.oncological"
      class="swiper-slide"
    >
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('genial') }}</h1>

        <div>
          <img src="/assets/images/done.svg" />
        </div>

        <div style="margin: 0 32px 48px; text-align: justify; text-justify: inter-word">
          <p v-if="timblo.user?.available">
            {{ $t('descubre-tu-plan-semanal-y-disfruta-de-tu-primer-pedido') }}
          </p>
          <p v-else>
            {{
              $t(
                'ahora-crea-tu-lista-de-la-compra-con-timblo-y-cuida-de-tu-estilo-de-vida'
              )
            }}
          </p>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="$emit('done', mealplan)">{{ $t('empezar') }}</t-button>
      </div>
    </swiper-slide>

    <swiper-slide v-if="this.timblo.user.oncological" class="swiper-slide">
      <div style="width: 100%; padding: 16px">
        <img
          @click="slides.slidePrev()"
          style="float: left; margin-left: 10px"
          src="assets/icons/ChevronLeft.svg"
        />
        <h1 style="color: var(--ion-color-primary)">{{ $t('genial') }}</h1>

        <div>
          <img src="/assets/images/done.svg" />
        </div>

        <div style="margin: 0 32px 48px; text-align: justify; text-justify: inter-word">
          <p>
            {{ $t('descubre-tu-plan-semanal-y-accede-a-consejos') }}
          </p>
        </div>
      </div>
      <div
        class="fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="$emit('done', mealplan)">{{ $t('empezar') }}</t-button>
      </div>
    </swiper-slide>
  </swiper>

  <ion-modal :is-open="birthdayModal" :initial-breakpoint="0.95">
    <ion-datetime displayFormat="YYYY-MMMM-D" pickerFormat="DD MM YYYY"></ion-datetime>
  </ion-modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  IonProgressBar,
  IonItem,
  IonButton,
  IonLabel,
  IonRadioGroup,
  IonAccordionGroup,
  IonAccordion,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonDatetime,
  toastController,
  alertController,
  IonCheckbox,
  loadingController,
} from '@ionic/vue';

import { informationCircle } from 'ionicons/icons';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';

import TButton from '@/components/TButton.vue';

import { useTimbloStore } from '@/stores/timblo';

export default defineComponent({
  name: 'TSwiper',

  props: {
    relatives: {
      type: Boolean,
    },
    mealplan_prop: {
      type: Object,
    },

    ageCheck: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    subtypes: {
      handler(val) {
        if (val) {
          this.subtypesSelected = this.subtypes
            .filter((s) => s.active)
            .map((t) => t.value);
        }
      },
      deep: true,
    },
  },

  components: {
    IonProgressBar,
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonButton,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonDatetime,
    IonCheckbox,
    SwiperSlide,
    Swiper,
    TButton,
  },

  setup() {
    const timblo = useTimbloStore();
    const { t } = useI18n();

    const slides = ref();
    const homeOnboarding = (swiper) => {
      // console.log(swiper);
      swiper.preventInteractionOnTransition = true;
      swiper.allowTouchMove = false;
      slides.value = swiper;
    };

    return {
      homeOnboarding,
      slides,
      informationCircle,
      timblo,

      subtypesSelected: [],
      CopyFoodTimes: ref({}),
      birthdayModal: ref(false),
      openAllergensModal: ref(false),
      paymentMethod: ref({
        user_id: ref(timblo.user.id),
      }),
      selectedTest: ref(null),
      subtypes: ref([
        { text: t('sin-huevos'), value: 3 },
        { text: t('con-pollo'), value: 1 },
        { text: t('con-pescado'), value: 2 },
        { text: t('con-moluscos'), value: 4 },
      ]),
      subscription_plan: ref({}),
      validWeight: ref(false),
      validHeight: ref(false),
      mealplan: ref({
        data: ref({
          gender: ref(null),
          height: ref(null),
          weight: ref(null),
        }),

        lifestyles: ref({
          selected: ref(null),
          list: ref([]),
        }),
        // [1,2,3] === ['Omnivoro', 'Vegetariano', 'Vegano', 'Carnivoro']
        food_times: ref([
          { label: t('carne'), value: 3, flag: [1, 4] },
          { id: [1], label: t('pollo'), value: 3, flag: [1] },
          { id: [2], label: t('pescado'), value: 3, flag: [1] },
          { id: [3], label: t('huevos'), value: 3, flag: [1, 2] },
          { label: t('proteinas-vegetales'), value: 3, flag: [1, 2, 3] },
          { label: t('legumbres'), value: 3, flag: [1, 2, 3] },
          { label: t('carbohidratos'), value: 3, flag: [1, 2, 3] },
          { id: [4], label: t('moluscos'), value: 3, flag: [1, 4] },
        ]),

        goals: ref({
          selected: ref(null),
          selectedSecondaryGoals: ref([]),
          list: [],
        }),

        exercises: ref({
          selected: ref(null),
          list: [
            { label: '0-1', value: 1 },
            { label: '1-2', value: 2 },
            { label: '2-3', value: 3 },
            { label: '4-5', value: 4 },
            { label: '6-7', value: 5 },
          ],
        }),

        levels: ref({
          selected: ref(null),
          list: [],
        }),

        food_number: ref([
          { label: t('Desayuno'), value: 0 },
          { label: t('comida'), value: 0 },
          { label: t('cena'), value: 0 },
          { label: t('snack'), value: 0 },
        ]),

        people: ref([
          { label: t('familiar'), value: 1, min: 1 },
          { label: t('ninos'), value: 0, min: 0 },
        ]),
        cancer_types: ref({
          selected: ref(null),
          list: [],
        }),
        subscription: ref({
          id_usuario: ref(null),
          id_plan: ref(null),
        }),
      }),
    };
  },

  created() {
    if (this.mealplan_prop) {
      this.mealplan.id = this.mealplan_prop.id;
      this.mealplan.data.name = this.mealplan_prop.name;
      this.mealplan.data.last_name = this.mealplan_prop.last_name;
      this.mealplan.data.gender = this.mealplan_prop.gender_id;
      this.mealplan.data.birth_date = this.$moment
        .unix(this.mealplan_prop.birthday)
        .format('YYYY-MM-DD');
      this.mealplan.data.height = this.mealplan_prop.height;
      this.mealplan.data.weight = this.mealplan_prop.weight;
    }
    this.getLifestyles();
    this.getGoals();
    this.getActivityLevels();
    this.getSubscriptionPlans();
    this.getCancerTypes();
    if (this.timblo.user.oncological) {
      for (let i = 0; i < this.mealplan.food_number.length; i++) {
        if (this.mealplan.food_number[i].label == 'Snack') {
          this.mealplan.food_number[i].value = 3;
        } else {
          this.mealplan.food_number[i].value = 7;
        }
      }
    }
  },

  computed: {
    unmaskedCard() {
      return {
        name: this.paymentMethod?.name,
        number: this.paymentMethod?.number?.replace(/ /g, ''),
        exp_month: this.paymentMethod?.exp_month,
        exp_year: this.paymentMethod?.exp_year,
        cvc: this.paymentMethod?.cvc,
      };
    },
  },

  methods: {
    checkParamsWeight(v) {
      v = v.replaceAll('.', '');
      if (v > 300) {
        this.openToastOptions(
          this.$t('peso-no-valido'),
          this.$t('el-peso-no-puede-ser-superior-a-300-kilos')
        );
        this.mealplan.data.weight = '300';
        return false;
      } else if (v < 15) {
        this.mealplan.data.weight = '15';
        this.openToastOptions(
          this.$t('peso-no-valido'),
          this.$t('el-peso-no-puede-ser-inferior-a-15-kilos')
        );
        return false;
      } else {
        this.mealplan.data.weight = v;
        return true;
      }
    },

    checkParamsHeight(v) {
      v = v.replaceAll('.', '');
      if (v > 300) {
        this.mealplan.data.height = '300';
        this.openToastOptions(
          this.$t('altura-no-valida'),
          this.$t(
            'la-altura-introducida-es-mayor-a-3-metros-vuelva-a-introducir-la-altura-en-cm'
          )
        );
        return false;
      } else if (v < 100) {
        this.openToastOptions(
          this.$t('altura-no-valida'),
          this.$t(
            'la-altura-introducida-es-menor-a-1-metro-vuelva-a-introducir-la-altura-en-cm'
          )
        );
        this.mealplan.data.height = '100';
        return false;
      } else {
        this.mealplan.data.height = v;
        return true;
      }
    },

    checkSlideReqs(slides, index, relatives = null) {
      switch (
        index // Indice del slide
      ) {
        case 1:
          if (
            (this.relatives && !this.mealplan.data.name) ||
            !this.mealplan.data.gender ||
            !this.mealplan.data.birth_date ||
            !this.mealplan.data.height ||
            !this.mealplan.data.weight
          ) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-rellenar-todos-los-campos-antes-de-continuar')
            );
          } else {
            if (!this.checkValidBirthDate()) {
              this.openToastOptions(
                this.$t('aviso'),
                this.$t('La fecha de nacimiento no es válida')
              );
            } else if (!this.checkBirthDate()&& this.ageCheck) {
              this.openToastOptions(
                this.$t('aviso'),
                this.$t('Debes ser mayor de edad para registrarte')
              );
            } else {
              this.validHeight = this.checkParamsHeight(this.mealplan.data.height);
              this.validWeight = this.checkParamsWeight(this.mealplan.data.weight);
              if (this.validHeight && this.validWeight) {
                slides.slideNext();
              }
            }
          }

          break;
        case 2:
          if (!this.mealplan.lifestyles.selected) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-seleccionar-una-opcion')
            );
          } else {
            this.CopyFoodTimes = {};
            this.CopyFoodTimes = JSON.parse(JSON.stringify(this.mealplan.food_times));
            this.subtypesSelected.forEach((element) => {
              if (element == 3) {
                this.CopyFoodTimes[3].flag.splice(1, 1);
              } else {
                this.CopyFoodTimes?.find((s) =>
                  s?.id == element ? s.flag.push(2) : null
                );
              }
            });

            slides.slideNext();
          }
          break;
        case 3:
          if (!this.timblo.user.oncological && !this.mealplan.goals.selected) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-seleccionar-una-opcion')
            );
          } else {
            if (
              this.timblo.user.oncological &&
              (!this.mealplan.goals.selected ||
                !this.mealplan.goals.selectedSecondaryGoals)
            ) {
              this.openToastOptions(
                this.$t('aviso'),
                this.$t('tiene-que-seleccionar-ambas-opciones')
              );
            } else {
              slides.slideNext();
            }
          }
          break;
        case 4:
          if (!this.mealplan.exercises.selected) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-seleccionar-una-opcion')
            );
          } else {
            slides.slideNext();
          }
          break;
        case 5:
          if (!this.mealplan.levels.selected) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-seleccionar-una-opcion')
            );
          } else {
            slides.slideNext();
          }
          break;
        case 6:
          if (relatives) {
            this.$emit('done', this.mealplan);
          } else {
            if (!this.mealplan.food_number.find((m) => m.value > 0)) {
              this.openToastOptions(
                this.$t('aviso'),
                this.$t('tiene-que-anadir-por-le-menos-una-de-las-comidas')
              );
            } else {
              this.mealplan.food_times = JSON.parse(JSON.stringify(this.CopyFoodTimes));
              slides.slideNext();
            }
          }
          break;
        case 7:
          if (
            !this.paymentMethod.name ||
            !this.paymentMethod.number ||
            !this.paymentMethod.exp_month ||
            !this.paymentMethod.exp_year ||
            !this.paymentMethod.cvc
          ) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-rellenar-todos-los-campos-antes-de-continuar')
            );
          } else {
            // Verificar si exp_date tiene el formato MM/YY válido
            const exp_month = this.paymentMethod.exp_month;
            const exp_year = this.paymentMethod.exp_year;

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear() % 100;

            if (
              isNaN(exp_month) ||
              isNaN(exp_year) ||
              exp_month < 1 ||
              exp_month > 12 ||
              exp_year < currentYear ||
              (exp_year === currentYear && exp_month < currentDate.getMonth() + 1)
            ) {
              this.openToastOptions(
                this.$t('aviso'),
                this.$t(
                  'Ingrese una fecha de expiración válida para la tarjeta de crédito.'
                )
              );
            } else {
              this.setPaymentMethod();
            }
          }
          break;
        case 8:
          if (!this.subscription_plan.selected) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-seleccionar-una-opcion')
            );
          } else {
            this.mealplan.subscription.id_plan = this.subscription_plan.selected;
            this.mealplan.subscription.id_usuario = this.timblo.user.id;
            slides.slideNext();
          }
          break;
        case 9:
          if (!this.mealplan.cancer_types.selected) {
            this.openToastOptions(
              this.$t('aviso'),
              this.$t('tiene-que-seleccionar-una-opcion')
            );
          } else {
            slides.slideNext();
          }
          break;
        default:
          break;
      }
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
    checkValidBirthDate() {
      const birthYear = new Date(this.mealplan.data.birth_date).getFullYear();
      return birthYear > 1900;
    },
    checkBirthDate() {
      const birthDateString = this.mealplan.data.birth_date;
      const birthDate = new Date(birthDateString);

      const currentDate = new Date();

      let ageDifference = currentDate.getFullYear() - birthDate.getFullYear();

      birthDate.setFullYear(currentDate.getFullYear());

      if (currentDate < birthDate) {
        ageDifference--;
      }

      const esMayorDeEdad = ageDifference >= 18;

      if (esMayorDeEdad) {
        return true;
      } else {
        return false;
      }
    },

    async checkedAllergens() {
      if (this.openAllergensModal) {
        const alert = await alertController.create({
          header: this.$t('timblo-te-cuida'),
          message: this.$t(
            'puedes-anadir-alergias-intolerancias-y-ingredientes-que-no-te-gusten-en-los-ajustes-de-tu-cuenta-cada-vez-que-cambiaras-los-ajustes-iremos-modificando-tu-plan-de-recetas-automaticamente'
          ),
          // TODO: Poner boton de cerrar el alert
          // buttons: [
          //   {
          //     text: '<ion-icon :icon="chevronBack"></ion-icon>',
          //     cssClass: 'closeAlertButton',
          //     role: 'cancel',
          //   }
          // ]
        });

        await alert.present();
      }
    },

    getLifestyles() {
      this.$axios.get('lifestyles').then(({ data }) => {
        this.mealplan.lifestyles.list = data;
      });
    },

    getGoals() {
      let filteredGoalsSecondary;
      let filteredGoalsMain;
      let filteredGoals;
      this.$axios.get('goals').then(({ data }) => {
        data = data.filter((g) => g.id != 6); // QUITAR SI MARCO QUIERE VOLVER A ACTIVAR TODOS LOS GOALS.
        if (this.timblo.user.oncological && !this.relatives) {
          filteredGoalsMain = data.filter(
            (goal) => goal.oncological == true && goal.main == true
          );
            filteredGoalsSecondary = data.filter(
            (goal) => goal.oncological == true && goal.main == false
          );
          this.mealplan.goals.principales = filteredGoalsMain;
          this.mealplan.goals.secundarios = filteredGoalsSecondary;
        } else {
            filteredGoals = data.filter(
            (goal) => goal.oncological == false && goal.main == true
          );

          this.mealplan.goals.list = filteredGoals;
        }


      });
    },

    getActivityLevels() {
      this.$axios.get('activity_levels').then(({ data }) => {
        console.log(data);

        if (this.timblo.user.oncological) {
          const filteredLevels = data.filter((level) => level.oncological == true);
          this.mealplan.levels.list = filteredLevels;
        } else {
          this.mealplan.levels.list = data;
        }
      });
    },

    getSubscriptionPlans() {
      this.$axios.get('subscription_plans').then(({ data }) => {
        this.subscription_plan.list = data;
      });
    },

    getCancerTypes() {
      this.$axios.get('cancer_types').then(({ data }) => {
        this.mealplan.cancer_types.list = data;
      });
    },

    async setPaymentMethod() {
      // this.slides.slideNext();
      const loading = await loadingController.create({
        message: 'Comprobando método de pago...',
      });

      loading.present();

      this.$axios
        .post('/payment_methods', {
          payment_method: {
            ...this.unmaskedCard,
          },
        })
        .then(async (response) => {
          if (response.data.status == 'OK') {
            this.slides.slideNext();
          } else {
            const error = await toastController.create({
              message: response.data.message,
              duration: 2000,
              position: 'top',
              color: 'danger',
            });

            await error.present();
          }
        })
        .catch(async ({ response }) => {
          const error = await toastController.create({
            message: response.data.message,
            duration: 2000,
            position: 'top',
            color: 'danger',
          });

          await error.present();
        })
        .finally(() => {
          loading?.dismiss();
        });
    },
    isSelected(id) {
      return this.mealplan.goals.selectedSecondaryGoals.includes(id);
    },
    toggleSelected(id) {
      const index = this.mealplan.goals.selectedSecondaryGoals.indexOf(id);
      if (index === -1) {
        this.mealplan.goals.selectedSecondaryGoals.push(id);
      } else {
        this.mealplan.goals.selectedSecondaryGoals.splice(index, 1);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.swiper-slide {
  align-items: start;
  overflow-y: scroll;
  display: block;
}

h1 {
  margin-top: 10px;
}

.exp-wrapper {
  position: relative;
  border: 1px solid #aaa;
  display: flex;
  width: 300px;
  justify-content: space-around;
  height: 36px;
  line-height: 36px;
  font-size: 24px;
}

.exp-wrapper:after {
  content: '/';
  position: absolute;
  left: 50%;
  margin-left: -4px;
  color: #aaa;
}

input.exp {
  float: left;
  font-family: monospace;
  border: 0;
  width: 18px;
  outline: none;
  appearance: none;
  font-size: 14px;
}
</style>
