<template>
<ion-page>
    <ion-content fullscreen>
          <img
          @click="this.$router.go(-1)"
          style="float:left; margin-top: 60px; margin-left: 20px" src="assets/icons/ChevronLeft.svg"/>

    <div style="margin: 120px 23px 0;">
      <h1>Hola {{timblo.user.name + ' ' +timblo.user.last_name}} en que podemos ayudarte?</h1>
    </div>

    <t-button style="font-size:14px; width: 90%; height: 59px; margin: 0px 24px 35px;" @click="this.$router.push({ name: 'help_questions' });">{{ $t('pedido-0') }}</t-button>
    <t-button style="font-size:14px; width: 90%; height: 59px; margin: 0px 24px 35px;" @click="null">{{ $t('pago') }}</t-button>
    <t-button style="font-size:14px; width: 90%; height: 59px; margin: 0px 24px 35px;" @click="null">{{ $t('entrega-0') }}</t-button>
    <t-button style="font-size:14px; width: 90%; height: 59px; margin: 0px 24px 35px;" @click="null">{{ $t('app') }}</t-button>
    <t-button style="background-color: #A1DEA0; font-size:14px; width: 90%; height: 59px; margin: 0px 24px 35px;" @click="null">{{ $t('me-encantaria-si') }}</t-button>
    </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { ellipse, square, triangle } from 'ionicons/icons';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';


export default defineComponent({
  name: 'HelpPage',

  components: {
    IonPage,
    IonContent,
    TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      ellipse,
      square,
      triangle,
      timblo,

    }
  },

    created() {
    // this.comprobarCodigo();
  },

  methods: {
    // comprobarCodigo() {
    //   this.$axios
    //     .post('discount', {
    //       discount_code: this.timblo.user?.active_coupon?.coupon?.code,
    //       id_usuario: this.timblo.user.id
    //     })
    //     .then(({ data }) => {
    //         this.newCode = data;
    //     });
    // },
  }

});
</script>

<style scoped>

h1 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;

    color: var(--ion-color-primary);
    margin: 19px 73px 15px 16px;

}

h3 {
    color: var(--ion-color-primary);
}

h5 {
    margin-top: 20px !important;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}


</style>