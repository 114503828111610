import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/Home.vue'
import SignUpPage from '../views/Auth/SignUp.vue';
import LoginPage from '../views/Auth/Login.vue';
import ResetPage from '../views/Auth/ResetPassword.vue';
import RecipePage from '../views/Recipe/Recipe.vue';
import RegisterPage from '../views/Auth/Register.vue';

import RelativesPage from '../views/Account/Relatives.vue';
import AddressesPage from '../views/Account/Addresses.vue';
import AddressPage from '../views/Account/Address.vue';
import ToolsPage from '../views/Account/Tools.vue';
import DiscountCodePage from '../views/Account/DiscountCode.vue';
import HelpPage from '../views/Account/Help.vue';
import HelpQuestionsPage from '../views/Account/HelpQuestions.vue';
import ActivateAccountPage from '../views/Account/ActivateAccount.vue';
import MarketPage from '../views/Nutrition/Market.vue';
import OrdersPage from '@/views/Orders/Orders.vue';
import ProductsPage from '@/views/Products/Products.vue';
import EnergyExpenditurePage from '@/views/Account/EnergyExpenditure.vue';
import RecoverPage from '../views/Auth/Recover.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'signup' }
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUpPage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPage
  },
  {
    path: '/recover',
    name: 'recover',
    component: RecoverPage
  },
  {
    path: '/recipe/:id',
    name: 'recipe',
    component: RecipePage
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage
  },



  {
    path: '/',
    component: HomePage,
    children: [
      // {
      //   path: '',
      //   redirect: { name: 'signup' }
      // },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home/Home.vue'),
        meta: { tab: 'home' },
      },
      {
        path: 'nutrition',
        name: 'nutrition',
        component: () => import('@/views/Nutrition/Nutrition.vue'),
        meta: { tab: 'nutrition' },
      },
      {
        path: 'wellness',
        name: 'wellness',
        component: () => import('@/views/Wellness/Wellness.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/views/Account/Account.vue')
      },
      {
        path: '/addresses',
        name: 'addresses',
        component: AddressesPage
      },
      {
        path: '/market_addresses',
        name: 'market_addresses',
        component: () => import('@/views/Account/MarketAddresses.vue'),
        meta: { tab: 'order' },
      },
      {
        path: 'address_delivery',
        name: 'address_delivery',
        component: () => import('@/views/Account/AddressDelivery.vue'),
        meta: { tab: 'order' },
      },
      {
        path: '/energy_expenditure/:id?',
        name: 'energy_expenditure',
        component: EnergyExpenditurePage
      },
      {
        path: '/feeding/:id?',
        name: 'feeding',
        component: () => import('@/views/Account/Feeding.vue')
      },
      {
        path: '/payment_methods/',
        name: 'payment_methods',
        component: () => import('@/views/Account/PaymentMethods.vue')
      },
      {
        path: '/payment_method/:id?',
        name: 'payment_method',
        component: () => import('@/views/Account/PaymentMethod.vue')
      },
      {
        path: '/address/:id?',
        name: 'address',
        component: AddressPage
      },
      {
        path: '/tools',
        name: 'tools',
        component: ToolsPage
      },
      {
        path: '/allergens/:id',
        name: 'allergens',
        component: () => import('@/views/Account/Allergens.vue')
      },
      {
        path: '/dislike/:id',
        name: 'dislike',
        component: () => import('@/views/Account/Dislike.vue')
      },
      {
        path: '/information',
        name: 'information',
        component: () => import('@/views/Account/Information.vue')
      },
      {
        path: '/relatives',
        name: 'relatives',
        component: RelativesPage
      },
      {
        path: '/markets',
        name: 'markets',
        component: () => import('@/views/Nutrition/Markets.vue'),
        meta: { tab: 'nutrition' },
      },
      {
        path: '/market/:id',
        name: 'market',
        component: () => import('@/views/Nutrition/Market.vue'),
        meta: { tab: 'nutrition' },
      },
      {
        path: 'fav_markets',
        name: 'fav_markets',
        component: () => import('@/views/Account/FavMarkets.vue'),
        meta: { tab: 'nutrition' },
      },
      {
        path: '/professionals',
        name: 'professionals',
        component: () => import('@/views/Nutrition/Professionals.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: '/nutritionist/:id',
        name: 'nutritionist',
        component: () => import('@/views/Nutrition/Nutritionist.vue'),
        meta: { tab: 'nutrition' },
      },
      {
        path: '/gym/:id',
        name: 'gym',
        component: () => import('@/views/Nutrition/Gym.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: '/doctor/:id',
        name: 'doctor',
        component: () => import('@/views/Nutrition/Doctor.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: '/discountcode',
        name: 'discountcode',
        component: DiscountCodePage
      },
      {
        path: '/help',
        name: 'help',
        component: HelpPage
      },
      {
        path: '/help_questions',
        name: 'help_questions',
        component: HelpQuestionsPage
      },
      {
        path: '/activate_account',
        name: 'activate_account',
        component: ActivateAccountPage
      },
      {
        path: '/pause_account',
        name: 'pause_account',
        component: () => import('@/views/Account/PauseAccount.vue')
      },
      {
        path: '/market/:id?',
        name: 'market',
        component: MarketPage,
        meta: { tab: 'nutrition' },
      },
      {
        path: '/orders/:id?',
        name: 'orders',
        component: OrdersPage,
        meta: { tab: 'order' },
      },
      {
        path: 'order_list',
        name: 'order_list',
        component: () => import('@/views/Orders/OrderList.vue'),
        meta: { tab: 'order' },
      },
      {
        path: '/order/:id?',
        name: 'order',
        component: () => import('@/views/Orders/Order.vue'),
        meta: { tab: 'order' },
      },
      {
        path: '/order_detail/:id?',
        name: 'order_detail',
        component: () => import('@/views/Orders/OrderDetail.vue'),
        meta: { tab: 'order' },
      },
      {
        path: '/products',
        name: 'products',
        component: ProductsPage,
        meta: { tab: 'nutrition' },
      },
      {
        path: '/recommended_products',
        name: 'recommended_products',
        component: () => import('@/views/Products/RecommendedProducts.vue'),
        meta: { tab: 'nutrition' },
      },
      {
        path: '/product/:id',
        name: 'product',
        component: () => import('@/views/Products/Product.vue'),
        meta: { tab: 'nutrition' },
      },
      {
        path: '/event/:id',
        name: 'event',
        component: () => import('@/views/Wellness/Event.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: '/meditation/:id',
        name: 'meditation',
        component: () => import('@/views/Wellness/Meditation.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: '/account/delete',
        name: 'deleteAccount',
        component: () => import('@/views/Account/DeleteAccount.vue')
      },
      {
        path: '/account/about_timblo',
        name: 'about_timblo',
        component: () => import('@/views/Account/AboutTimblo.vue')
      },
      {
        path: '/account/contact_us',
        name: 'contact_us',
        component: () => import('@/views/Account/ContactTimblo.vue')
      },
      {
        path: '/account/about_me',
        name: 'about_me',
        component: () => import('@/views/Account/AboutMe.vue')
      },
      {
        path: '/account/subscription',
        name: 'subscription',
        component: () => import('@/views/Account/Subscription.vue')
      },
      {
        path: '/orders/confirm/:id',
        name: 'confirm',
        component: () => import('@/views/Orders/OrderConfirm.vue'),
        meta: { tab: 'order' },
      },
      {
        path: '/orders/deny/:id',
        name: 'deny',
        component: () => import('@/views/Orders/OrderDeny.vue'),
        meta: { tab: 'order' },
      },
      {
        path: '/blog/articles',
        name: 'blogs',
        component: () => import('@/views/Blogs/Blogs.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: '/blog/articles/:id',
        name: 'blog',
        component: () => import('@/views/Blogs/Blog.vue'),
        meta: { tab: 'wellness' },
      },
      {
        path: '/offer/:id',
        name: 'offer',
        component: () => import('@/views/Products/Offer.vue'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
