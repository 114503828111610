<template>
<ion-page>
  <ion-header class="ion-no-border">
    <ion-toolbar class="card-toolbar">
      <ion-buttons slot="start">
        <ion-button @click="$router.go(-1)">
          <ion-icon :icon="chevronBack"></ion-icon>
        </ion-button>
      </ion-buttons>
  
      <ion-title>{{ $t('activa-el-servicio-timblo-0') }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>

      <div style="margin: 40px 24px 30px">
        <label><h5>{{ $t('para-activar-el-servicio-timblo-necesitas-tener-una-tarjeta-bancaria-y-una-direccion-de-entrega-asociadas') }}</h5></label>
        
      </div>
        <ion-button :disabled="addresses.length > 0" style="color: white ;font-size:14px; width: 90%; height: 43px; margin: 0px 24px 35px;" @click="$router.push({ name: 'addresses'})">{{ $t('anade-direccion-de-entrega') }}</ion-button>
        <ion-button :disabled="cards.length > 0" style="color: white ;font-size:14px; width: 90%; height: 43px; margin: 0px 24px 35px;" @click="$router.push({ name: 'payment_methods'})">{{ $t('anade-metodo-de-pago') }}</ion-button>
        <ion-button :disabled="addresses.length == 0 || cards.length == 0" style="color: white ;font-size:14px; width: 90%; height: 43px; margin: 0px 24px 35px;" @click="cambiarServicio(1)">{{ $t('activa-el-servicio-timblo') }}</ion-button>


    </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { ellipse, square, triangle, chevronBack } from 'ionicons/icons';
import { useTimbloStore } from '@/stores/timblo';


export default defineComponent({
  name: 'ActivateAccountPage',

  components: {
    IonPage,
    IonContent,
    // TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      ellipse,
      square,
      triangle,
      user: ref({}),
      addresses: ref({}),
      cards: ref({}),
      timblo,
      chevronBack,

    }
  },

    created() {
    this.getAddresses();
    this.getCards();
  },

  methods: {
    getAddresses() {
      this.$axios
        .get('addresses', {
          params: {
            id_usuario: this.timblo?.user?.id,
          },
        })
        .then(({ data }) => {
          this.addresses = data.data;
        });
    },

    getCards() {
      this.$axios
      .get('payment_methods', {
        user: this.timblo?.user?.id,
      })
      .then(({ data }) => {
        this.cards = data;
      })

    },

    cambiarServicio(valor) {
        this.timblo.user.active = valor,
        this.$axios
            .put('/users/', {
            user: {
                ...this.timblo.user},
            })
            .then(({ data }) => {
                this.user = data;
                this.$router.go(-1)
            });
    },
  }

});
</script>

<style scoped>

h1 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
    color: var(--ion-color-primary);

}

h3 {
    color: var(--ion-color-primary);
}

h5 {
    margin-top: 20px !important;
    font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}


</style>