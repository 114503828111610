<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div>
        <ion-img
          style="margin: 33px 108px 0px 129px"
          src="assets/logo/logo.svg"
        ></ion-img>
      </div>

      <div>
        <ion-img
          style="margin: 24px 62px 18px 65px"
          src="assets/images/workout.svg"
        ></ion-img>
      </div>

      <div class="subtitulo">
        <h4>{{ $t('has-olvidado-tu-contrasena') }}</h4>
        <p>{{ $t('introduce-tu-correo-electronico-y-te-mandamos-un-enlace-de-recuperacion') }}</p>
      </div>

      <div style="margin: 10px 24px 0">
        <div>
          <ion-input
            v-model="credentials.email"
            :placeholder="$t('email')"
            type="email"
            class="t-input"
          />
        </div>

        <t-button style="margin-top: 16px" @click="sendEmailReset()">{{ $t('enviar') }}</t-button>
      </div>
      <div style="text-align: center">
        <p
          @click="$router.push({ name: 'login' })"
          style="color: var(--ion-color-primary)"
        >
          {{ $t('volver-al-inicio') }}
        </p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';

import { IonPage, IonInput, IonContent, toastController } from '@ionic/vue';

export default defineComponent({
  name: 'ResetPage',

  components: {
    IonPage,
    IonContent,
    TButton,
    IonInput,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      timblo,
      toastController,

      credentials: ref({}),
    };
  },

  created() {
    //
  },

  methods: {
    sendEmailReset() {
      if (/[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/.test(this.credentials.email)) {
        this.$axios.post('/reset', { ...this.credentials }).then(({ data }) => {
          if (data < 0) {
            this.openToastOptions(
              this.$t('Ha habido un error'),
              'El usuario no existe'
            );
            return;
          }
          this.openToastOptions(
            this.$t('Correo enviado'),
            'Pronto recibirá un correo de Timblo',
            'success'
          );
          setTimeout(() => {
            this.$router.replace({name: 'login'});
          }, 3500);

        });
      } else {
        this.openToastOptions(
          this.$t('Ha habido un error'),
          'Introduce un email valido'
        );
      }
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
  },
});
</script>

<style lang="scss" scoped>
h1 {
  color: var(--ion-color-primary);
}

input {
  width: 90%;
  padding-left: 16px;
  height: 48px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
}

input::placeholder {
  font-family: 'Nunito Sans';
  color: var(--ion-color-primary-shade);
}

.subtitulo {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--ion-color-primary);
  padding-left: 45px;
  padding-right: 35px;
}
.subtitulo2 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  text-align: left;
  color: black;
  padding-left: 45px;
  padding-right: 35px;
}
</style>
