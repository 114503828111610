<template>
  <ion-app style="margin: auto; top: 0; bottom: 0; max-width: 560px">
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

import { useTimbloStore } from '@/stores/timblo';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      timblo,
    };
  },

  async created() {
    if (this.timblo?.token) {
      this.timblo.products = await this.$axios
        .get('/orders/products')
        .then(({ data }) => data);

      this.$axios.get('/user').then(async ({ data }) => {
      this.timblo.user = data;
    })

    }
    if (process.env.NODE_ENV != 'development') {
      this.$FullStory.getCurrentSessionURL();
    }
  },
});
</script>
