<template>
  <ion-page>
    <ion-content class="set_padding">
      <!-- <ion-img
        v-if="recipe.image"
        :src="`https://api.timblo.es/images/recipes/${recipe.image}`"
      ></ion-img> -->

      <div class="timblo-card-photo">
        <ion-img v-if="recipe.image" :src="`https://api.timblo.es/images/recipes/${recipe.image}`" />

        <ion-toolbar class="card-toolbar">
          <ion-button shape="round" @click="$router.go(-1)">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
          <div slot="end" style="display:flex;flex-direction:column;align-items:center">
            <ion-button @click="toggleFav(recipe)" shape="round" style="--color: #e28e9d">
              <ion-icon :icon="recipe.favourites_count ? heart : heartOutline"></ion-icon>
            </ion-button>
            <ion-button @click.stop="!computedOrderStatus ? addOrChange() : null" v-if="!buttonDisabled" shape="round"
              style="--background: url('assets/icons/reload.svg') center center no-repeat;--border-radius: 50%; ">
              <ion-icon></ion-icon>
            </ion-button>

          </div>
        </ion-toolbar>
      </div>

      <div class="product-order-content set_padding">
        <div v-if="recipe.name" class="product-order-content-header">
          <h4>{{ recipe.name[timblo.lang] || recipe.name['es'] }}</h4>
        </div>

        <div class="product-order-content-mealplans">
          <div class="makeHorizontalScroll mealplans">
            <span class="usersMealplans" v-for="mealplan in mealplans" :key="`mealplan-${mealplan}`" :style="`${
                mealplan.active
                  ? 'background-color: var(--ion-color-primary)'
                  : 'background-color: #d3d3d3'
              }`" @click="
                () => {
                  if (!this.order.change) {
                    if (mealplan?.active) mealplan.active = false;
                    else mealplan.active = true;
                    if ($route.query.order) putOrderRecipe(mealplan);
                    checkAvailableDay();
                  }
                }
              ">
              {{
              mealplan.name?.charAt(0).toUpperCase() +
              mealplan.name?.charAt(1).toUpperCase()
              }}
            </span>
          </div>
          <div class="countOrder">
            <ion-buttons>
              <ion-button @click="subQuant(order)" :disabled="!timblo.available">
                <img :src="`assets/icons/diff.svg`" />
              </ion-button>
            </ion-buttons>
            <span class="value">
              {{ order.portions }}
            </span>
            <ion-buttons>
              <ion-button @click="sumQuant(order)" :disabled="!timblo.available">
                <img :src="`assets/icons/sum.svg`" />
              </ion-button>
            </ion-buttons>
          </div>
          <div style="margin-left: 10px">
            <!-- <span class="usersMealplans" style="background-color: #d3d3d3" > LO </span> -->
            <ion-icon style="font-size: 30px" @click="addRecipeToOrder(recipe)"
              v-if="!+this.order.changeRecipe.in_order" src="assets/icons/cart.svg"></ion-icon>

            <ion-icon style="font-size: 30px" @click="addRecipeToOrder(recipe)" v-else
              src="assets/icons/cart_outline.svg"></ion-icon>
          </div>
        </div>
      </div>

      <!-- <div class="product-order-content-days set_padding">
        <h5 style="color: var(--ion-color-primary-shade)">{{ $t('Seleccione el día') }}</h5>
        <div class="makeHorizontalScroll mealplans product-order-content-days-box">
          <span
            class="usersMealplans"
            v-for="day in days"
            :key="`day-${day.value}`"
            :style="`${
              $route.query.week_day == day.value || selectedDay == day.value
                ? 'background-color: var(--ion-color-primary)'
                : 'background-color: #d3d3d3'
            }`"
            @click="selectedDay = day.value"
          >
            {{
              day.label
            }}
          </span>
        </div>
      </div> -->

      <div style="margin: 16px 24px">
        <h5 style="color: var(--ion-color-primary-shade)">{{ $t('descripcion') }}</h5>
        <span v-html="recipe.description?.[timblo.lang] || recipe.description?.['es']"></span>
      </div>

      <hr style="border-bottom: 1px solid #d6d6d6" />

      <div class="recipe-info" style="margin: 16px 24px">
        <h5 style="color: var(--ion-color-primary-shade)">{{ $t('informacion') }}</h5>

        <div>
          <!-- <ion-icon color="primary" slot="start" class="icon ico-clock"/> -->
          {{ recipe.time }} {{ $t('minutos') }}
        </div>
        <!-- <div v-if="$utils.calculateNutrition(recipe?.ingredients)!=0"> -->
        <!-- <ion-icon color="primary" slot="start" class="icon ico-nutrients-energy"/> -->
        <!-- {{ $utils.calculateNutrition(recipe?.ingredients) }} kcal -->
        <!-- </div> -->
        <div v-if="$utils.calculateNutrition(recipe?.ingredients, 'carbohydrate') != 0">
          <!-- <ion-icon color="primary" slot="start" class="icon ico-nutrients-carbohydrate"/> -->
          {{ $utils.calculateNutrition(recipe?.ingredients, 'carbohydrate') }}
          {{ $t('gramos de carbohidratos') }}
        </div>

        <h5 style="color: var(--ion-color-primary-shade)">{{ $t('ingredientes') }}</h5>
        <div v-for="(ingredient, index) in recipe.ingredients" :key="`ingredient-${index}`">
          {{ ingredient.pivot.quantity }}{{ ingredient.measure_unit?.abbreviation }} de
          {{ ingredient.name[timblo.lang] || ingredient.name['es'] }}
          <template v-if="ingredient.pivot.exclude">
            {{ $t("en-despensa") }}
          </template>
        </div>

        <h5 style="color: var(--ion-color-primary-shade)">{{ $t('instrucciones') }}</h5>
        <ol class="recipe-instructions">
          <li v-for="(step, index) in recipe.steps" :key="`step-${index}`">
            <template v-if="step.title && step.title[timblo.lang || 'es']?.length">
              <b v-html="step.title[timblo.lang] || step.title['es']"></b>
              <br />
            </template>

            <span v-html="step.description[timblo.lang] || step.description['es']"></span>
          </li>
        </ol>
      </div>

      <div class="specify-recipe-day" style="margin: 16px 24px">
        <t-button @click="chooseRecipeDay = true">
          {{
          selectedDay
          ? days.find((d) => d.value == selectedDay).name
          : $t('Especifica el día para tu receta')
          }}
          <ion-icon :icon="chevronDownOutline" style="color: black"></ion-icon>
        </t-button>
      </div>
      <div style="margin: 16px 24px; margin-bottom: 6em">
        <t-button :disabled="buttonDisabled" @click="addOrChange()">
          {{ this.order?.changeRecipe?.week_plans?.length && checkDay() ? $t('Cambiar receta') : $t('Añadir receta') }}
        </t-button>
      </div>
    </ion-content>

    <ion-modal :is-open="chooseRecipeDay" @didDismiss="chooseRecipeDay = false" :initialBreakpoint="1"
      :breakpoints="[0, 0.5, 1]">
      <ion-content class="ion-padding">
        <ion-list>
          <t-button class="checkOrder" v-for="week_day in days" :key="`week_day-${week_day.value}`" @click="
              () => {
                chooseRecipeDay = false;
                selectedDay = week_day.value;
                if (+$route.query.order && !$route.query.changeRecipe)
                  putOrderRecipe(null, null, week_day);
              }
            ">
            {{ `${week_day.name}` }}
          </t-button>
        </ion-list>
      </ion-content>
    </ion-modal>
    <TFinder v-model="addRecipe" :order="order || $route.params.id" :change="$route.params.id"
      :mealplanActive="order?.mealplans || []" :tag="nutritionTag" :type="recipe.meal_type" />
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useTimbloStore } from '@/stores/timblo';
import { IonPage, IonContent, toastController, IonModal } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import {
  heartOutline,
  heart,
  chevronBack,
  informationCircle,
  chevronDownOutline,
} from 'ionicons/icons';

import TButton from '@/components/TButton.vue';
import TFinder from '@/components/TFinder.vue';

export default defineComponent({
  name: 'RecipePage',

  components: {
    IonPage,
    IonContent,
    TButton,
    IonModal,
    TFinder,
  },

  setup() {
    const { t } = useI18n();
    const timblo = useTimbloStore();
    const days = [
      {
        value: 1,
        label: 'L',
        name: t('lunes'),
      },
      {
        value: 2,
        label: 'M',
        name: t('martes'),
      },
      {
        value: 3,
        label: 'X',
        name: t('miercoles'),
      },
      {
        value: 4,
        label: 'J',
        name: t('jueves'),
      },
      {
        value: 5,
        label: 'V',
        name: t('viernes'),
      },
      {
        value: 6,
        label: 'S',
        name: t('sabado'),
      },
      {
        value: 7,
        label: 'D',
        name: t('domingo'),
      },
    ];

    return {
      // VARS

      recipe: ref({
        ingredients: ref([]),
      }),
      mealplans: ref([]),
      chooseRecipeDay: ref(false),
      order: ref({
        portions: ref(0),
        week_day: ref(null),
      }),
      addRecipe: ref(false),
      days,
      selectedDay: ref(null),

      // STORES
      timblo,
      buttonDisabled: false,
      // ICONS
      heartOutline,
      heart,
      chevronBack,
      informationCircle,
      chevronDownOutline,
    };
  },

  async ionViewWillEnter() {
    this.selectedDay = this.$route.query.week_day || 1;
    if (this.$route.query.order_id) {
      this.order.id = this.$route.query.order_id;
    } else {
      await this.getLastOrder();
    }
    await this.getRecipes();
    this.checkOrder(this.$route.query.order_id || this.order.id);
  },

  methods: {
    async getRecipes(){
      this.$axios.get(`/recipes/recipe`,
        {
          params: {
            recipe_id: this.$route.params.id,
            order_id: this.$route.query.order_id || this.order.id,
          },
        }).then(({ data }) => {

          this.recipe = data;
          this.order.recipe = data;
          console.log(this.recipe);

          this.getMealPlans().then(() => {
            // !this.$route.query.changeRecipe
            //   ? this.checkAvailableDay()
            //   : (this.selectedDay = this.$route.query.changeRecipe
            //     ? JSON.parse(this.$route.query.changeRecipe)?.week_day
            //     : 1);
          });

          // this.order.orderDate = this.$route.query.date;
          // this.order.portions = this.$route.query.portions || 0;
          // this.order.week_day = this.$route.query.week_day;
          // this.order.slot_id = this.$route.query.slot_id;
          this.order.portions = data.week_plans[0]?.pivot.portions ?? 0;
          this.order.slot_id = this.$route?.query?.slot || data.week_plans[0]?.pivot.slot_id || this.$route?.query?.slot_id;
          this.order.weekday = this.$route?.query?.week_day || data.week_plans[0]?.pivot.week_day;
          this.order.recipe_week_plan_id = data.week_plans[0]?.id;
          this.order.changeRecipe = {
            recipe_id: data.id,
            week_plans: (data.week_plans || []).map((wp) => wp?.id),
            week_day: data.week_plans[0]?.pivot?.week_day,
            in_order: data.week_plans[0]?.pivot?.in_order,
          }
          // console.log(this.order);
          // this.order.changeRecipe = this.$route.query.changeRecipe
          //   ? JSON.parse(this.$route.query.changeRecipe)
          //   : null;
          // if (this.$route.query.order_id) {
          //   this.checkOrder(this.$route.query.order_id);
          // }
        });
    },

    async getLastOrder() {
      return this.$axios
        .get('orders/pending')
        .then(({ data }) => {
          console.log("Finalizado");
          this.order.id = data.pending.id;
          console.log(this.order.id);
          return data.id;
        });
    },

    checkAvailableDay() {
      this.$axios
        .get('recipes/day', {
          params: {
            recipe_id: this.recipe?.id,
            mealplans: this.mealplans.filter((m) => m?.active).map((a) => a?.id),
          },
        })
        .then(({ data }) => {
          if (data.length) {
            this.selectedDay = data[0];
          } else this.selectedDay = 1;
        });
    },

    checkOrder(order_id) {
      this.$axios
        .get('orders/byId', {
          params: {
            order_id: order_id,
          },
        })
        .then(({ data }) => {
          if (data.order_status_id != 1) {

            this.buttonDisabled = true;
          } else {
            this.buttonDisabled = false;
          }
        });
    },

    toggleFav(recipe) {
      recipe.favourites_count = 1 - recipe.favourites_count;
      this.$axios.post('/recipes/toggle_fav', {
        recipe,
      });
    },

    getMealPlans() {
      return this.$axios.get(`/mealplans/${this.timblo.user.id}`).then(({ data }) => {
        const mps = this.recipe.week_plans.map(wp => wp?.meal_plan_id);

        data.map((d) => {
          if (mps.length) {
            if (mps?.includes(d.id)) d.active = true;
          } else {
            if (d.is_admin) d.active = true;
          }
          return d;
        });
        this.mealplans = data;
      });
    },

    putOrderRecipe(mealplans = null, portions = null, week_day = this.selectedDay) {
      // this.order.in_order = 1 - this.order.in_order;
      if (this.order?.in_order) {
        this.openToastOptions(
          this.$t('Aviso'),
          this.$t('Receta añadida al carrito.'),
          'success'
        );
      }
      this.$axios
        .put(`/orders/recipe`, {
          order: this.order,
          order_id: this.order.id,
          recipe_id: this.recipe.id,
          recipe_week_plan: this.order?.recipe_week_plan_id,
          slot_id: this.order?.slot_id,
          mealplan: mealplans ? mealplans.id : null,
          portions,
          week_day: week_day,
          in_order: 1 - this.order.changeRecipe?.in_order ?? 0,
        })
        .then(() => {
          this.openToastOptions(
            this.$t('Gracias!'),
            this.$t('Pedido actualizado.'),
            'success'
          );
        })
        .finally(async () => {
          this.timblo.products = await this.$axios
            .get('/orders/products')
            .then(({ data }) => data);
        });
    },

    putOrderRecipeIngredients() {
      // this.order.in_order = 1 - this.order.in_order;
      if (this.order.in_order) {
        this.openToastOptions(
          this.$t('Aviso'),
          this.$t('Receta añadida al carrito.'),
          'success'
        );
      }

      const activeUsers = this.mealplans.filter((m) => m.active).map((m) => m.id);

      // const activeUsers = this.mealplans.filter((m) => m.active).map((m) => m.id);
      const activeDay = this.days.find((m) => m?.value == this.selectedDay)?.value;

      this.$axios
        .put(`/orders/recipe_ingredients`, {
          order_id: +this.order.id,
          recipe_id: this.recipe.id,
          mealPlans: activeUsers,
          week_day: activeDay,
          slot_id: this.order?.slot_id,
          portion: this.order.portions,
          cost: this.recipe.ingredients.map((a) => +a?.base_cost).reduce((a, b) => a + b),
          changeRecipe: this.order.changeRecipe
            ? this.order.changeRecipe
            : null,
        })
        .then(() => {
          this.openToastOptions(
            this.$t('Gracias!'),
            this.$t('Pedido actualizado.'),
            'success'
          );
        })
        .finally(async () => {
          this.timblo.products = await this.$axios
            .get('/orders/products')
            .then(({ data }) => data);
        });
    },

    // ORDER - QUANTITY
    async sumQuant(order) {
      order.portions = parseInt(order.portions);
      const actualQuant = order.portions;
      const adder = 1;
      const maxQuant = 20;
      if (actualQuant + adder > maxQuant / 2) {
        this.openToastOptions(
          this.$t('Aviso!'),
          this.$t('No puede pedir mas de {quantity}', { quantity: maxQuant / 2 })
        );
      } else {
        order.portions += adder;
      }
      if (this.order.id) this.putOrderRecipe(null, order.portions);

    },

    async subQuant(order) {
      order.portions = parseInt(order.portions);
      const actualQuant = order.portions;
      const adder = 1;
      const minQuant = 0;
      if (!(actualQuant - adder < minQuant)) order.portions -= adder;

      if (this.order.id) this.putOrderRecipe(null, order.portions);
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },

    // recipeInclude(data) {
    //   data.in_order = 1 - dafheartta.in_order;
    //   if (data.in_order) {
    //     this.openToastOptions(
    //       this.$t('Aviso'),
    //       this.$t('Receta añadida al carrito.'),
    //       'success'
    //     );
    //   }

    //   this.$axios
    //     .post('/recipes/include', {
    //       order: this.order,
    //       wp: data,
    //     })
    //     .then(async () => {
    //       this.init();

    //       this.timblo.products = await this.$axios
    //         .get('/orders/products')
    //         .then(({ data }) => data);
    //     });
    // },
    addOrChange() {
      if (this.order.changeRecipe.week_plans.length && this.order.weekday == this.selectedDay) {
        this.openChangeRecipe(this.order);
      } else {
          this.addRecipeToOrder();
      }
    },
    async addRecipeToOrder() {
      // FIXME: Aparentemente no añade ninguna receta...
      // const activeUsers = this.mealplans
      //   .filter((m) => this.$route.query.mealplans?.includes(m?.id + ''))
      //   .map((m) => m.id);
      const activeUsers = this.mealplans.filter((m) => m.active).map((m) => m.id);

      // const activeUsers = this.mealplans.filter((m) => m.active).map((m) => m.id);
      const activeDay = this.days.find((m) => m?.value == this.selectedDay)?.value;
      if (!activeUsers.length) {
        this.openToastOptions(this.$t('Aviso!'), this.$t('Tiene que añadir un usuario'));
      } else {
        const in_order = 1 - this.order.changeRecipe?.in_order;

        // if (this.order?.recipe.week_plans?.length) {
        if (this.order?.recipe.week_plans?.filter(r => r?.pivot?.week_day == this.selectedDay)?.length) {
          this.$axios
            .post('/recipes/include', {
              order: {
                id: this?.order?.id,
              },
              wp: {
                id: this.recipe.week_plans[0]?.pivot?.id,
                in_order,
              },
            })
            .then(async () => {
              // this.openToastOptions(this.$t('Aviso'), this.$t('Receta eliminada del carrito.'));
              this.init();
              this.timblo.products = await this.$axios
                .get('/orders/products')
                .then(({ data }) => data);
              // this.openToastOptions(
              //   this.$t('Gracias!'),
              //   this.$t('Receta añadida a su pedido.'),
              //   'success'
              // );
            })
            .finally(async () => {
              this.$router.go(-1);
              this.timblo.products = await this.$axios
                .get('/orders/products')
                .then(({ data }) => data);
            });
        } else{
          // console.log(this.order.changeRecipe);

          return this.$axios
            .post(`/orders/recipe`, {

              order_id: +this.order.id,
              recipe_id: this.recipe.id,
              mealPlans: activeUsers,
              week_day: activeDay,
              oldWeekDay: this.order.weekday,
              slot_id: this.order.slot_id,
              portion: this.order.portions,
              cost: this.recipe.ingredients
                .map((a) => +a?.base_cost)
                .reduce((a, b) => a + b),
              changeRecipe: this.order.changeRecipe,
              change: this.$route.query.change ?? null
            })
            .then(({ data }) => {
              this.openToastOptions(
                this.$t('Gracias!'),
                this.$t('Receta añadida a su pedido.'),
                'success'
              );

              return data;
            })
            .finally(async () => {
              this.$router.go(-1);
              this.timblo.products = await this.$axios
                .get('/orders/products')
                .then(({ data }) => data);
            })
            .catch(() => {
              this.openToastOptions(this.$t('Error'), this.$t('Algo no ha ido bien'));
            });
        }
        }


      return false;
    },

    checkDay(){
      // console.log("AAAAAAAAAAAAAA");
      // console.log(this.order);
      return this.recipe.week_plans.find((d) => d.pivot.week_day == this.selectedDay);
    },

    async recipeInclude() {
      if(!this?.order?.changeRecipe.week_plans?.length) {
        const data = await this.addRecipeToOrder();
          if(data) {
            this.openToastOptions(this.$t('Aviso'), this.$t('No se ha podido añadir.'));
            return;
          }
          // this.order.id = data?.order?.id;
          // this.$route.query.recipe_week_plan = data?.rwp?.id;
          // this.$route.query.in_order = 0;
      }
      // const in_order = 1 - this.order.changeRecipe?.in_order;

      // this.$axios
      //   .post('/recipes/include', {
      //     order: {
      //       id: this?.order?.id,
      //     },
      //     wp: {
      //       id: this.recipe.week_plans[0]?.pivot?.id,
      //       in_order,
      //     },
      //   })
      //   .then(async () => {
      //     if (in_order) {
      //       this.openToastOptions(
      //         this.$t('Aviso'),
      //         this.$t('Receta añadida al carrito.'),
      //         'success'
      //       );
      //     } else {
      //       this.openToastOptions(this.$t('Aviso'), this.$t('Receta eliminada del carrito.'));
      //     }
      //     this.init();
      //     this.timblo.products = await this.$axios
      //       .get('/orders/products')
      //       .then(({ data }) => data);
      //     // this.openToastOptions(
      //     //   this.$t('Gracias!'),
      //     //   this.$t('Receta añadida a su pedido.'),
      //     //   'success'
      //     // );
      //   })
      //   .finally(async () => {
      //     this.$router.go(-1);
      //     this.timblo.products = await this.$axios
      //       .get('/orders/products')
      //       .then(({ data }) => data);
      //   });
    },

    openChangeRecipe(order) {
      // const activeUsers = this.mealplans.filter((m) => m.active).map((m) => m.id);

      // const mealplans = this.orders.week_plans
      //   ?.map((a) => {
      //     return a.recipe_week_plan.filter((r) => r.recipe_id == order.recipe_id);
      //   })
      //   .flat();

      this.addRecipe = true;
      // TODO: Tags preseleccionados
      this.order.mealplans = order.mealplans;
      // this.$route.query.change = 3;
      // this.order.changeRecipe = {
      //   recipe_id: order.changeRecipe.recipe_id,
      //   week_plans: order.changeRecipe.week_plans,
      //   week_day: order?.week_day,
      //   in_order: 0,
      // };
    },


    // deleteRecipeFromOrder() {
    //   this.$axios
    //     .delete(`/orders/recipe`, {
    //       params: {
    //         recipe_week_plan_id: this.$route.query.recipe_week_plan,
    //         order_id: this.order.id,
    //       },
    //     })
    //     .then(() => {
    //       this.openToastOptions(
    //         this.$t('Completado'),
    //         this.$t('Receta borrada de su pedido.'),
    //         'danger'
    //       );
    //     })
    //     .finally(async () => {
    //       this.$router.go(-1);
    //       this.timblo.products = await this.$axios
    //         .get('/orders/products')
    //         .then(({ data }) => data);
    //     })
    //     .catch(() => {
    //       this.openToastOptions(this.$t('Error'), this.$t('Algo no ha ido bien'));
    //     });
    // },
  },
});
</script>

<style lang="scss" scoped>
ion-list {
  background-color: transparent;
}
.checkOrder {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  background-color: transparent;
  border: 1px solid black;
  color: var(--ion-color-primary);
  font-size: 16;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
ion-content {
  --background: #f2f4f8;
}
.set_padding {
  padding-left: 24px;
  padding-right: 24px;
}
.timblo-card-photo {
  position: relative;
  overflow: hidden;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-img {
    width: 100%;
  }

  & .card-toolbar {
    position: absolute;
    top: 1.2em;
    --background: transparent;
    --ion-color-base: transparent !important;

    & ion-button {
      --background: #fff;

      &::part(native) {
        padding: 12px;
      }
    }
  }
}
.mealplans {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  width: 100%;

  & > .usersMealplans {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 33.3333333333333%;
    border-radius: 100%;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
    // background-color: var(--ion-color-tertiary-contrast);
  }
}
.product-order-content {
  background-color: #fffefe;

  &-header {
    margin-top: 0;
    padding-top: 16px;
  }
  &-mealplans {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .countOrder {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #f2f4f8;
      border-radius: 24px;
      height: 36px;
      min-width: 85px;
      .value {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  &-days {
    background-color: #fff;
    padding-bottom: 10px;
    h5 {
      margin-top: 0px;
      padding-top: 10px;
    }
    &-box {
      justify-content: space-between;
    }
  }
}

.recipe-info > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 16px;
}
.recipe-instructions li {
  line-height: 21.82px;
}
.specify-recipe-day button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  font-size: 16px;
  color: #575757;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #575757;
}
</style>
