<template>
  <ion-page>
    <ion-content>
      <ion-buttons>
        <ion-button @click="$router.go(-1)">
          <ion-icon :icon="chevronBack" style="color: black; font-size: 30px"></ion-icon>
        </ion-button>
      </ion-buttons>

      <div class="padding-content" v-if="orders?.delivery_date">
        <ion-card class="ion-no-margin card_subscribe padding-content"
          v-if="orderMessage && !+orderMessage.order_number">
          <div v-html="orderMessage.content"></div>
        </ion-card>
        <button class="order-header" @click="chooseOrder = true">
          <h4>
            {{ `${$t('Entrega')} ${$moment.unix(orders?.delivery_date).format('ll')}` }}
            <span>
              <ion-icon :icon="chevronDownOutline" style="color: black"></ion-icon>
            </span>
          </h4>
        </button>
        <div class="order-days">
          <ion-button v-for="day in computedDays" :key="`day-${day.value}`" color="transparent"
            :class="`day ${day?.active ? 'active' : ''}`" @click.stop="dayTrigger(day)">
            <!-- :disabled="day?.disabled" -->
            {{ $t('dia') }} <br />
            {{ day.value }}
          </ion-button>
        </div>

        <div class="order-content">
          <h4>{{ totalRecipesOrder }} {{ $t('comidas') }} {{ $t('en-esta-entrega') }}</h4>
          <p>( {{ `${recipeWeekPlan?.length} ${$t('en-este-dia')}` }} )</p>
          <ion-list v-if="recipeWeekPlan.length">
            <div class="content-order" v-for="order in recipeWeekPlan || []" :key="`recipe-${order.recipe.id}`">
              <div class="makeHorizontalScroll mealplans">
                <span class="usersMealplans" v-for="mealplan in mealplans" :key="`mealplan-${mealplan}`" :style="`${
                    order.mealplans.includes(mealplan.id)
                      ? 'background-color: var(--ion-color-primary)'
                      : 'background-color: #d3d3d3'
                  }`" @click="computedOrderStatus ? null : addMealPlanRecipe(mealplan, order)">
                  {{
                  mealplan.name?.charAt(0).toUpperCase() +
                  mealplan.name?.charAt(1).toUpperCase()
                  }}
                </span>
              </div>

              <ion-card class="ion-no-margin">
                <img :src="`https://api.timblo.es/images/recipes/${order?.recipe?.image}`"
                  :alt="`order-${order?.recipe?.id}`" @click="openRecipe(order)" />
                <ion-card-header class="ion-no-padding">
                  <h5 class="ion-text-wrap limitTwoLinesHeader" @click="openRecipe(order)">
                    {{ order?.recipe?.name[timblo.lang] || order?.recipe?.name['es'] }}
                    {{ console.log(order) }}

                    <p v-if="order?.in_order" style="font-size: small;">
                      {{ $t('Has pedido productos de esta receta') }}
                    </p>
                  </h5>
                </ion-card-header>
                <ion-card-content style="visibility: hidden" class="card_content">
                  <p>
                    {{ $utils.calculateNutrition(order.recipe.ingredients) }}
                    kcal | {{ order.recipe.time }} min
                  </p>
                </ion-card-content>

                <div class="removeOrder">
                  <ion-buttons style="display: flex; flex-direction: column; gap: 10px">
                    <ion-icon style="font-size: 30px" @click="!computedOrderStatus ? removeRecipe(order) : null"
                      src="assets/icons/trash.svg"></ion-icon>

                    <ion-icon style="font-size: 30px" @click="!computedOrderStatus ? changeRecipe(order) : null"
                      src="assets/icons/reload.svg"></ion-icon>

                    <!-- <ion-button
                      class="removeBtn"
                      @click="changeRecipe(order)"
                      :disabled="computedOrderStatus"
                    >
                      <ion-icon class="f30" :icon="repeatOutline"></ion-icon>
                    </ion-button> -->
                  </ion-buttons>
                </div>
                <div class="favOrder">
                  <ion-buttons>
                    <!-- <ion-button
                      @click="toggleFav(order.recipe)"
                      shape="round"
                      style="--color: #e28e9d"
                      :disabled="computedOrderStatus"
                    >
                      <ion-icon
                        :icon="order.recipe.favourites_count ? heart : heartOutline"
                      ></ion-icon>
                    </ion-button> -->

                    <!-- <ion-button
                      class="removeBtn"
                      :style="
                        order.in_order
                          ? ''
                          : 'background-color: var(--ion-color-primary) !important'
                      "
                      @click="recipeInclude(order)"
                      :disabled="computedOrderStatus"
                    >
                      <ion-icon class="f30" :icon="cartOutline"></ion-icon>
                    </ion-button> -->

                    <ion-icon style="font-size: 30px" @click="!computedOrderStatus ? recipeInclude(order) : null" :src="
                        order.in_order
                          ? 'assets/icons/cart_outline.svg'
                          : 'assets/icons/cart.svg'
                      "></ion-icon>
                  </ion-buttons>
                </div>
              </ion-card>

              <div class="content-select">
                <ion-select icon="chevronDownOutline" v-model="order.slot_id" :disabled="computedOrderStatus"
                  @ionChange="changeRecipeInfo(order.id, order.slot_id)">
                  <ion-select-option v-for="slot in slots" :key="`slot-${slot.pid}`" :value="slot.id">
                    {{ slot.name.es }}
                  </ion-select-option>
                </ion-select>

                <div class="countOrder">
                  <ion-buttons>
                    <ion-button @click="subQuant(order)" :disabled="computedOrderStatus">
                      <img :src="`assets/icons/diff.svg`" />
                    </ion-button>
                  </ion-buttons>
                  <span class="value">
                    {{ order.portions }}
                  </span>
                  <ion-buttons>
                    <ion-button @click="sumQuant(order)" :disabled="computedOrderStatus">
                      <img :src="`assets/icons/sum.svg`" />
                    </ion-button>
                  </ion-buttons>
                </div>
              </div>
            </div>
          </ion-list>

          <div style="text-align: center; margin-bottom: 2rem" v-else>
            <p>
              {{ $t('no-tienes-nada-previsto-para-este-dia') }} <br />
              {{
              $t(
              'crea-al-menu-que-mnas-te-apetece-anadiendo-facilmente-recetas-con-el-boton-de-abajo'
              )
              }}
            </p>
            <img src="/assets/images/noOrders.svg" style="width: max-content; max-width: 100%" />
          </div>
        </div>

        <div class="order-footer">
          <div style="text-align: center">
            <!-- <h4>¿Falta algo?</h4> -->
            <p>{{ $t('quieres-anadir-otra-recetas-a-tu-plan') }}</p>
          </div>
          <t-button style="
              /* background-color: var(--ion-color-secondary); */
              color: black;
              margin-bottom: 10px;
            " :disabled="computedOrderStatus" @click="
              () => {
                addRecipe = true;
                nutritionTag = '';
              }
            ">
            {{ $t('consulta-mas-recetas') }}
          </t-button>

          <ion-accordion-group style="margin-bottom: 10px">
            <ion-accordion value="first">
              <ion-item slot="header" color="light">
                <ion-label>{{
                  $t('te-gustaria-recibir-los-ingredientes-para-cocinar-tus-recetas')
                  }}</ion-label>
              </ion-item>

              <div class="ion-padding" slot="content" style="background: #f2f4f8">
                {{
                $t('puedes-anadir-a-tu-cesta-de-la-compra-recetas-sueltas-con-el-boton')
                }}
                <ion-icon style="font-size: 24px" src="assets/icons/cart.svg"></ion-icon>
                {{ $t('o-anadirlas-todas-con-el-boton-de-abajo') }}
              </div>
            </ion-accordion>
          </ion-accordion-group>

          <t-button style="
              background-color: var(--ion-color-secondary);
              color: black;
              margin-bottom: 10px;
            " :disabled="computedOrderStatus" @click="addAllRecipes(order)">
            {{ $t('anadir-todas-las-recetas') }}
          </t-button>
          <!-- <t-button -->
          <!-- @click="$router.push({ name: 'products' })" -->
          <!-- :disabled="computedOrderStatus" -->
          <!-- > -->
          <!-- {{ $t('Producto Extra') }} -->
          <!-- </t-button> -->
          <!-- <h5 style="text-align: center; padding: 16px"> -->
          <!-- ¿Quieres ver lo que te vamos a enviar? -->
          <!-- </h5> -->
          <!-- <t-button -->
          <!-- v-if="!computedOrderStatus" -->
          <!-- class="checkOrder" -->
          <!-- @click="$router.push({ name: 'order_detail', params: { id: order.pid } })" -->
          <!-- > -->
          <!-- {{ $t('Consulta tu cesta aqui') }} -->
          <!-- <ion-img :src="`assets/icons/shoppingCart.svg`"></ion-img> -->
          <!-- </t-button> -->
        </div>
      </div>
    </ion-content>

    <ion-modal :is-open="chooseOrder" @didDismiss="chooseOrder = false" :initialBreakpoint="0.5"
      :breakpoints="[0, 0.5, 1]">
      <ion-content class="ion-padding">
        <ion-list class="order-footer">
          <t-button class="checkOrder" v-for="orderDate in orderDates" :key="`orderDate-${orderDate.id}`"
            @click="changeOrder(orderDate)">
            {{
            `${$t(`Pedido (${orderDate.status.name.es}`)}) - ${$moment
            .unix(orderDate.delivery_date)
            .format('DD MMM')}`
            }}
            <ion-img v-if="orderDate.status.slug == 'pending'" :src="`assets/icons/shoppingCart.svg`"></ion-img>
            <ion-icon v-if="orderDate.status.slug != 'pending'" :icon="lockClosedOutline"></ion-icon>
          </t-button>
        </ion-list>
      </ion-content>
    </ion-modal>

    <ion-modal :is-open="tutorialModal" :initial-breakpoint="1">
      <ion-content v-if="!oncologico">
        <swiper @swiper="orderTutorial" style="height: 100%">
          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/meal_1${timblo.lang == 'ca' ? '_ca' : ''}.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/meal_2${timblo.lang == 'ca' ? '_ca' : ''}.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/meal_3${timblo.lang == 'ca' ? '_ca' : ''}.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/meal_4${timblo.lang == 'ca' ? '_ca' : ''}.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/meal_5${timblo.lang == 'ca' ? '_ca' : ''}.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/meal_6${timblo.lang == 'ca' ? '_ca' : ''}.png`" style="width: 100%" />
          </swiper-slide>
        </swiper>
      </ion-content>
      <ion-content v-else>
        <swiper @swiper="orderTutorial" style="height: 100%">
          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/oncological_A${
                timblo.lang == 'ca' ? '_ca' : ''
              }.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/oncological_B${
                timblo.lang == 'ca' ? '_ca' : ''
              }.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/oncological_C${
                timblo.lang == 'ca' ? '_ca' : ''
              }.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/oncological_D${
                timblo.lang == 'ca' ? '_ca' : ''
              }.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/oncological_E${
                timblo.lang == 'ca' ? '_ca' : ''
              }.png`" style="width: 100%" />
          </swiper-slide>

          <swiper-slide class="swiper-slide tutorial-slide" @click="slideNext(slides)">
            <img :src="`/assets/walkthrough/oncological_F${
                timblo.lang == 'ca' ? '_ca' : ''
              }.png`" style="width: 100%" />
          </swiper-slide>
        </swiper>
      </ion-content>
    </ion-modal>

    <TFinder v-model="addRecipe" :order="order || $route.params.id" :mealplanActive="order?.mealplans || []"
      :change="oldRecipe" :tag="nutritionTag" :type="selectedMealType" :slotId="this.recipe_slot"
      :weekday="this.weekday" />
  </ion-page>
</template>

<script>
import { ref, defineComponent } from 'vue';
import { useTimbloStore } from '@/stores/timblo';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';

// Components
import TFinder from '@/components/TFinder.vue';
import TButton from '@/components/TButton.vue';

import {
  IonPage,
  IonContent,
  IonButton,
  IonButtons,
  IonList,
  IonCard,
  IonCardHeader,
  // IonCardContent,
  IonSelect,
  IonSelectOption,
  IonModal,
  alertController,
  IonImg,
  IonIcon,
  toastController,
  loadingController,
} from '@ionic/vue';

import {
  chevronBack,
  chevronDownOutline,
  closeOutline,
  repeatOutline,
  cartOutline,
  heart,
  heartOutline,
  lockClosedOutline,
  informationCircle,
} from 'ionicons/icons';

import { orderStatic } from './staticOrders';

export default defineComponent({
  name: 'OrdersPage',

  components: {
    IonPage,
    IonContent,
    IonButton,
    IonButtons,
    IonList,
    IonCard,
    IonCardHeader,
    // IonCardContent,
    IonSelect,
    IonSelectOption,
    TButton,
    IonModal,
    TFinder,
    IonImg,
    IonIcon,
    Swiper,
    SwiperSlide,
  },

  setup() {
    const timblo = useTimbloStore();
    const slides = ref();
    const orderTutorial = (swiper) => {
      swiper.preventInteractionOnTransition = true;
      swiper.allowTouchMove = false;
      slides.value = swiper;
    };

    const days = [
      {
        value: 1,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
      {
        value: 4,
      },
      {
        value: 5,
      },
      {
        value: 6,
      },
      {
        value: 7,
      },
    ];

    return {
      // VARS
      orderTutorial,
      slides,

      days,
      tutorialModal: ref(false),
      slots: ref([]),
      mealplans: ref([]),
      orders: ref([]),
      orderDates: ref([]),
      weekPlans: ref([]),
      recipeWeekPlan: ref([]),
      chooseOrder: ref(false),
      addRecipe: ref(false),
      order: ref(null),
      totalRecipesOrder: ref(0),
      orderMessage: ref(null),
      orderStatic,
      mealplansRecipes: ref([]),
      selectedMealType: ref(null),
      oldRecipe: ref(null),
      slot: ref(null),
      weekday: ref(null),
      recipe_slot: ref(null),
      // STORES
      timblo,

      // ICONS
      chevronBack,
      chevronDownOutline,
      closeOutline,
      heart,
      heartOutline,
      lockClosedOutline,
      informationCircle,
      repeatOutline,
      cartOutline,
    };
  },
  computed: {
    computedDays() {
      return this.days;
    },
    computedOrderStatus() {
      const { orders } = this;
      return !(orders.status.slug == 'pending');
    },
  },

  beforeRouteEnter(to, from, next) {
    if (from?.name == 'recipe' && from?.query.order == 0) {
      setTimeout(() => (this.addRecipe = true), 500);
    }
    next();
  },

  ionViewDidEnter() {
    if (!this.timblo.user.orders_tutorial) {
      this.tutorialModal = true;
    }

    this.init();
    this.getOrderMessage();
    this.getUser();
  },

  methods: {
    getUser() {
      this.$axios.get('user').then(({ data }) => {
        console.log(data);
      });
    },

    getOrderMessage() {
      this.$axios.get('order_message').then(({ data }) => {
        this.orderMessage = data;
      });
    },
    init() {
      this.getOrders().then(async () => {
        if (!this.$route.params.id && this.orderDates) {
          await this.getOrders(this.orderDates.slice(-1)[0]?.id);

          this.order = this.orders;
          this.getSlots();
          this.getMealPlans();
          this.getTotalRecipes();
        }
        if (this.$route.params.id) {
          this.getOrders(this.$route.params.id).then(() => {
            this.orders.active_day = this.days.find((d) => d.active).value;
            this.order = this.orders;
            this.getSlots();
            this.getMealPlans();
            this.getTotalRecipes();
          });
        }
        this.timblo.selectedOrderDay ? this.dayTrigger(this.timblo.selectedOrderDay) : "";
        console.log("Dia " + this.orders.active_day);


      });
    },

    addMealPlanRecipe(mealplan, order) {
      if (mealplan?.active) mealplan.active = false;
      else mealplan.active = true;

      if (!order.mealplans.includes(mealplan.id)) {
        order.mealplans.push(mealplan.id);
      } else {
        const index = order.mealplans.findIndex((a) => a == mealplan.id);
        order.mealplans.splice(index, 1);
      }

      this.$axios
        .put(`/orders/recipe`, {
          order_id: this.order?.id,
          order,
          mealplan: mealplan.id,
        })
        .then(async () => {
          this.init();

          this.timblo.products = await this.$axios
            .get('/orders/products')
            .then(({ data }) => data);
        });
    },

    async addAllRecipes(order) {
      const loading = await loadingController.create({
        message: 'Añadiendo todas las recetas a la cesta...',
      });

      loading.present();

      this.$axios.post('/recipes/add_all_recipes', { order }).finally(async () => {
        this.init();

        this.timblo.products = await this.$axios
          .get('/orders/products')
          .then(({ data }) => data);

        loading?.dismiss();
      });
    },

    toggleFav(recipe) {
      recipe.favourites_count = 1 - recipe.favourites_count;
      this.$axios.post('/recipes/toggle_fav', {
        recipe,
      });
    },

    async removeRecipe(order) {
      const alert = await alertController.create({
        header: this.$t('Cuidado!'),
        message: this.$t('¿Estas seguro que quieres borrar esta receta?'),
        buttons: [
          {
            text: 'NO',
            role: 'cancel',
          },
          {
            text: 'SI',
            role: 'confirm',
            handler: () => {
              const mealplans = this.orders.week_plans
                ?.map((a) => {
                  return a.recipe_week_plan.filter((r) => r.recipe_id == order.recipe_id);
                })
                .flat();
              this.$axios
                .delete('/orders/weekplanRecipe', {
                  params: {
                    id: order.recipe_id,
                    week_plans: [...new Set(mealplans.map((w) => w.week_plan_id))],
                    order_id: this.orders.id,
                  },
                })
                .then(() => {
                  this.init();
                })
                .finally(async () => {
                  this.timblo.products = await this.$axios
                    .get('/orders/products')
                    .then(({ data }) => data);
                });
            },
          },
        ],
      });

      await alert.present();
    },

    changeRecipe(order) {
      // const mealplans = this.orders.week_plans
      //   ?.map((a) => {
      //     return a.recipe_week_plan.filter((r) => r.recipe_id == order.recipe_id);
      //   })
      //   .flat();

      // TODO: Tags preseleccionados
      this.recipe_slot = order.slot_id;
      this.weekday = order.week_day;
      this.order.mealplans = order.mealplans;

      this.selectedMealType = order?.recipe?.meal_type;
      this.oldRecipe = order?.recipe_id;
      this.order.slot_id = order?.recipe?.slot_id;
      this.order.weekday = order?.recipe?.week_day;

      this.addRecipe = true;

      // this.order.changeRecipe = {
      //   recipe_id: order.recipe_id,
      //   week_plans: [...new Set(mealplans.map((w) => w.week_plan_id))],
      //   week_day: order?.week_day,
      //   in_order: order?.in_order,
      //   slot: order?.slot_id,
      // };
    },

    changeRecipeInfo(recipe_week_plan, slot_id = null, portions = null) {
      this.$axios.put('/orders/recipe', {
        recipe_week_plan,
        slot_id,
        portions,
      });
    },

    changeOrder(orderData) {
      orderData.active_day = this.days.find((d) => d.active)?.value || 1;
      this.order = orderData;
      this.chooseOrder = false;
      this.getOrders(orderData.id).then(() => {
        this.getMealPlans();
      });
    },

    recipeInclude(data) {
      data.in_order = 1 - data.in_order;
      if (data.in_order) {
        this.openToastOptions(
          this.$t('Aviso'),
          this.$t('Receta añadida al carrito.'),
          'success'
        );
      }

      this.$axios
        .post('/recipes/include', {
          order: this.order,
          wp: data,
        })
        .then(async () => {
          this.init();

          this.timblo.products = await this.$axios
            .get('/orders/products')
            .then(({ data }) => data);
        });
    },

    dayTrigger(day) {
      this.timblo.selectedOrderDay = day;
      this.getDayMealPlan(day);
    },

    getDayMealPlan(day) {
      this.days.map((d) => {
        if (d?.value == day?.value) d.active = true;
        else d.active = false;
        return d;
      });

      const dayRecipes = this.orders.week_plans
        .map((w) => {
          return w.recipe_week_plan
            .map((r) => {
              r.mealplan = w.meal_plan_id;
              return r;
            })
            .flat();
        })
        .flat();

      const weekDayRecipes = [];

      this.orders.week_plans
        ?.map((w) => {
          w.recipe_week_plan
            .map((r) => {
              r.mealplan = w.meal_plan_id;
              return r;
            })
            .flat();
          return {
            recipe_week_plan: w.recipe_week_plan,
          };
        })
        .map((v) => {
          return v.recipe_week_plan.filter((a) => {
            if (+a.week_day == day?.value) {
              const equalRecipes = dayRecipes.filter(
                (dR) =>
                  dR.slot_id == a.slot_id &&
                  dR.recipe.pid == a.recipe.pid &&
                  dR.week_day == a.week_day
              );
              if (equalRecipes?.length > 1) {
                const mealPlansIds = equalRecipes.map((r) => r.mealplan);
                if (
                  !(
                    weekDayRecipes.length &&
                    weekDayRecipes?.find(
                      (t) => t.recipe.pid == equalRecipes[0].recipe.pid
                    )
                  )
                ) {
                  weekDayRecipes.push({
                    ...equalRecipes[0],
                    slot_id: a.slot_id,
                    week_day: a.week_day,
                    mealplans: mealPlansIds,
                  });
                }
              } else {
                weekDayRecipes.push({
                  ...a,
                  slot_id: a.slot_id,
                  week_day: a.week_day,
                  mealplans: [a.mealplan],
                });
              }
              return a;
            }
          });
        })
        .flat();

      this.recipeWeekPlan = weekDayRecipes;

      return weekDayRecipes;
    },

    getTotalRecipes() {
      this.$axios
        .get('/recipes/week', {
          params: {
            count: 1,
          },
        })
        .then(({ data }) => {
          this.totalRecipesOrder = data;
        });
    },

    getSlots() {
      this.$axios('/slots').then(({ data }) => {
        this.slots = data;
      });
    },

    getMealPlans() {
      this.$axios
        .get(`/mealplans/${this.timblo.user.id}`)
        .then(({ data }) => (this.mealplans = data));
    },

    async getOrders(order = null) {
      return await this.$axios
        .get(`/orders`, {
          params: {
            user: this.timblo.user?.id,
            favs: 1,
            olderOrders: 2,
            order,
          },
        })
        .then(({ data }) => {
          if (!order) {
            data.orders.sort((a, b) => {
              return a.delivery_date - b.delivery_date;
            }); // Ordenamos los dias de pedidos
            this.orderDates = data.orders;
            // this.orderDates.push(data.last);
          } else {
            const weekDayArray = [
              ...new Set(
                data.orders.week_plans
                  .map((w) => w.recipe_week_plan)
                  .flat()
                  .map((d) => +d.week_day)
              ),
            ];
            weekDayArray.sort((a, b) => a - b); // Ordenamos los dias
            this.days.map((d) => {
              d.active = false;
              d.disabled = false;
              if (weekDayArray.includes(d.value) && weekDayArray[0] == d.value) {
                d.active = true;
              }
              if (!weekDayArray.includes(d.value)) {
                d.disabled = true;
              }
              return d;
            });
            this.orders = data.orders;

            // Select First Active Day of Order
            const firstWeekPlanDay = this.days.find((d) => d.active);
            this.getDayMealPlan(firstWeekPlanDay);
          }
        });
    },

    // ORDER - QUANTITY
    async sumQuant(order) {
      const actualQuant = order.portions;
      const adder = 1;
      const maxQuant = 20;
      if (actualQuant + adder > maxQuant / 2) {
        this.openToastOptions(
          this.$t('Aviso!'),
          this.$t('No puede pedir mas de {quantity}', { quantity: maxQuant / 2 })
        );
      } else {
        order.portions += adder;
      }

      this.changeRecipeInfo(order.id, null, order.portions);
    },

    async subQuant(order) {
      const actualQuant = order.portions;
      const adder = 1;
      const minQuant = 0;

      if (!(actualQuant - adder < minQuant)) order.portions -= adder;

      this.changeRecipeInfo(order.id, null, order.portions);
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },

    slideNext(slides) {
      if (!slides.isEnd) {
        slides.slideNext();
      } else {
        this.$axios
          .post('tutorial', {
            field: 'orders_tutorial',
          })
          .then(() => {
            this.timblo.user.orders_tutorial = 1;
          });

        this.tutorialModal = false;
      }
    },

    openRecipe(order) {
      console.log(this.day);
      // this.timblo.selectedOrderDay = this.day;
      this.mealplansRecipes = this.orders.week_plans
        ?.map((a) => {
          return a.recipe_week_plan.filter((r) => r.recipe_id == order.recipe_id);
        })
        .flat();

      this.$router.push({
        name: 'recipe',
        params: {
          id: order.recipe.id,
        },
        query: {
          order_id: this?.orders?.id,
          week_day: order?.week_day
        },
          // order_id: this.orders.id,
          // slot: order?.recipe?.slot_id,
          // week_day: order?.week_day,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.makeHorizontalScroll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.mealplans {
  margin-top: 10px;
  margin-bottom: 10px;
  & > .usersMealplans {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
  }
}
.padding-content {
  padding-left: 24px;
  padding-right: 24px;
}
.order {
  &-header {
    width: 100%;
    background-color: transparent;
    h4 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-days {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    flex-wrap: nowrap;
    flex: 1 0 21%;
    overflow-x: auto;
    margin-top: 14px;

    & > ion-button {
      width: 52px;
      height: 52px;
      border: 1px solid #000;
      border-radius: 10px;

      &.active {
        background-color: var(--ion-color-secondary);
        border-color: transparent;
      }
      &.nActive {
        color: #b7b7b7;
        border-color: #b7b7b7;
      }
    }
  }

  &-content {
    & > h4 {
      margin-bottom: 5px;
    }
    & > p {
      margin-top: 0;
    }

    & > ion-list {
      display: flex;
      flex-direction: column;
      justify-self: center;
      margin-top: 20px;
      background-color: transparent;

      & > .content-order {
        &::after {
          // TODO: separator full screen
          content: '';
        }
        & > ion-card {
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
          display: flex;
          flex-direction: column;
          justify-content: start;
          flex: 25%;
          align-items: start;
          width: 100%;
          height: 100%;
          margin-bottom: 20px;
          & > img {
            max-height: 150px;
            object-fit: cover;
            width: 100%;
          }
          ion-card-header {
            padding: 0px 10px;
            margin-bottom: 16px;
            h5 {
              margin-bottom: 0;
            }
          }
          ion-card-content.card_content {
            padding: 0px 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            p {
              font-size: 16px;
            }
          }
          .removeOrder {
            position: absolute;
            right: 5px;
            top: 5px;
          }
          .favOrder {
            position: absolute;
            right: 5px;
            bottom: calc(60px - 32px);
          }
        }

        .content-select {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          ion-select {
            --placeholder-opacity: 1;
            padding-left: 0;
          }
          ion-select::part(icon) {
            margin-left: 10px;
            opacity: 1;
            content: url('@/../public/assets/icons/arrowDown.svg');
          }

          .countOrder {
            display: flex;
            flex-direction: row;
            align-items: center;

            .value {
              margin-right: 10px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  &-footer {
    margin-top: 10px;
    background-color: transparent;
    .checkOrder {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 10px;
      background-color: transparent;
      border: 1px solid black;
      color: var(--ion-color-primary);
      //font-size: 16;
      font-weight: 400;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.removeBtn {
  background-color: #e28e9d;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  --color: white;
}
ion-content {
  --background: var(--ion-color-tertiary-shade);
  --padding-top: 24px;
  --padding-bottom: 24px;
}
</style>
