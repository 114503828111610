
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TButton',
  props: {
    color: String,
    background: String,
    icon: String,
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
});
