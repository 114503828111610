import en from './en.json';
import es from './es.json';
import ca from './ca.json';
import it from './it.json';

export default {
  en,
  es,
  ca,
  it,
};