<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div>
        <ion-img style="margin: 3em 79px 0" src="assets/logo/logo.svg"></ion-img>
        <ion-img style="margin: 0 84px" src="assets/images/winners.svg"></ion-img>
      </div>

      <div
        style="
          position: absolute;
          bottom: 6em;
          width: 100%;
          padding: 0 16px;
          text-align: center;
        "
      >
        <t-button style="margin-bottom: 16px" @click="$router.push({ name: 'register' })"
          >{{ $t('registrate-con-e-mail') }}</t-button
        >
        <!-- <t-button style="margin-bottom: 16px" icon="google" outline
          >Registrarse con Google</t-button
        > -->
        <a
          @click="$router.push({ name: 'login' })"
          style="color: var(--ion-color-primary)"
          >{{ $t('ya-tengo-una-cuenta') }}</a
        >
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';

import { IonPage, IonContent } from '@ionic/vue';

export default defineComponent({
  name: 'SignUpPage',

  components: {
    TButton,
    IonPage,
    IonContent,
  },

  setup() {
    const timblo = useTimbloStore();
    return {
      timblo,
    };
  },

  mounted() {
    if (Object.keys(this.timblo.user)?.length > 0) {
      this.$router.replace({ name: 'home' });
    }
  },
});
</script>
