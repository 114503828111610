export const orderStatic = [
  {
    "id": 194,
    "pid": "wcryimdc0z",
    "user_id": 17,
    "country_id": 724,
    "ingredients": [
      {
        "cost": "1.49",
        "name": {
          "es": "BERENJENA"
        },
        "quantity": 270,
        "avg_weight": 420,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 13,
        "measure_unit_id": 1
      },
      {
        "cost": "0.98",
        "name": {
          "es": "CALABACÍN"
        },
        "quantity": 270,
        "avg_weight": 300,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 107,
        "measure_unit_id": 1
      },
      {
        "cost": "43.90",
        "name": {
          "es": "Solomillo de ternera"
        },
        "quantity": 270,
        "avg_weight": 0,
        "group_name": {
          "es": "Carne roja"
        },
        "measure_unit": "g",
        "disponibility": 951,
        "measure_unit_id": 1
      },
      {
        "cost": "7.20",
        "name": {
          "es": "ARROZ BASMATI"
        },
        "quantity": 270,
        "avg_weight": 500,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1119,
        "measure_unit_id": 1
      },
      {
        "cost": "3.87",
        "name": {
          "es": "Caldo de verduras"
        },
        "quantity": 300,
        "avg_weight": 1000,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "ml",
        "disponibility": 1496,
        "measure_unit_id": 2
      },
      {
        "cost": "2.48",
        "name": {
          "es": "APIO ecológico"
        },
        "quantity": 240,
        "avg_weight": 1200,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 31,
        "measure_unit_id": 1
      },
      {
        "cost": "2.39",
        "name": {
          "es": "TOMATE DE PERA"
        },
        "quantity": 300,
        "avg_weight": 100,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 529,
        "measure_unit_id": 1
      },
      {
        "cost": "15.90",
        "name": {
          "es": "PECHUGA DE PAVO"
        },
        "quantity": 300,
        "avg_weight": 750,
        "group_name": {
          "es": "Carne blanca"
        },
        "measure_unit": "g",
        "disponibility": 853,
        "measure_unit_id": 1
      },
      {
        "cost": "7.95",
        "name": {
          "es": "QUINOA"
        },
        "quantity": 300,
        "avg_weight": 0,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1438,
        "measure_unit_id": 1
      },
      {
        "cost": "7.47",
        "name": {
          "es": "aguacate"
        },
        "quantity": 300,
        "avg_weight": 320,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1419,
        "measure_unit_id": 1
      }
    ],
    "extra_ingredients": [
      {
        "cost": "11.86",
        "name": {
          "es": "AGUACATE"
        },
        "quantity": 840,
        "avg_weight": 210,
        "group_name": {
          "es": "Fruta"
        },
        "measure_unit": "g",
        "disponibility": 23,
        "measure_unit_id": 1
      }
    ],
    "zip_code": "08911",
    "address": {
      "address_line_1": "av president companys 100, 5, 3",
      "address_line_2": null,
      "city": "badalona",
      "zip": "08911"
    },
    "cost": "45.70",
    "original_cost": null,
    "order_status_id": 2,
    "observations": null,
    "stripe_id": null,
    "delivery_date": 1668630549,
    "delivered_at": null,
    "created_at": 2020,
    "updated_at": 2020,
    "week_plans": [
      {
        "pid": "s2vpv9tudw",
        "available": 1,
        "date": "2022-11-16",
        "cost": "9.96",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 46,
        "recipe_week_plan": [
          {
            "id": 5266,
            "alternatives_ids": [
              30,
              21,
              6
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 31,
              "pid": "3u91qaaolv",
              "name": {
                "es": "Arroz basmati con tiras de ternera a la plancha, berenjena y calabacín al horno"
              },
              "meal_type": 1,
              "image": "31.jpg",
              "image_pre": null,
              "time": 35,
              "description": {
                "es": "<p>Descubre lo sabroso que es el arroz Basmati</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Encender el horno a 220º"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon el caldo en una olla, añade el arroz y deja que se cueza hasta que se haya absorbido todo el caldo. Si ves que se queda seco y el arroz está aun un poco duro, puedes echarle un poco más de caldo durante la cocción."
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta berenjena y calabacín y envuélvelos en papel de aluminio con un poco de sal y pimienta"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Hornea las verduras a 220º durante unos 15 min  Pasa la ternera a la pancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes servir la ternera con las verdura y al arroz como acompañante. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "Cómo cocinar el arroz basmati: lávalo en agua fría, ponlo en la olla con el caldo o el agua aún frío, cuécelo a fuego medio hasta que hierva y después baja y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5269,
            "alternatives_ids": [
              47,
              21,
              6
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": 2020,
            "portions": 0,
            "recipe": {
              "id": 30,
              "pid": "7hvefm930g",
              "name": {
                "es": "Quinoa con pavo a la plancha y verduras, picado de aguacate y tomate"
              },
              "meal_type": 1,
              "image": "30.jpg",
              "image_pre": null,
              "time": 21,
              "description": {
                "es": "<p>Plato fresco y sabroso</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Poner a hervir el agua y salarla (pondremos 2 volúmenes de agua para cada volumen de quinoa). Para un resultado más sabroso se puede reemplazar el agua por caldo"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Pica los tomates, el aguacate, el apio y la cebolla a trocitos"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Echa la quinoa en el agua (o caldo) hirviendo y deja que se vaya cociendo hasta que se haya absorbido todo el agua/caldo. Si ves que se queda seco y la quinoa está aun un poco dura, puedes echarle un poco más de agua durante la cocción."
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Pasa a la plancha el pavo, con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes emplatar el pavo con las verduras y el picado como acompañamiento. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que el aguacate es considerado una grasa? Tiene del 10% al 35% de grasas insaturadas Omega-3 y lineleico. Contiene además muchos fitonutrientes, como unos antioxidantes y está rico en vitamina A y E."
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "9vruinvquk",
        "available": 1,
        "date": "2020-06-15",
        "cost": "9.96",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 25,
        "recipe_week_plan": [
          {
            "id": 5267,
            "alternatives_ids": [
              30,
              21,
              6
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 31,
              "pid": "3u91qaaolv",
              "name": {
                "es": "Arroz basmati con tiras de ternera a la plancha, berenjena y calabacín al horno"
              },
              "meal_type": 1,
              "image": "31.jpg",
              "image_pre": null,
              "time": 35,
              "description": {
                "es": "<p>Descubre lo sabroso que es el arroz Basmati</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Encender el horno a 220º"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon el caldo en una olla, añade el arroz y deja que se cueza hasta que se haya absorbido todo el caldo. Si ves que se queda seco y el arroz está aun un poco duro, puedes echarle un poco más de caldo durante la cocción."
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta berenjena y calabacín y envuélvelos en papel de aluminio con un poco de sal y pimienta"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Hornea las verduras a 220º durante unos 15 min  Pasa la ternera a la pancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes servir la ternera con las verdura y al arroz como acompañante. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "Cómo cocinar el arroz basmati: lávalo en agua fría, ponlo en la olla con el caldo o el agua aún frío, cuécelo a fuego medio hasta que hierva y después baja y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5270,
            "alternatives_ids": [
              47,
              21,
              6
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": 2020,
            "portions": 0,
            "recipe": {
              "id": 30,
              "pid": "7hvefm930g",
              "name": {
                "es": "Quinoa con pavo a la plancha y verduras, picado de aguacate y tomate"
              },
              "meal_type": 1,
              "image": "30.jpg",
              "image_pre": null,
              "time": 21,
              "description": {
                "es": "<p>Plato fresco y sabroso</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Poner a hervir el agua y salarla (pondremos 2 volúmenes de agua para cada volumen de quinoa). Para un resultado más sabroso se puede reemplazar el agua por caldo"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Pica los tomates, el aguacate, el apio y la cebolla a trocitos"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Echa la quinoa en el agua (o caldo) hirviendo y deja que se vaya cociendo hasta que se haya absorbido todo el agua/caldo. Si ves que se queda seco y la quinoa está aun un poco dura, puedes echarle un poco más de agua durante la cocción."
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Pasa a la plancha el pavo, con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes emplatar el pavo con las verduras y el picado como acompañamiento. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que el aguacate es considerado una grasa? Tiene del 10% al 35% de grasas insaturadas Omega-3 y lineleico. Contiene además muchos fitonutrientes, como unos antioxidantes y está rico en vitamina A y E."
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "tax6jamxun",
        "available": 1,
        "date": "2020-06-15",
        "cost": "9.96",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 25,
        "recipe_week_plan": [
          {
            "id": 5268,
            "alternatives_ids": [
              30,
              21,
              6
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 31,
              "pid": "3u91qaaolv",
              "name": {
                "es": "Arroz basmati con tiras de ternera a la plancha, berenjena y calabacín al horno"
              },
              "meal_type": 1,
              "image": "31.jpg",
              "image_pre": null,
              "time": 35,
              "description": {
                "es": "<p>Descubre lo sabroso que es el arroz Basmati</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Encender el horno a 220º"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon el caldo en una olla, añade el arroz y deja que se cueza hasta que se haya absorbido todo el caldo. Si ves que se queda seco y el arroz está aun un poco duro, puedes echarle un poco más de caldo durante la cocción."
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta berenjena y calabacín y envuélvelos en papel de aluminio con un poco de sal y pimienta"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Hornea las verduras a 220º durante unos 15 min  Pasa la ternera a la pancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes servir la ternera con las verdura y al arroz como acompañante. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "Cómo cocinar el arroz basmati: lávalo en agua fría, ponlo en la olla con el caldo o el agua aún frío, cuécelo a fuego medio hasta que hierva y después baja y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5271,
            "alternatives_ids": [
              47,
              21,
              6
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": 2020,
            "portions": 0,
            "recipe": {
              "id": 30,
              "pid": "7hvefm930g",
              "name": {
                "es": "Quinoa con pavo a la plancha y verduras, picado de aguacate y tomate"
              },
              "meal_type": 1,
              "image": "30.jpg",
              "image_pre": null,
              "time": 21,
              "description": {
                "es": "<p>Plato fresco y sabroso</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Poner a hervir el agua y salarla (pondremos 2 volúmenes de agua para cada volumen de quinoa). Para un resultado más sabroso se puede reemplazar el agua por caldo"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Pica los tomates, el aguacate, el apio y la cebolla a trocitos"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Echa la quinoa en el agua (o caldo) hirviendo y deja que se vaya cociendo hasta que se haya absorbido todo el agua/caldo. Si ves que se queda seco y la quinoa está aun un poco dura, puedes echarle un poco más de agua durante la cocción."
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Pasa a la plancha el pavo, con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes emplatar el pavo con las verduras y el picado como acompañamiento. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que el aguacate es considerado una grasa? Tiene del 10% al 35% de grasas insaturadas Omega-3 y lineleico. Contiene además muchos fitonutrientes, como unos antioxidantes y está rico en vitamina A y E."
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      }
    ]
  },
  {
    "id": 216,
    "pid": "apsnod3p1w",
    "user_id": 17,
    "country_id": 724,
    "ingredients": [
      {
        "cost": "15.90",
        "name": {
          "es": "PECHUGA DE PAVO"
        },
        "quantity": 450,
        "avg_weight": 750,
        "group_name": {
          "es": "Carne blanca"
        },
        "measure_unit": "g",
        "disponibility": 853,
        "measure_unit_id": 1
      },
      {
        "cost": "2.10",
        "name": {
          "es": "ARROZ INTEGRAL"
        },
        "quantity": 150,
        "avg_weight": 0,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1121,
        "measure_unit_id": 1
      },
      {
        "cost": "2.90",
        "name": {
          "es": "LECHE DE COCO"
        },
        "quantity": 114,
        "avg_weight": 1000,
        "group_name": {
          "es": "Extra"
        },
        "measure_unit": "ml",
        "disponibility": 1281,
        "measure_unit_id": 2
      },
      {
        "cost": "3.60",
        "name": {
          "es": "HARINA"
        },
        "quantity": 36,
        "avg_weight": 500,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1041,
        "measure_unit_id": 1
      },
      {
        "cost": "1.95",
        "name": {
          "es": "CEBOLLA DULCE"
        },
        "quantity": 36,
        "avg_weight": 190,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 119,
        "measure_unit_id": 1
      },
      {
        "cost": "12.00",
        "name": {
          "es": "PEREJIL Seco"
        },
        "quantity": 30,
        "avg_weight": 100,
        "group_name": {
          "es": "Extra"
        },
        "measure_unit": "g",
        "disponibility": 1436,
        "measure_unit_id": 1
      },
      {
        "cost": "3.45",
        "name": {
          "es": "espinaca"
        },
        "quantity": 186,
        "avg_weight": 490,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 225,
        "measure_unit_id": 1
      },
      {
        "cost": "0.99",
        "name": {
          "es": "CEBOLLA BLANCA"
        },
        "quantity": 240,
        "avg_weight": 320,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 115,
        "measure_unit_id": 1
      },
      {
        "cost": "12.42",
        "name": {
          "es": "ESPÁRRAGO VERDE"
        },
        "quantity": 240,
        "avg_weight": 240,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 217,
        "measure_unit_id": 1
      },
      {
        "cost": "12.90",
        "name": {
          "es": "PECHUGA DE POLLO"
        },
        "quantity": 300,
        "avg_weight": 400,
        "group_name": {
          "es": "Carne blanca"
        },
        "measure_unit": "g",
        "disponibility": 855,
        "measure_unit_id": 1
      },
      {
        "cost": "7.20",
        "name": {
          "es": "ARROZ BASMATI"
        },
        "quantity": 300,
        "avg_weight": 500,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1119,
        "measure_unit_id": 1
      },
      {
        "cost": "3.87",
        "name": {
          "es": "Caldo de verduras"
        },
        "quantity": 3000,
        "avg_weight": 1000,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "ml",
        "disponibility": 1496,
        "measure_unit_id": 2
      },
      {
        "cost": "4.00",
        "name": {
          "es": "SALSA DE TOMATE"
        },
        "quantity": 240,
        "avg_weight": 400,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1446,
        "measure_unit_id": 1
      }
    ],
    "extra_ingredients": null,
    "zip_code": "08911",
    "address": {
      "address_line_1": "av president companys 100, 5, 3",
      "address_line_2": null,
      "city": "badalona",
      "zip": "08911"
    },
    "cost": "104.12",
    "original_cost": null,
    "order_status_id": 2,
    "observations": null,
    "stripe_id": null,
    "delivery_date": 1668630549,
    "delivered_at": null,
    "created_at": 2020,
    "updated_at": 2020,
    "week_plans": [
      {
        "pid": "4nfvxj95tr",
        "available": 1,
        "date": "2022-11-16",
        "cost": "17.21",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 46,
        "recipe_week_plan": [
          {
            "id": 5278,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 75,
              "pid": "lp4j7e1b9a",
              "name": {
                "es": "Pavo al curry con arroz integral"
              },
              "meal_type": 1,
              "image": "75.jpg",
              "image_pre": null,
              "time": 10,
              "description": {
                "es": "Para rellenar"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": "Qué hay que calentar"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Pon a hervir el agua o el caldo de verduras (para un resultado más sabroso)"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué base hay que preparar"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "En la batidora mezcla harina y curry"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué hay que preparar"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta la cebolla\nCorta el pavo en tiras y pasado en al mix de harina y curry"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": "Qué hay que cocinar"
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Cuece el arroz en el caldo hasta que no haya absorbido el liquido e esté \nDora la cebolla en una sartén con aceite\nCuando esté dorada añade las tiras de pavo\n Cuando el pavo empieza a coger coló añada leche de coco y sigue adeudando el jugo. 5 minutos antes de termina la cocción puedes añadir espinacas frescas y limpias"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué hay que emplatar"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Sirve el pavo con el arroz como acompañamiento, puedes añadir al último perejil triturado y ajustar de pimienta"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5281,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 29,
              "pid": "l8ouaaxljy",
              "name": {
                "es": "Arroz basmati con pollo, salsa de tomate y espárragos"
              },
              "meal_type": 1,
              "image": "29.jpg",
              "image_pre": null,
              "time": 28,
              "description": {
                "es": "<p>Blanco, rojo y verde</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon agua (o caldo) a hervir en una olla, con un poco de sal gorda. Recuerda ponerle tapa, para que hierva más rápido. Acuérdate de echar el arroz con el caldo aún frío, sin esperar a que hierva"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "En una sartén, ve haciendo el tomate triturado con un poco de aceite, sal y pimienta al gusto. (Para darle más sabor, puedes dorar una mitad de cebolla cortada fina con un hilo de aceite y después añadir el tomate triturado)"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Corta los tallos de los espárragos y límpialos con un poco de agua. Cuécelos unos 4 min en el agua o el caldo hirviendo  Luego, sácalos con la ayuda de una espumadera; en esa misma agua (o caldo) hirviendo, echaremos el arroz. Dejar que se vaya cociendo hasta que se haya absorbido todo el líquido. Pasar el pollo cortado a la plancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Servir el arroz con los espárragos y el tomate triturado y la pechuga de pollo al lado. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que para cocinar los espárragos hay que pelarlos? Si con un pela patatas quitas la piel de los tallos de los espárragos puedes cocinarlos en tan solo 5 min en agua hirviendo: conservando mejor sus propiedades nutricionales"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "4c3c84somi",
        "available": 1,
        "date": "2020-06-29",
        "cost": "17.21",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 27,
        "recipe_week_plan": [
          {
            "id": 5279,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 75,
              "pid": "lp4j7e1b9a",
              "name": {
                "es": "Pavo al curry con arroz integral"
              },
              "meal_type": 1,
              "image": "75.jpg",
              "image_pre": null,
              "time": 10,
              "description": {
                "es": "Para rellenar"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": "Qué hay que calentar"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Pon a hervir el agua o el caldo de verduras (para un resultado más sabroso)"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué base hay que preparar"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "En la batidora mezcla harina y curry"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué hay que preparar"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta la cebolla\nCorta el pavo en tiras y pasado en al mix de harina y curry"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": "Qué hay que cocinar"
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Cuece el arroz en el caldo hasta que no haya absorbido el liquido e esté \nDora la cebolla en una sartén con aceite\nCuando esté dorada añade las tiras de pavo\n Cuando el pavo empieza a coger coló añada leche de coco y sigue adeudando el jugo. 5 minutos antes de termina la cocción puedes añadir espinacas frescas y limpias"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué hay que emplatar"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Sirve el pavo con el arroz como acompañamiento, puedes añadir al último perejil triturado y ajustar de pimienta"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5282,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 29,
              "pid": "l8ouaaxljy",
              "name": {
                "es": "Arroz basmati con pollo, salsa de tomate y espárragos"
              },
              "meal_type": 1,
              "image": "29.jpg",
              "image_pre": null,
              "time": 28,
              "description": {
                "es": "<p>Blanco, rojo y verde</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon agua (o caldo) a hervir en una olla, con un poco de sal gorda. Recuerda ponerle tapa, para que hierva más rápido. Acuérdate de echar el arroz con el caldo aún frío, sin esperar a que hierva"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "En una sartén, ve haciendo el tomate triturado con un poco de aceite, sal y pimienta al gusto. (Para darle más sabor, puedes dorar una mitad de cebolla cortada fina con un hilo de aceite y después añadir el tomate triturado)"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Corta los tallos de los espárragos y límpialos con un poco de agua. Cuécelos unos 4 min en el agua o el caldo hirviendo  Luego, sácalos con la ayuda de una espumadera; en esa misma agua (o caldo) hirviendo, echaremos el arroz. Dejar que se vaya cociendo hasta que se haya absorbido todo el líquido. Pasar el pollo cortado a la plancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Servir el arroz con los espárragos y el tomate triturado y la pechuga de pollo al lado. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que para cocinar los espárragos hay que pelarlos? Si con un pela patatas quitas la piel de los tallos de los espárragos puedes cocinarlos en tan solo 5 min en agua hirviendo: conservando mejor sus propiedades nutricionales"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "3zzh3ewq9b",
        "available": 1,
        "date": "2020-06-29",
        "cost": "17.21",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 27,
        "recipe_week_plan": [
          {
            "id": 5280,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 75,
              "pid": "lp4j7e1b9a",
              "name": {
                "es": "Pavo al curry con arroz integral"
              },
              "meal_type": 1,
              "image": "75.jpg",
              "image_pre": null,
              "time": 10,
              "description": {
                "es": "Para rellenar"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": "Qué hay que calentar"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Pon a hervir el agua o el caldo de verduras (para un resultado más sabroso)"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué base hay que preparar"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "En la batidora mezcla harina y curry"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué hay que preparar"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta la cebolla\nCorta el pavo en tiras y pasado en al mix de harina y curry"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": "Qué hay que cocinar"
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Cuece el arroz en el caldo hasta que no haya absorbido el liquido e esté \nDora la cebolla en una sartén con aceite\nCuando esté dorada añade las tiras de pavo\n Cuando el pavo empieza a coger coló añada leche de coco y sigue adeudando el jugo. 5 minutos antes de termina la cocción puedes añadir espinacas frescas y limpias"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": "Qué hay que emplatar"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Sirve el pavo con el arroz como acompañamiento, puedes añadir al último perejil triturado y ajustar de pimienta"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5283,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 29,
              "pid": "l8ouaaxljy",
              "name": {
                "es": "Arroz basmati con pollo, salsa de tomate y espárragos"
              },
              "meal_type": 1,
              "image": "29.jpg",
              "image_pre": null,
              "time": 28,
              "description": {
                "es": "<p>Blanco, rojo y verde</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon agua (o caldo) a hervir en una olla, con un poco de sal gorda. Recuerda ponerle tapa, para que hierva más rápido. Acuérdate de echar el arroz con el caldo aún frío, sin esperar a que hierva"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "En una sartén, ve haciendo el tomate triturado con un poco de aceite, sal y pimienta al gusto. (Para darle más sabor, puedes dorar una mitad de cebolla cortada fina con un hilo de aceite y después añadir el tomate triturado)"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Corta los tallos de los espárragos y límpialos con un poco de agua. Cuécelos unos 4 min en el agua o el caldo hirviendo  Luego, sácalos con la ayuda de una espumadera; en esa misma agua (o caldo) hirviendo, echaremos el arroz. Dejar que se vaya cociendo hasta que se haya absorbido todo el líquido. Pasar el pollo cortado a la plancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Servir el arroz con los espárragos y el tomate triturado y la pechuga de pollo al lado. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que para cocinar los espárragos hay que pelarlos? Si con un pela patatas quitas la piel de los tallos de los espárragos puedes cocinarlos en tan solo 5 min en agua hirviendo: conservando mejor sus propiedades nutricionales"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      }
    ]
  },
  {
    "id": 274,
    "pid": "ca7k9zrtrt",
    "user_id": 17,
    "country_id": 724,
    "ingredients": [
      {
        "cost": "9.80",
        "name": {
          "es": "JENGIBRE 100g"
        },
        "quantity": 75,
        "avg_weight": 100,
        "group_name": {
          "es": "Fruta"
        },
        "measure_unit": "g",
        "disponibility": 1448,
        "measure_unit_id": 1
      },
      {
        "cost": "1.95",
        "name": {
          "es": "CEBOLLA DULCE"
        },
        "quantity": 36,
        "avg_weight": 190,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 119,
        "measure_unit_id": 1
      },
      {
        "cost": "3.99",
        "name": {
          "es": "PEPINO Ecológico"
        },
        "quantity": 120,
        "avg_weight": 200,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1488,
        "measure_unit_id": 1
      },
      {
        "cost": "7.95",
        "name": {
          "es": "TOMATE CHERRY"
        },
        "quantity": 120,
        "avg_weight": 15,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1494,
        "measure_unit_id": 1
      },
      {
        "cost": "16.90",
        "name": {
          "es": "FILETE DE SALMÓN"
        },
        "quantity": 300,
        "avg_weight": 0,
        "group_name": {
          "es": "Pescado azul"
        },
        "measure_unit": "g",
        "disponibility": 1083,
        "measure_unit_id": 1
      },
      {
        "cost": "7.20",
        "name": {
          "es": "ARROZ BASMATI"
        },
        "quantity": 495,
        "avg_weight": 500,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1119,
        "measure_unit_id": 1
      },
      {
        "cost": "7.15",
        "name": {
          "es": "Aguacate de Malaga"
        },
        "quantity": 150,
        "avg_weight": null,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": null,
        "measure_unit_id": 1
      },
      {
        "cost": "1.79",
        "name": {
          "es": "BERENJENA"
        },
        "quantity": 270,
        "avg_weight": 420,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 13,
        "measure_unit_id": 1
      },
      {
        "cost": "0.98",
        "name": {
          "es": "CALABACÍN"
        },
        "quantity": 270,
        "avg_weight": 300,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 107,
        "measure_unit_id": 1
      },
      {
        "cost": "43.90",
        "name": {
          "es": "Solomillo de ternera"
        },
        "quantity": 270,
        "avg_weight": 0,
        "group_name": {
          "es": "Carne roja"
        },
        "measure_unit": "g",
        "disponibility": 951,
        "measure_unit_id": 1
      },
      {
        "cost": "3.87",
        "name": {
          "es": "Caldo de verduras"
        },
        "quantity": 300,
        "avg_weight": 1000,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "ml",
        "disponibility": 1496,
        "measure_unit_id": 2
      }
    ],
    "extra_ingredients": null,
    "zip_code": "08911",
    "address": {
      "address_line_1": "av president companys 100, 5, 3",
      "address_line_2": null,
      "city": "badalona",
      "zip": "08911"
    },
    "cost": "33.24",
    "original_cost": null,
    "order_status_id": 2,
    "observations": null,
    "stripe_id": null,
    "delivery_date": 1668630549,
    "delivered_at": null,
    "created_at": 2020,
    "updated_at": 2020,
    "week_plans": [
      {
        "pid": "hnnrg9vkp8",
        "available": 1,
        "date": "2022-11-16",
        "cost": "14.94",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 46,
        "recipe_week_plan": [
          {
            "id": 5284,
            "alternatives_ids": [
              21,
              140,
              176
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 53,
              "pid": "gri9j6w775",
              "name": {
                "es": "Poke de salmón"
              },
              "meal_type": 1,
              "image": "53.jpg",
              "image_pre": null,
              "time": 40,
              "description": {
                "es": "<p>Salmón con verduras y un toque asiático</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 23,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Corta el salmón en dados y deja macerar 20 min en salsa de soja, jengibre rallado y semillas de chía (también puedes añadir limón)"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta todas las verduras en pequeños trozos para que las puedas comer luego fácilmente sin tener que partirlas"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Cuece el arroz Basmati en agua o caldo. El arroz Basmati va lavado antes de ser cocinado en 1 parte de arroz por una parte de agua"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Ve colocando los ingredientes por capas: el arroz en la base, luego las verduras y finalmente el salmón macerado. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que el arroz Basmati se cuece sin hacer hervir el agua o al caldo en antelación? Ponlo en la olla y déjalo a fuego medio hasta que hierva, en cuanto hierve baja el fuego y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5287,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 31,
              "pid": "3u91qaaolv",
              "name": {
                "es": "Arroz basmati con tiras de ternera a la plancha, berenjena y calabacín al horno"
              },
              "meal_type": 1,
              "image": "31.jpg",
              "image_pre": null,
              "time": 35,
              "description": {
                "es": "<p>Descubre lo sabroso que es el arroz Basmati</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Encender el horno a 220º"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon el caldo en una olla, añade el arroz y deja que se cueza hasta que se haya absorbido todo el caldo. Si ves que se queda seco y el arroz está aun un poco duro, puedes echarle un poco más de caldo durante la cocción."
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta berenjena y calabacín y envuélvelos en papel de aluminio con un poco de sal y pimienta"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Hornea las verduras a 220º durante unos 15 min  Pasa la ternera a la pancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes servir la ternera con las verdura y al arroz como acompañante. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "Cómo cocinar el arroz basmati: lávalo en agua fría, ponlo en la olla con el caldo o el agua aún frío, cuécelo a fuego medio hasta que hierva y después baja y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "lyvous194b",
        "available": 1,
        "date": "2020-07-06",
        "cost": "14.94",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 28,
        "recipe_week_plan": [
          {
            "id": 5285,
            "alternatives_ids": [
              21,
              140,
              176
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 53,
              "pid": "gri9j6w775",
              "name": {
                "es": "Poke de salmón"
              },
              "meal_type": 1,
              "image": "53.jpg",
              "image_pre": null,
              "time": 40,
              "description": {
                "es": "<p>Salmón con verduras y un toque asiático</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 23,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Corta el salmón en dados y deja macerar 20 min en salsa de soja, jengibre rallado y semillas de chía (también puedes añadir limón)"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta todas las verduras en pequeños trozos para que las puedas comer luego fácilmente sin tener que partirlas"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Cuece el arroz Basmati en agua o caldo. El arroz Basmati va lavado antes de ser cocinado en 1 parte de arroz por una parte de agua"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Ve colocando los ingredientes por capas: el arroz en la base, luego las verduras y finalmente el salmón macerado. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que el arroz Basmati se cuece sin hacer hervir el agua o al caldo en antelación? Ponlo en la olla y déjalo a fuego medio hasta que hierva, en cuanto hierve baja el fuego y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5288,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 31,
              "pid": "3u91qaaolv",
              "name": {
                "es": "Arroz basmati con tiras de ternera a la plancha, berenjena y calabacín al horno"
              },
              "meal_type": 1,
              "image": "31.jpg",
              "image_pre": null,
              "time": 35,
              "description": {
                "es": "<p>Descubre lo sabroso que es el arroz Basmati</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Encender el horno a 220º"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon el caldo en una olla, añade el arroz y deja que se cueza hasta que se haya absorbido todo el caldo. Si ves que se queda seco y el arroz está aun un poco duro, puedes echarle un poco más de caldo durante la cocción."
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta berenjena y calabacín y envuélvelos en papel de aluminio con un poco de sal y pimienta"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Hornea las verduras a 220º durante unos 15 min  Pasa la ternera a la pancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes servir la ternera con las verdura y al arroz como acompañante. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "Cómo cocinar el arroz basmati: lávalo en agua fría, ponlo en la olla con el caldo o el agua aún frío, cuécelo a fuego medio hasta que hierva y después baja y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "xvksqvr2vo",
        "available": 1,
        "date": "2020-07-06",
        "cost": "14.94",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 28,
        "recipe_week_plan": [
          {
            "id": 5286,
            "alternatives_ids": [
              21,
              140,
              176
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 53,
              "pid": "gri9j6w775",
              "name": {
                "es": "Poke de salmón"
              },
              "meal_type": 1,
              "image": "53.jpg",
              "image_pre": null,
              "time": 40,
              "description": {
                "es": "<p>Salmón con verduras y un toque asiático</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 23,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Corta el salmón en dados y deja macerar 20 min en salsa de soja, jengibre rallado y semillas de chía (también puedes añadir limón)"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta todas las verduras en pequeños trozos para que las puedas comer luego fácilmente sin tener que partirlas"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Cuece el arroz Basmati en agua o caldo. El arroz Basmati va lavado antes de ser cocinado en 1 parte de arroz por una parte de agua"
                  }
                },
                {
                  "time": 2,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Ve colocando los ingredientes por capas: el arroz en la base, luego las verduras y finalmente el salmón macerado. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que el arroz Basmati se cuece sin hacer hervir el agua o al caldo en antelación? Ponlo en la olla y déjalo a fuego medio hasta que hierva, en cuanto hierve baja el fuego y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 5289,
            "alternatives_ids": [
              21,
              6,
              140
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 31,
              "pid": "3u91qaaolv",
              "name": {
                "es": "Arroz basmati con tiras de ternera a la plancha, berenjena y calabacín al horno"
              },
              "meal_type": 1,
              "image": "31.jpg",
              "image_pre": null,
              "time": 35,
              "description": {
                "es": "<p>Descubre lo sabroso que es el arroz Basmati</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 3,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Encender el horno a 220º"
                  }
                },
                {
                  "time": 15,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Para el arroz basmati, calcularemos una medida de agua o caldo para cada medida de arroz. Pon el caldo en una olla, añade el arroz y deja que se cueza hasta que se haya absorbido todo el caldo. Si ves que se queda seco y el arroz está aun un poco duro, puedes echarle un poco más de caldo durante la cocción."
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Corta berenjena y calabacín y envuélvelos en papel de aluminio con un poco de sal y pimienta"
                  }
                },
                {
                  "time": 20,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Hornea las verduras a 220º durante unos 15 min  Pasa la ternera a la pancha con un hilo de aceite"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Puedes servir la ternera con las verdura y al arroz como acompañante. ¡A comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "Cómo cocinar el arroz basmati: lávalo en agua fría, ponlo en la olla con el caldo o el agua aún frío, cuécelo a fuego medio hasta que hierva y después baja y deja que acabe la cocción"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      }
    ]
  },
  {
    "id": 294,
    "pid": "tli7a17epo",
    "user_id": 17,
    "country_id": 724,
    "ingredients": [
      {
        "cost": "0.00",
        "name": {
          "es": "agua"
        },
        "quantity": 36,
        "avg_weight": 0,
        "group_name": {
          "es": "Extra"
        },
        "measure_unit": "ml",
        "disponibility": 1423,
        "measure_unit_id": 2
      },
      {
        "cost": "2.48",
        "name": {
          "es": "APIO ecológico"
        },
        "quantity": 60,
        "avg_weight": 1200,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 31,
        "measure_unit_id": 1
      },
      {
        "cost": "2.08",
        "name": {
          "es": "ACELGA DE COLORES"
        },
        "quantity": 225,
        "avg_weight": 600,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1476,
        "measure_unit_id": 1
      },
      {
        "cost": "2.49",
        "name": {
          "es": "CEBOLLA de Figueres"
        },
        "quantity": 24,
        "avg_weight": 100,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 117,
        "measure_unit_id": 1
      },
      {
        "cost": "1.95",
        "name": {
          "es": "CEBOLLA DULCE"
        },
        "quantity": 18,
        "avg_weight": 190,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 119,
        "measure_unit_id": 1
      },
      {
        "cost": "1.49",
        "name": {
          "es": "ZANAHORIA"
        },
        "quantity": 225,
        "avg_weight": 100,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 367,
        "measure_unit_id": 1
      },
      {
        "cost": "5.99",
        "name": {
          "es": "ALUBIA BLANCA seca"
        },
        "quantity": 564,
        "avg_weight": 0,
        "group_name": {
          "es": "Legumbres"
        },
        "measure_unit": "g",
        "disponibility": 563,
        "measure_unit_id": 1
      },
      {
        "cost": "2.10",
        "name": {
          "es": "ARROZ INTEGRAL"
        },
        "quantity": 186,
        "avg_weight": 0,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1121,
        "measure_unit_id": 1
      },
      {
        "cost": "8.00",
        "name": {
          "es": "CACAHUETE"
        },
        "quantity": 75,
        "avg_weight": 0,
        "group_name": {
          "es": "Fruta"
        },
        "measure_unit": "g",
        "disponibility": 1229,
        "measure_unit_id": 1
      },
      {
        "cost": "28.00",
        "name": {
          "es": "NUECES"
        },
        "quantity": 36,
        "avg_weight": 0,
        "group_name": {
          "es": "Fruta"
        },
        "measure_unit": "g",
        "disponibility": 1241,
        "measure_unit_id": 1
      },
      {
        "cost": "3.87",
        "name": {
          "es": "Caldo de verduras"
        },
        "quantity": 420,
        "avg_weight": 1000,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "ml",
        "disponibility": 1496,
        "measure_unit_id": 2
      },
      {
        "cost": "4.00",
        "name": {
          "es": "SALSA DE TOMATE"
        },
        "quantity": 300,
        "avg_weight": 400,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1446,
        "measure_unit_id": 1
      },
      {
        "cost": "22.96",
        "name": {
          "es": "Filete de palangre"
        },
        "quantity": 600,
        "avg_weight": 0,
        "group_name": {
          "es": "Pescado blanco"
        },
        "measure_unit": "g",
        "disponibility": 1073,
        "measure_unit_id": 1
      },
      {
        "cost": "6.32",
        "name": {
          "es": "ARROZ"
        },
        "quantity": 150,
        "avg_weight": 500,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1117,
        "measure_unit_id": 1
      },
      {
        "cost": "0.04",
        "name": {
          "es": "DIENTE DE AJO"
        },
        "quantity": 0,
        "avg_weight": 12,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "ud",
        "disponibility": 21,
        "measure_unit_id": 3
      },
      {
        "cost": "3.04",
        "name": {
          "es": "espinaca"
        },
        "quantity": 114,
        "avg_weight": 490,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 225,
        "measure_unit_id": 1
      }
    ],
    "extra_ingredients": null,
    "zip_code": "08911",
    "address": {
      "address_line_1": "av president companys 100, 5, 3",
      "address_line_2": null,
      "city": "badalona",
      "zip": "08911"
    },
    "cost": "38.19",
    "original_cost": null,
    "order_status_id": 2,
    "observations": null,
    "stripe_id": null,
    "delivery_date": 1668630549,
    "delivered_at": null,
    "created_at": 2020,
    "updated_at": 2020,
    "week_plans": [
      {
        "pid": "8uuq1pr97m",
        "available": 1,
        "date": "2022-11-16",
        "cost": "10.35",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 46,
        "recipe_week_plan": [
          {
            "id": 10823,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 47,
              "pid": "upfope10u5",
              "name": {
                "es": "Arroz con acelga de colores y nueces + alubias al horno con verduras"
              },
              "meal_type": 1,
              "image": "47.jpg",
              "image_pre": null,
              "time": 60,
              "description": {
                "es": "<p>Plato sano y completo. 20 minutos de atención y 60 min de cocción</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 4,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": "Para el arroz"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Lleva el caldo a ebullición. Tritura la cebolla dulce y dórala en una olla con un hilo de aceite. Corta las acelgas en láminas finas y póchala con la cebolla dulce durante un par de minutos Añade el arroz y tuéstalo un poquito (3 min) Luego añadiremos el caldo hirviendo y dejaremos que se haga el arroz, sin que se pase de cocción"
                  }
                },
                {
                  "time": 30,
                  "title": {
                    "es": "Para la mantequilla de anacardos"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Tritura los anacardos con la batidora, añadiendo un poco de aceite de oliva si necesario; luego añade las nueces partidas a trocitos por encima de la crema. Ajusta de sal y pimienta si queires."
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": "Para las alubias"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Tritura la cebolla y limpia un diente de ajo (si lo partes en dos y le quitas el corazón central se repetirá menos) Ponlos en un plato que vaya al horno, con un hilo de aceite, durante unos 15 min a 180º. Mientras tanto limpia y tritura la zanahoria, el apio y el perejil. Limpia las alubias (cocidas) en agua abundante"
                  }
                },
                {
                  "time": 30,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Saca la bandeja del horno para añadir las verduras, las alubias y unos 50 ml de agua, dejar en el horno 30 min. Después, añadir la salsa de tomate, el orégano, ajustar de sal y pimienta y dejar cocinar durante otros 50 min"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": "Cómo servir"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Cuando el arroz esté al punto remuévelo con la crema de anacardos y nueces. Sirve de un lado las alubias con verduras y del otro el arroz. ¡A comer!"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 10826,
            "alternatives_ids": [
              21,
              135,
              176
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 260,
              "pid": "6nok3f2vxo",
              "name": {
                "es": "Merluza con arroz verde"
              },
              "meal_type": 1,
              "image": "260.jpg",
              "image_pre": null,
              "time": 20,
              "description": {
                "es": "Para rellenar"
              },
              "podcast": "0",
              "fuente": "Karlos Arguiñano",
              "consejo": "Si quieres freír la zanahoria, para obtener un resultado más crujiente, rállala, déjala a remojo en agua durante 15 minutos, escúrrela bien y después fríala",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 2,
              "office_friendly": true,
              "steps": [
                {
                  "time": 4,
                  "title": {
                    "es": "Qué hay que calentar"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Calentar medio litro de agua en una sartén"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": "Qué base hay que preparar"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Pasa las espinacas limpias por agua, y pasa el todo en la batidora: agua de cocción y espinacas, cuela el liquido obtenido, con el que vas a cocinar el arroz"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": "Qué hay que preparar"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Pela las zanahorias y rallas, para un resultado más sano añádelas a tu plato en crudo, si quieres darle más sabor fríalas en una sartén en abundante aceite y encurdelas en papel de cocina para absorber el aceite sobrante"
                  }
                },
                {
                  "time": 16,
                  "title": {
                    "es": "Qué hay que cocinar"
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Aplasta con un golpe los ajos y dóralos en una cazuela, cuando cogen color añada el arroz, rehogado un minuto, sazona y cuécelo 18 minutos a fuego medio en el licuado de espinacas\nPescado: Mientras se hace el arroz pasa los filetes de merluza a la plancha: 3 minutos por lado, primero el lado con la piel, dale solo una vuelta"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": "Qué hay que emplatar"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Sirve los filetes de merluza, con el crujiente de zanahoria por encima (si lo has freído) y el arroz verde al lado y ¡a comer!"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "cz1rztq6ti",
        "available": 1,
        "date": "2020-07-20",
        "cost": "10.35",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 30,
        "recipe_week_plan": [
          {
            "id": 10824,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 47,
              "pid": "upfope10u5",
              "name": {
                "es": "Arroz con acelga de colores y nueces + alubias al horno con verduras"
              },
              "meal_type": 1,
              "image": "47.jpg",
              "image_pre": null,
              "time": 60,
              "description": {
                "es": "<p>Plato sano y completo. 20 minutos de atención y 60 min de cocción</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 4,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": "Para el arroz"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Lleva el caldo a ebullición. Tritura la cebolla dulce y dórala en una olla con un hilo de aceite. Corta las acelgas en láminas finas y póchala con la cebolla dulce durante un par de minutos Añade el arroz y tuéstalo un poquito (3 min) Luego añadiremos el caldo hirviendo y dejaremos que se haga el arroz, sin que se pase de cocción"
                  }
                },
                {
                  "time": 30,
                  "title": {
                    "es": "Para la mantequilla de anacardos"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Tritura los anacardos con la batidora, añadiendo un poco de aceite de oliva si necesario; luego añade las nueces partidas a trocitos por encima de la crema. Ajusta de sal y pimienta si queires."
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": "Para las alubias"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Tritura la cebolla y limpia un diente de ajo (si lo partes en dos y le quitas el corazón central se repetirá menos) Ponlos en un plato que vaya al horno, con un hilo de aceite, durante unos 15 min a 180º. Mientras tanto limpia y tritura la zanahoria, el apio y el perejil. Limpia las alubias (cocidas) en agua abundante"
                  }
                },
                {
                  "time": 30,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Saca la bandeja del horno para añadir las verduras, las alubias y unos 50 ml de agua, dejar en el horno 30 min. Después, añadir la salsa de tomate, el orégano, ajustar de sal y pimienta y dejar cocinar durante otros 50 min"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": "Cómo servir"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Cuando el arroz esté al punto remuévelo con la crema de anacardos y nueces. Sirve de un lado las alubias con verduras y del otro el arroz. ¡A comer!"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 10827,
            "alternatives_ids": [
              21,
              135,
              176
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 260,
              "pid": "6nok3f2vxo",
              "name": {
                "es": "Merluza con arroz verde"
              },
              "meal_type": 1,
              "image": "260.jpg",
              "image_pre": null,
              "time": 20,
              "description": {
                "es": "Para rellenar"
              },
              "podcast": "0",
              "fuente": "Karlos Arguiñano",
              "consejo": "Si quieres freír la zanahoria, para obtener un resultado más crujiente, rállala, déjala a remojo en agua durante 15 minutos, escúrrela bien y después fríala",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 2,
              "office_friendly": true,
              "steps": [
                {
                  "time": 4,
                  "title": {
                    "es": "Qué hay que calentar"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Calentar medio litro de agua en una sartén"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": "Qué base hay que preparar"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Pasa las espinacas limpias por agua, y pasa el todo en la batidora: agua de cocción y espinacas, cuela el liquido obtenido, con el que vas a cocinar el arroz"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": "Qué hay que preparar"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Pela las zanahorias y rallas, para un resultado más sano añádelas a tu plato en crudo, si quieres darle más sabor fríalas en una sartén en abundante aceite y encurdelas en papel de cocina para absorber el aceite sobrante"
                  }
                },
                {
                  "time": 16,
                  "title": {
                    "es": "Qué hay que cocinar"
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Aplasta con un golpe los ajos y dóralos en una cazuela, cuando cogen color añada el arroz, rehogado un minuto, sazona y cuécelo 18 minutos a fuego medio en el licuado de espinacas\nPescado: Mientras se hace el arroz pasa los filetes de merluza a la plancha: 3 minutos por lado, primero el lado con la piel, dale solo una vuelta"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": "Qué hay que emplatar"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Sirve los filetes de merluza, con el crujiente de zanahoria por encima (si lo has freído) y el arroz verde al lado y ¡a comer!"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "mjoxeinpyx",
        "available": 1,
        "date": "2020-07-20",
        "cost": "10.35",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 30,
        "recipe_week_plan": [
          {
            "id": 10825,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 47,
              "pid": "upfope10u5",
              "name": {
                "es": "Arroz con acelga de colores y nueces + alubias al horno con verduras"
              },
              "meal_type": 1,
              "image": "47.jpg",
              "image_pre": null,
              "time": 60,
              "description": {
                "es": "<p>Plato sano y completo. 20 minutos de atención y 60 min de cocción</p>"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 4,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": true,
              "steps": [
                {
                  "time": 20,
                  "title": {
                    "es": "Para el arroz"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Lleva el caldo a ebullición. Tritura la cebolla dulce y dórala en una olla con un hilo de aceite. Corta las acelgas en láminas finas y póchala con la cebolla dulce durante un par de minutos Añade el arroz y tuéstalo un poquito (3 min) Luego añadiremos el caldo hirviendo y dejaremos que se haga el arroz, sin que se pase de cocción"
                  }
                },
                {
                  "time": 30,
                  "title": {
                    "es": "Para la mantequilla de anacardos"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Tritura los anacardos con la batidora, añadiendo un poco de aceite de oliva si necesario; luego añade las nueces partidas a trocitos por encima de la crema. Ajusta de sal y pimienta si queires."
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": "Para las alubias"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Tritura la cebolla y limpia un diente de ajo (si lo partes en dos y le quitas el corazón central se repetirá menos) Ponlos en un plato que vaya al horno, con un hilo de aceite, durante unos 15 min a 180º. Mientras tanto limpia y tritura la zanahoria, el apio y el perejil. Limpia las alubias (cocidas) en agua abundante"
                  }
                },
                {
                  "time": 30,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Saca la bandeja del horno para añadir las verduras, las alubias y unos 50 ml de agua, dejar en el horno 30 min. Después, añadir la salsa de tomate, el orégano, ajustar de sal y pimienta y dejar cocinar durante otros 50 min"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": "Cómo servir"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Cuando el arroz esté al punto remuévelo con la crema de anacardos y nueces. Sirve de un lado las alubias con verduras y del otro el arroz. ¡A comer!"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2021,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 10828,
            "alternatives_ids": [
              21,
              135,
              176
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 260,
              "pid": "6nok3f2vxo",
              "name": {
                "es": "Merluza con arroz verde"
              },
              "meal_type": 1,
              "image": "260.jpg",
              "image_pre": null,
              "time": 20,
              "description": {
                "es": "Para rellenar"
              },
              "podcast": "0",
              "fuente": "Karlos Arguiñano",
              "consejo": "Si quieres freír la zanahoria, para obtener un resultado más crujiente, rállala, déjala a remojo en agua durante 15 minutos, escúrrela bien y después fríala",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 2,
              "office_friendly": true,
              "steps": [
                {
                  "time": 4,
                  "title": {
                    "es": "Qué hay que calentar"
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Calentar medio litro de agua en una sartén"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": "Qué base hay que preparar"
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Pasa las espinacas limpias por agua, y pasa el todo en la batidora: agua de cocción y espinacas, cuela el liquido obtenido, con el que vas a cocinar el arroz"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": "Qué hay que preparar"
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "Pela las zanahorias y rallas, para un resultado más sano añádelas a tu plato en crudo, si quieres darle más sabor fríalas en una sartén en abundante aceite y encurdelas en papel de cocina para absorber el aceite sobrante"
                  }
                },
                {
                  "time": 16,
                  "title": {
                    "es": "Qué hay que cocinar"
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Aplasta con un golpe los ajos y dóralos en una cazuela, cuando cogen color añada el arroz, rehogado un minuto, sazona y cuécelo 18 minutos a fuego medio en el licuado de espinacas\nPescado: Mientras se hace el arroz pasa los filetes de merluza a la plancha: 3 minutos por lado, primero el lado con la piel, dale solo una vuelta"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": "Qué hay que emplatar"
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Sirve los filetes de merluza, con el crujiente de zanahoria por encima (si lo has freído) y el arroz verde al lado y ¡a comer!"
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      }
    ]
  },
  {
    "id": 300,
    "pid": "cx9wo81llb",
    "user_id": 17,
    "country_id": 724,
    "ingredients": [
      {
        "cost": "0.98",
        "name": {
          "es": "CALABACÍN"
        },
        "quantity": 390,
        "avg_weight": 300,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 107,
        "measure_unit_id": 1
      },
      {
        "cost": "12.90",
        "name": {
          "es": "PECHUGA DE POLLO"
        },
        "quantity": 300,
        "avg_weight": 400,
        "group_name": {
          "es": "Carne blanca"
        },
        "measure_unit": "g",
        "disponibility": 855,
        "measure_unit_id": 1
      },
      {
        "cost": "2.60",
        "name": {
          "es": "MACARONES"
        },
        "quantity": 300,
        "avg_weight": 0,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1145,
        "measure_unit_id": 1
      },
      {
        "cost": "1.49",
        "name": {
          "es": "ZANAHORIA"
        },
        "quantity": 300,
        "avg_weight": 100,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 367,
        "measure_unit_id": 1
      },
      {
        "cost": "2.49",
        "name": {
          "es": "PUERRO"
        },
        "quantity": 240,
        "avg_weight": 300,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1427,
        "measure_unit_id": 1
      },
      {
        "cost": "15.90",
        "name": {
          "es": "PECHUGA DE PAVO"
        },
        "quantity": 300,
        "avg_weight": 750,
        "group_name": {
          "es": "Carne blanca"
        },
        "measure_unit": "g",
        "disponibility": 853,
        "measure_unit_id": 1
      },
      {
        "cost": "2.55",
        "name": {
          "es": "hélices"
        },
        "quantity": 300,
        "avg_weight": 0,
        "group_name": {
          "es": "Carbohidratos"
        },
        "measure_unit": "g",
        "disponibility": 1442,
        "measure_unit_id": 1
      },
      {
        "cost": "4.00",
        "name": {
          "es": "SALSA DE TOMATE"
        },
        "quantity": 900,
        "avg_weight": 400,
        "group_name": {
          "es": "Verduras"
        },
        "measure_unit": "g",
        "disponibility": 1446,
        "measure_unit_id": 1
      }
    ],
    "extra_ingredients": null,
    "zip_code": "08911",
    "address": {
      "address_line_1": "av president companys 100, 5, 3",
      "address_line_2": null,
      "city": "badalona",
      "zip": "08911"
    },
    "cost": "28.84",
    "original_cost": null,
    "order_status_id": 2,
    "observations": null,
    "stripe_id": null,
    "delivery_date": 1668630549,
    "delivered_at": null,
    "created_at": 2020,
    "updated_at": 2020,
    "week_plans": [
      {
        "pid": "1n3evmv2ih",
        "available": 1,
        "date": "2022-11-16",
        "cost": "6.19",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 46,
        "recipe_week_plan": [
          {
            "id": 10829,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 7,
              "pid": "6h0itcrv0v",
              "name": {
                "es": "Macarrones con calabacín, pollo, salsa de soja y cilantro"
              },
              "meal_type": 1,
              "image": "7.jpg",
              "image_pre": null,
              "time": 18,
              "description": {
                "es": "Tiempo de preparación 2 minutos Tiempo de cocción 15 min"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 2,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Poner a hervir agua en una olla con sal y una tapa"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Cuando rompa a hervir el agua echar la pasta y cocinar al dente"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "1) Cortar el pollo en daditos 2) Cortar el calabacín a trocitos"
                  }
                },
                {
                  "time": 6,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Pasar el pollo a la plancha con un hilo de aceite y después en la misma olla, los trozos de calabacín"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Añadir 2 cucharadas de salsa de soja y cilantro, y ¡a comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que la pasta “al dente” tiene un indice glucémico más bajo? Por esta razón la asimilación de los hidratos de carbono contenidos en la pasta es más lenta, ofreciendo “energía” durante más tiempo a nuestro cuerpo"
                  }
                }
              ],
              "recipe_id": 185,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 10832,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 9,
              "pid": "l026egqewk",
              "name": {
                "es": "Hélices con trocitos de pavo, zanahorias y puerro, salsa de tomate y hierbas aromáticas"
              },
              "meal_type": 1,
              "image": "9.jpg",
              "image_pre": null,
              "time": 18,
              "description": {
                "es": "Tiempo de preparación 2 minutos Tiempo de cocción 15 min"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Pon agua a hervir, sin olvidar salarla"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Cuando hierva el agua, echaremos la pasta y la dejaremos cocer hasta que esté al dente"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "1) Limpiar y cortar a trocitos puerro y zanahoria 2) Cortar el pavo a trocitos"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "1) En una cazuela, pon el tomate triturado a hervir con sal y pimienta 2) Pasa a la plancha las verduras y después el pavo 3) En la cazuela con tomate se pueden añadir pavo y verduras para que acaben la cocción juntos"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Saca la pasta al dente, añade la salsa de tomate, las verduras y el pavo y ¡a comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que añadir aceite en el agua donde cocinas la pasta ayuda a que la pasta no se pegue así como si le cantaras una canción? Para que la pasta no se pegue remuévela de vez en cuando durante la cocción y sácala al diente."
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "lrcj4w2kal",
        "available": 1,
        "date": "2020-07-27",
        "cost": "6.19",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 31,
        "recipe_week_plan": [
          {
            "id": 10830,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 7,
              "pid": "6h0itcrv0v",
              "name": {
                "es": "Macarrones con calabacín, pollo, salsa de soja y cilantro"
              },
              "meal_type": 1,
              "image": "7.jpg",
              "image_pre": null,
              "time": 18,
              "description": {
                "es": "Tiempo de preparación 2 minutos Tiempo de cocción 15 min"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 2,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Poner a hervir agua en una olla con sal y una tapa"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Cuando rompa a hervir el agua echar la pasta y cocinar al dente"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "1) Cortar el pollo en daditos 2) Cortar el calabacín a trocitos"
                  }
                },
                {
                  "time": 6,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Pasar el pollo a la plancha con un hilo de aceite y después en la misma olla, los trozos de calabacín"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Añadir 2 cucharadas de salsa de soja y cilantro, y ¡a comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que la pasta “al dente” tiene un indice glucémico más bajo? Por esta razón la asimilación de los hidratos de carbono contenidos en la pasta es más lenta, ofreciendo “energía” durante más tiempo a nuestro cuerpo"
                  }
                }
              ],
              "recipe_id": 185,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 10833,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 9,
              "pid": "l026egqewk",
              "name": {
                "es": "Hélices con trocitos de pavo, zanahorias y puerro, salsa de tomate y hierbas aromáticas"
              },
              "meal_type": 1,
              "image": "9.jpg",
              "image_pre": null,
              "time": 18,
              "description": {
                "es": "Tiempo de preparación 2 minutos Tiempo de cocción 15 min"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Pon agua a hervir, sin olvidar salarla"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Cuando hierva el agua, echaremos la pasta y la dejaremos cocer hasta que esté al dente"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "1) Limpiar y cortar a trocitos puerro y zanahoria 2) Cortar el pavo a trocitos"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "1) En una cazuela, pon el tomate triturado a hervir con sal y pimienta 2) Pasa a la plancha las verduras y después el pavo 3) En la cazuela con tomate se pueden añadir pavo y verduras para que acaben la cocción juntos"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Saca la pasta al dente, añade la salsa de tomate, las verduras y el pavo y ¡a comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que añadir aceite en el agua donde cocinas la pasta ayuda a que la pasta no se pegue así como si le cantaras una canción? Para que la pasta no se pegue remuévela de vez en cuando durante la cocción y sácala al diente."
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      },
      {
        "pid": "r51ng93gt4",
        "available": 1,
        "date": "2020-07-27",
        "cost": "6.19",
        "created_at": 2020,
        "updated_at": 2020,
        "deleted_at": null,
        "week_number": 31,
        "recipe_week_plan": [
          {
            "id": 10831,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "2",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 7,
              "pid": "6h0itcrv0v",
              "name": {
                "es": "Macarrones con calabacín, pollo, salsa de soja y cilantro"
              },
              "meal_type": 1,
              "image": "7.jpg",
              "image_pre": null,
              "time": 18,
              "description": {
                "es": "Tiempo de preparación 2 minutos Tiempo de cocción 15 min"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 2,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Poner a hervir agua en una olla con sal y una tapa"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Cuando rompa a hervir el agua echar la pasta y cocinar al dente"
                  }
                },
                {
                  "time": 3,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "1) Cortar el pollo en daditos 2) Cortar el calabacín a trocitos"
                  }
                },
                {
                  "time": 6,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "Pasar el pollo a la plancha con un hilo de aceite y después en la misma olla, los trozos de calabacín"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Añadir 2 cucharadas de salsa de soja y cilantro, y ¡a comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que la pasta “al dente” tiene un indice glucémico más bajo? Por esta razón la asimilación de los hidratos de carbono contenidos en la pasta es más lenta, ofreciendo “energía” durante más tiempo a nuestro cuerpo"
                  }
                }
              ],
              "recipe_id": 185,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          },
          {
            "id": 10834,
            "alternatives_ids": [
              21,
              140,
              256
            ],
            "slot_id": 4,
            "week_day": "5",
            "created_at": null,
            "updated_at": null,
            "portions": 0,
            "recipe": {
              "id": 9,
              "pid": "l026egqewk",
              "name": {
                "es": "Hélices con trocitos de pavo, zanahorias y puerro, salsa de tomate y hierbas aromáticas"
              },
              "meal_type": 1,
              "image": "9.jpg",
              "image_pre": null,
              "time": 18,
              "description": {
                "es": "Tiempo de preparación 2 minutos Tiempo de cocción 15 min"
              },
              "podcast": "0",
              "fuente": "0",
              "consejo": "0",
              "expiration": 1,
              "filler": false,
              "people": 0,
              "min_people": 1,
              "office_friendly": false,
              "steps": [
                {
                  "time": 8,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 1,
                  "description": {
                    "es": "Pon agua a hervir, sin olvidar salarla"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 2,
                  "description": {
                    "es": "Cuando hierva el agua, echaremos la pasta y la dejaremos cocer hasta que esté al dente"
                  }
                },
                {
                  "time": 5,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 3,
                  "description": {
                    "es": "1) Limpiar y cortar a trocitos puerro y zanahoria 2) Cortar el pavo a trocitos"
                  }
                },
                {
                  "time": 10,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 4,
                  "description": {
                    "es": "1) En una cazuela, pon el tomate triturado a hervir con sal y pimienta 2) Pasa a la plancha las verduras y después el pavo 3) En la cazuela con tomate se pueden añadir pavo y verduras para que acaben la cocción juntos"
                  }
                },
                {
                  "time": 1,
                  "title": {
                    "es": null
                  },
                  "Type_ID": 5,
                  "description": {
                    "es": "Saca la pasta al dente, añade la salsa de tomate, las verduras y el pavo y ¡a comer!"
                  }
                },
                {
                  "title": {
                    "es": "Consejo"
                  },
                  "description": {
                    "es": "¿Sabías que añadir aceite en el agua donde cocinas la pasta ayuda a que la pasta no se pegue así como si le cantaras una canción? Para que la pasta no se pegue remuévela de vez en cuando durante la cocción y sácala al diente."
                  }
                }
              ],
              "recipe_id": null,
              "created_at": 2020,
              "updated_at": 2020,
              "deleted_at": null,
              "featured": false
            }
          }
        ]
      }
    ]
  }
];