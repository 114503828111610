<template>
  <ion-modal :is-open="modelValue" @didDismiss="$emit('update:modelValue', false)" :initial-breakpoint="1">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">
            <ion-icon :icon="chevronBack" style="color: black; font-size: 30px"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar class="ion-no-padding" :debounce="1000" :search-icon="`assets/home/search.svg`"
        placeholder="Buscar" @ionInput="applyFilters(true)" v-model="recipeSearch" ref="searchbar" />

      <div class="makeHorizontalScroll mealplans">
        <span class="usersMealplans" v-for="mealplan in mealplans" :key="`mealplan-${mealplan}`" :style="`${
            mealplan.active
              ? 'background-color: var(--ion-color-primary)'
              : 'background-color: #d3d3d3'
          }`" @click="
            () => {
              if (mealplan?.active) mealplan.active = false;
              else mealplan.active = true;
              applyFilters(true);
              getTags(this.tag);
            }
          ">
          {{
          mealplan.name?.charAt(0).toUpperCase() +
          mealplan.name?.charAt(1).toUpperCase()
          }}
        </span>
      </div>

      <div v-if="tag != 'featured'" class="makeHorizontalScroll">
        <ion-chip v-for="filter in tags" :key="`filter-${filter}`" :style="`${
            filter.active
              ? 'background-color: var(--ion-color-primary); color:white;'
              : 'background-color: var(--ion-color-tertiary-contrast); color:black;'
          }`" @click="
            () => {
              if (filter?.active) filter.active = false;
              else filter.active = true;
              applyFilters(true);
            }
          ">
          {{ filter.name?.charAt(0).toUpperCase() + filter.name.slice(1) }}
        </ion-chip>
      </div>

      <div>
        <ion-refresher slot="fixed" @ionRefresh="doRefresh">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>

        <template v-if="!meta?.not_found">
          <ion-list class="recipesList">
            <template v-for="recipe in searchData">
              <ion-card class="ion-no-margin" v-for="rcp in recipe" :key="`recipe-${rcp.id}`" @click="
                  () => {
                      $router.push({
                        name: 'recipe',
                        params: {
                          id: rcp.id,
                        },
                        query: {
                          order_id: order.id,
                          change,
                          slot: order?.slot_id || slotId,
                          week_day: order?.weekday || weekday
                        },
                      });

                    $emit('update:modelValue', false);
                  }
                ">
                <ion-img :src="`https://api.timblo.es/images/recipes/${rcp?.image}`" />
              </ion-card>
            </template>
          </ion-list>
        </template>

        <template v-else>
          <div style="text-align: center">
            <ion-img style="margin: 10em 79px 0" src="assets/images/deny.svg"></ion-img>
            <h2>{{ $t('no-se-han-encontrado-recetas') }}</h2>
            <h4>{{ $t(`finder_not_found_${meta?.code}`) }}</h4>
          </div>
        </template>

        <ion-infinite-scroll threshold="10px" id="infinite-scroll" @ionInfinite="onLoad($event)">
          <ion-infinite-scroll-content loading-spinner="bubbles">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonList,
  IonSearchbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';

import { chevronBack } from 'ionicons/icons';

import { ref, defineComponent, nextTick } from 'vue';
import { useTimbloStore } from '@/stores/timblo';
import { CancelToken } from 'axios';

export default defineComponent({
  name: 'TFinder',

  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    tag: {
      type: String,
    },

    type: {
      type: Number,
      default: null,
    },

    order: {
      type: Object,
      required: false,
    },

    mealplanActive: {
      type: Object,
      required: false,
    },

    change: {
      type: Number,
      default: null,
    },
    slotId: {
      type: Number,
      default: null,
    },
    weekday: {
      type: Number,
      default: null,
    },
  },

  components: {
    IonModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonList,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  watch: {
    modelValue: {
      async handler(newValue) {
        if (newValue) {
          this.rand = this.$moment().unix();
          // this.getMealPlans();

          // CARGANDO MEALPLANS
          if (!this.mealplans.length) {
            await this.getMealPlans();
          }

          this.getTags(this.tag).then(() => {
            this.applyFilters(true);
          });
          nextTick(() => {
            setTimeout(() => {
              this.$refs.searchbar.$el.setFocus();
            }, 1000);
          });
        } else {
          this.recipeSearch = null;
          this.tags = [];
          this.mealplans = [];
          this.searchData = [];
        }
      },
      deep: true,
    },
  },

  setup() {
    const timblo = useTimbloStore();
    return {
      // STORE
      timblo,

      cancelTokenSource: null,
      pagination: ref({
        rowsPerPage: ref(30),
      }),
      tags: ref([]),
      searchData: ref([]),
      mealplans: ref([]),
      recipeSearch: ref(null),

      // PAGINATION
      current_page: ref(1),
      last_page: ref(null),
      loading: ref(false),
      total: ref(null),
      meta: ref(null),

      rand: ref(null),

      // ICONS
      chevronBack,
    };
  },

  methods: {
    applyFilters(reset) {
      if (reset) {
        this.searchData = [];
        this.current_page = 1;
      }
      return this.getRecipes(this.current_page);
    },

    onLoad(event) {
      if (
        !this.loading &&
        this.current_page < this.last_page &&
        this?.tag != 'newRecipes'
      ) {
        this.current_page += 1;
        this.applyFilters().then(() => {
          event.target.complete();
        });
      } else {
        event.target.complete();
      }
    },

    async getRecipes() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Llamada cancelada debido a una nueva solicitud.');
      }
      this.cancelTokenSource = CancelToken.source();
      // const allergens =
      //   this.mealplans
      //     .filter((f) => {
      //       if (f.active && f.allergens.length) return f;
      //     })
      //     .map((v) => v?.allergens)
      //     .flat()
      //     .map((v) => v.id) || [];

      // const dislikes =
      //   this.mealplans
      //     .filter((f) => {
      //       if (f.active && f.dislikes.length) return f;
      //     })
      //     .map((v) => v?.dislikes)
      //     .flat()
      //     .map((v) => v.id) || [];

      const mealplans = this.mealplans.filter((mp) => mp?.active).map((mp) => mp?.id);

      const tags =
        this.tags
          ?.filter((f) => {
            if (!this.timblo.user?.oncological || f.active) return f;
          })
          .map((a) => a?.id) || [];

      let newRecipes = null;
      let less20M = null;
      let featured = null;
      if (this.tag == 'newRecipes') newRecipes = 1;
      if (this.tag == 'less20M') less20M = 1;
      if (this.tag == 'featured') featured = 1;

      return await this.$axios
        .get('/recipes/search', {
          params: {
            ...this.pagination,
            rowsPerPage: 32,
            search: this.recipeSearch,
            page: this.current_page,
            rand: this.rand,
            type: this.type,
            newRecipes,
            less20M,
            mealplans,
            featured,
            tags,
          },
          cancelToken: this.cancelTokenSource.token, // Pasa el token de cancelación en la configuración
        })
        .then(({ data }) => {
          // if (this?.tag == 'newRecipes') {
          //   this.searchData.push(data);
          // } else {
          //   this.searchData.push(data.data);

          //   this.current_page = data.current_page;
          //   this.last_page = data.last_page;
          //   this.total = data.total;
          // }

          this.searchData.push(data.data.data);

          this.current_page = data.data.current_page;
          this.last_page = data.data.last_page;
          this.total = data.data.total;
          this.meta = data?.meta;
        })
        .finally(() => {
          this.cancelTokenSource = null; // Limpia el token de cancelación
          this.loading = false;
        });
    },

    async getMealPlans() {
      let mealplanActive = [];
      return this.$axios.get(`/mealplans/${this.timblo.user.id}`).then(({ data }) => {
        if (this.mealplanActive?.length) {
          mealplanActive = data.map((m) => {
            if (this.mealplanActive?.includes(m.id)) m.active = true;
            return m;
          });
        } else {
          data.map((d) => {
            if (this.$route.query.mealplans?.length) {
              if (this.$route.query.mealplans?.includes(d.id + '')) d.active = true;
            } else {
              if (d.is_admin) d.active = true;
            }
            return d;
          });
          mealplanActive = data.map((m) => {
            // Marcar por defecto los usuarios sin alergenos
            if (m?.allergens.length == 0) m.active = true;
            return m;
          });
        }
        this.mealplans = mealplanActive;
      });
    },

    getTags(tagName = null) {
      let featured = null;
      if (tagName == 'featured') featured = this.timblo.user?.id;
      return this.$axios
        .get('/tags', {
          params: {
            featured,
          },
        })
        .then(({ data }) => {
          const datos = data;
          if (this.tag?.length) {
            datos.map((t) => {
              if (t.name == this.tag) t.active = true;
              return t;
            });
            if (this.tag == 'featured') {
              datos.map((t) => {
                t.active = true;
                return t;
              });
            }
          }
          datos.sort((a, b) => {
            return b.active ? 1 : -1;
          });
          this.tags = datos;
        });
    },

    doRefresh(event) {
      this.rand = this.$moment().unix();
      this.applyFilters(true).then(() => {
        event.target.complete();
      });
    },
  },
});
</script>

<style lang="scss" scoped>
ion-toolbar {
  --background: var(--ion-color-tertiary-shade);
  --border-color: transparent;
}
ion-modal > ion-content::part(background) {
  --background: var(--ion-color-tertiary-shade);
}

ion-modal > ion-content > ion-list {
  background-color: transparent;

  & > ion-card {
    box-shadow: none;
  }
}

.makeHorizontalScroll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  & > ion-chip {
    min-width: fit-content;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
}
.mealplans {
  margin-top: 10px;
  margin-bottom: 10px;
  & > .usersMealplans {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    min-width: 35px;
    min-height: 35px;
    max-width: 35px;
    max-height: 35px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    overflow: hidden;
    // background-color: var(--ion-color-tertiary-contrast);
  }
}

.recipesList {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);

  & > ion-card > ion-img {
    height: 140px;
    object-fit: cover;
  }
}
</style>
