<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div>
        <ion-img
          style="margin: 33px 108px 0px 129px"
          src="assets/logo/logo.svg"
        ></ion-img>
      </div>

      <div>
        <ion-img
          style="margin: 24px 62px 18px 65px"
          src="assets/images/workout.svg"
        ></ion-img>
      </div>

      <div class="subtitulo">
        <h4>{{ $t('nueva-contrasena') }}</h4>
      </div>

      <div style="margin: 10px 24px 0">
        <ion-input
          v-model="newCredentials.password"
          :placeholder="$t('contrasena')"
          name="password"
          type="password"
          class="t-input"
        />
      </div>
      <div style="margin: 10px 24px 0">
        <ion-input
          v-model="newCredentials.password_confirmation"
          :placeholder="$t('repite-contrasena')"
          name="password_confirmation"
          type="password"
          class="t-input"
        />
      </div>

      <t-button
        :disabled="!newCredentials.password || !newCredentials.password_confirmation"
        style="margin-top: 16px"
        @click="changePassword()"
        >{{ $t('enviar') }}</t-button
      >
      <div style="text-align: center">
        <p
          @click="$router.push({ name: 'login' })"
          style="color: var(--ion-color-primary)"
        >
          {{ $t('volver-al-inicio') }}
        </p>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';

import { IonPage, IonInput, IonContent, toastController } from '@ionic/vue';

export default defineComponent({
  name: 'RecoverPage',

  components: {
    IonPage,
    IonContent,
    TButton,
    IonInput,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      timblo,
      toastController,

      token: ref(null),
      newCredentials: ref({
        password: null,
        password_confirmation: null,
      }),
    };
  },

  async created() {
    this.token = this.$route.query.token;

    const check = await this.checkToken();
    if (!check) {
      this.$router.push({ name: 'login' });
    }
  },

  methods: {
    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
    changePassword() {
      if (this.newCredentials.password === this.newCredentials.password_confirmation) {
        this.$axios
          .post('/new-password', { ...this.newCredentials, token: this.token })
          .then(() => {
            this.$router.push({ name: 'login' });
          });
      } else {
        this.openToastOptions(
          this.$t('Error'),
          'Debe escribir la misma contraseña en ambos campos'
        );
      }
    },
    checkToken() {
      return new Promise((resolve) => {
        this.$axios
          .get('/check-token', {
            params: {
              token: this.token,
            },
          })
          .then(({ data }) => resolve(data));
      });
    },
  },
});
</script>

<style lang="scss" scoped>
h1 {
  color: var(--ion-color-primary);
}

input {
  width: 90%;
  padding-left: 16px;
  height: 48px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
}

input::placeholder {
  font-family: 'Nunito Sans';
  color: var(--ion-color-primary-shade);
}

.subtitulo {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--ion-color-primary);
  padding-left: 45px;
  padding-right: 35px;
}
.subtitulo2 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  text-align: left;
  color: black;
  padding-left: 45px;
  padding-right: 35px;
}
</style>
