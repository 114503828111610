<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="card-toolbar">
        <ion-buttons slot="start">
          <ion-button @click="$router.go(-1)">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>{{ $t('mis-utensilios') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding" fullscreen>
      <!-- <div style="margin: 120px 23px 0">
        <h1>Mis utensilios</h1>
      </div> -->

      <div style="margin-bottom: 32px">
        <ion-img src="assets/images/tools.svg" style="height: 150px"></ion-img>

        <h5>
          {{ $t('que-tienes-para-cocinar') }}
        </h5>
      </div>

      <!-- <ion-list>
        <ion-item :key="`tools-${index}`" v-for="(tool, index) in tools">
          <ion-label> {{ tool.name[timblo.lang]}} </ion-label>
          <ion-toggle
            @ionChange="updateTool()"
            v-model="tool.users_count"
            slot="end"
          ></ion-toggle>
        </ion-item>
      </ion-list> -->

      <div>
        <ion-item
          v-for="(tool, index) in tools"
          :key="`tool-${index}`"
          lines="none"
          class="item-border"
        >
          <ion-icon color="primary" slot="start" :class="['icon', tool.icon]"></ion-icon>
          <ion-label>{{ tool.name[timblo.lang] }}</ion-label>
          <ion-checkbox slot="end" v-model="tool.users_count" @ionChange="updateTool()" />
        </ion-item>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonContent, IonPage, IonItem, IonCheckbox } from '@ionic/vue';
import { chevronBack } from 'ionicons/icons';
import { useTimbloStore } from '@/stores/timblo';

export default defineComponent({
  name: 'ToolsPage',

  components: {
    IonPage,
    IonContent,
    IonItem,
    IonCheckbox,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      chevronBack,
      test: ref({}),
      tools: ref({}),
      timblo,
    };
  },

  ionViewWillEnter() {
    this.getTools();
  },

  methods: {
    getTools() {
      console.log(this.timblo.user.id);
      this.$axios
        .get('tools', {
          params: {
            pagination: 1,
          },
        })
        .then(({ data }) => {
          this.tools = data.data;
        });
    },

    updateTool() {
      this.$axios.put('/tools/', {
        user: this.timblo.user,
        tools: this.tools.filter((t) => t.users_count).map((t) => t.id),
      });
    },
  },
});
</script>

<style scoped>
h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: var(--ion-color-primary);
}

h3 {
  color: var(--ion-color-primary);
}

h5 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-left: 26px;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

hr {
  border: 0.5px solid #d6d6d6 !important;
}

.itemAccount {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-top: 20px;
  align-items: center;
}
</style>
