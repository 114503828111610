<template>
  <ion-page>
    <ion-content fullscreen>
      <div class="custom_toolbar set_padding">
        <ion-button shape="round" @click="$router.go(-1)">
          <ion-icon :icon="chevronBackOutline" style="font-size: 26px" />
        </ion-button>
        <div style="width: 100%; text-align: center">
          <h3>{{ $t('codigo-descuento') }}</h3>
        </div>
      </div>

      <div class="aboutMe_content set_padding">
        <div class="aboutMe_content_image">
          <img src="/assets/images/discountCodeIMG.svg" alt="DiscountImage" />
        </div>

        <div class="aboutMe_content_desc">
          <h5>
            {{ $t('nos-encanta-ayudarte-a-ahorrar-dinero-si-tienes-un-codigo-de-descuento') }}
            <br />
            {{ $t('escribelo-aqui') }}
          </h5>
        </div>
      </div>

      <div class="aboutMe_MP set_padding">
        <label><h5>{{ $t('tu-codigo-de-descuento') }}</h5></label>
        <ion-input
          v-model="code"
          class="t-input"
          placeholder="Ex. BIENVENIDO30"
          type="text"
        />
        <label v-if="newCode == false"
          ><a style="color: var(--ion-color-danger)"
            >{{ $t('el-codigo-no-esta-disponible') }}</a
          ></label
        >
        <label v-if="newCode == true"
          ><a style="color: var(--ion-color-secondary)"
            >{{ $t('codigo-canjeado-correctamente') }}</a
          ></label
        >

        <h5 v-if="active">{{ $t('cupones-en-uso') }}</h5>
        <div class="active_coupon" v-if="active">
          <ion-chip slot="end" style="background: #a8cfb7">
            <div>{{ active?.coupon?.code }}</div>
          </ion-chip>

          <p>
            <span>{{ $t('valido-hasta') }}</span> <br />
            {{
              $moment(active?.created_at)
                .add(active?.coupon?.days_to_expire, 'days')
                .format('LL')
            }}
          </p>
        </div>

        <t-button style="font-size: 20px; margin-top: 32px" @click="setCode(code)"
          >{{ $t('utilizar-codigo') }}</t-button
        >
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';

import {
  IonContent,
  IonPage,
  IonButton,
  IonInput,
  toastController,
  IonChip,
} from '@ionic/vue';

import { chevronBackOutline, informationCircle } from 'ionicons/icons';

import { useTimbloStore } from '@/stores/timblo';

import TButton from '@/components/TButton.vue';

export default defineComponent({
  name: 'DiscountCodePage',

  components: {
    IonPage,
    IonContent,
    IonButton,
    IonInput,
    IonChip,

    TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      // ICONS
      chevronBackOutline,
      informationCircle,

      // STORES
      timblo,

      code: ref(null),
      newCode: ref(),
      active: ref(null),
    };
  },

  created() {
    this.getActiveCoupon();
  },

  methods: {
    getActiveCoupon() {
      this.$axios.get('/discount/active').then(({ data }) => {
        this.active = data;
      });
    },

    async setCode(code) {
      this.$axios
        .post(`/discount/order`, {
          code,
        })
        .then(({ data }) => {
          this.active = data;
          this.openToastOptions(
            'Cupón canjeado',
            '¡Enhorabuena! El cupón se ha canjeado con éxito',
            'success'
          );
        })
        .catch(({ response }) => {
          this.openToastOptions('Error al usar el cupón', response?.data?.message);
        });
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
  },
});
</script>

<style lang="scss" scoped>
.active_coupon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    text-align: center;
    font-weight: 600;
    span {
      font-weight: 300;
    }
  }
}
ion-button {
  --background: transparent;

  &::part(native) {
    padding: 0;
    box-shadow: none;
  }
}
ion-content {
  // --background: #F5F5F5;
  --background: #fff;
}
.set_padding {
  padding-left: 24px;
  padding-right: 24px;
}

.custom_toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  h3 {
    margin-top: initial !important;
    margin-bottom: initial !important;
  }
}

.aboutMe {
  &_content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;

    &_image {
      width: 100%;
      margin: auto;
      text-align: center;
    }
    &_desc {
      margin-top: 30px;
      text-align: center;

      h5 {
        font-size: 24px !important;
      }
      p {
        font-size: 14px;
      }
    }
  }

  &_MP {
    ion-accordion {
      background-color: white;
      border-radius: 10px;
      margin-bottom: 26px;
    }

    ion-accordion::part(header) {
      height: 50px !important;
    }

    ion-accordion > ion-item::part(native) {
      margin-bottom: 30px;
      background-color: #fff;
      font-size: 18px;
      font-weight: 500;
      border-color: transparent;
    }
  }
}

ion-label {
  font-family: 'Nunito Sans' !important;
  font-weight: 600 !important;
}
.about_energy ion-item {
  padding-top: 12px;
  padding-bottom: 12px;

  .content {
    margin-left: 15px;
  }
  p {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }
  p:last-child {
    color: rgb(86, 86, 86);
  }
}
</style>
