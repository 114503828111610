<template>
  <ion-modal
    :is-open="modelValue"
    @didDismiss="$emit('update:modelValue', false)"
    :initial-breakpoint="1"
  >
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$emit('update:modelValue', false)">
            <ion-icon
              :icon="chevronBack"
              style="color: black; font-size: 30px"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ion-searchbar
        class="ion-no-padding"
        :debounce="1000"
        :search-icon="`assets/home/search.svg`"
        :placeholder="$t('buscar-productos')"
        @ionInput="applyFilters(true)"
        v-model="productSearch"
      />

      <div class="productFilter">
        <ion-item lines="none" class="ion-no-margin" @click="openMarketsFilter">
          <ion-label>{{ $t('Filtrar por tienda') }}</ion-label>
          <ion-input :value="`${selectedMarket?.name || ''}`" />
          <ion-icon
            :icon="chevronForward"
            style="color: black; font-size: 25px"
          ></ion-icon>
        </ion-item>

        <ion-item
          v-if="nextOrders?.length > 1"
          lines="none"
          class="ion-no-margin"
          @click="openOrdersFilter"
        >
          <ion-label>
            {{
              selectedOrder.delivery_date
                ? `${$t('Entrega el')}: ${this.$moment
                    .unix(selectedOrder.delivery_date)
                    .format('ll')}`
                : $t('Elige tu pedido')
            }}
          </ion-label>
          <ion-icon
            :icon="chevronForward"
            style="color: black; font-size: 25px"
          ></ion-icon>
        </ion-item>
      </div>

      <ion-list class="recipesList">
        <template v-for="product in searchData">
          <ion-item
            lines="inset"
            class="ion-no-margin"
            v-for="prd in product"
            :key="`product-${prd.id}`"
            @click="selectProduct(prd)"
          >
            <div
              :class="`icon ${prd?.icon}`"
              style="
                color: var(--ion-color-secondary);
                margin-right: 10px;
                font-size: 24px;
              "
            ></div>
            <ion-label>
              {{
                (prd?.name[timblo.lang] || prd?.name['es']).charAt(0).toUpperCase() +
                (prd?.name[timblo.lang] || prd?.name['es']).slice(1)
              }}
              <span v-if="prd.market_ingredients[0]?.cost">
                {{
                  `${` (${(
                    (+prd.market_ingredients[0]?.cost * (1 + timblo_fee) +
                      +prd.market_ingredients[0]?.cost * timblo_fee * 0.21) *
                    (prd.measure_unit_id == 3
                      ? 1
                      : (prd.market_ingredients[0]?.avg_weight || 1000) / 1000)
                  ).toFixed(2)}€/${
                    (prd.market_ingredients[0]?.avg_weight || 1000) &&
                    ['kg', 'g', 'ml'].includes(prd.measure_unit.abbreviation)
                      ? 'ud'
                      : prd.measure_unit.abbreviation
                  })`}`
                }}
              </span>
            </ion-label>
            <ion-icon
              :icon="chevronForward"
              style="color: black; font-size: 24px"
            ></ion-icon>
          </ion-item>
        </template>
      </ion-list>

      <ion-infinite-scroll
        threshold="10px"
        id="infinite-scroll"
        @ionInfinite="onLoad($event)"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <!-- Product Modal -->
  <ion-modal
    :is-open="setProduct"
    @didDismiss="setProduct = false"
    :initial-breakpoint="1"
  >
    <ion-content>
      <div class="productCard">
        <div class="card-header">
          <h4>{{ selectedMarket?.name || 'Todas las tiendas' }}</h4>
        </div>
        <div class="card-image" v-if="selectedProduct.image">
          <img
            :src="`https://api.timblo.es/images/ingredients/${
              selectedProduct.market_ingredients[0].image || selectedProduct?.image
            }`"
            alt="Image"
          />
        </div>
        <div class="card-image no-image" v-else>
          {{ $t('No hay foto') }}
        </div>
        <div class="card-content">
          <div class="card-content-market">
            <h5 class="card-content-market-header">
              {{
                $t(`${selectedProduct.name[timblo.lang] || selectedProduct.name['es']}`)
              }}
              <span>
                {{
                  ` (${(
                    (+selectedProduct.market_ingredients[0]?.cost * (1 + timblo_fee) +
                      +selectedProduct.market_ingredients[0]?.cost * timblo_fee * 0.21) *
                    (+selectedProduct.measure_unit_id == 3
                      ? 1
                      : (+selectedProduct.market_ingredients[0]?.avg_weight || 1000) /
                        1000)
                  ).toFixed(2)}€/${
                    (+selectedProduct.market_ingredients[0]?.avg_weight || 1000) &&
                    ['kg', 'g', 'ml'].includes(selectedProduct.measure_unit.abbreviation)
                      ? 'ud'
                      : selectedProduct.measure_unit.abbreviation
                  })`
                }}
              </span>
            </h5>
            <div class="card-content-market-content">
              <p>
                {{ `${$t('Alergenos')}:` }}
                <span v-if="selectedProduct.allergens?.length">
                  {{
                    selectedProduct.allergens
                      .map((a) => a.name[timblo.lang] || a.name['es'])
                      .toString() || ''
                  }}
                </span>
                <span v-else>
                  {{ $t('No tiene alergenos') }}
                </span>
              </p>

              <p v-if="selectedProduct.market_ingredients[0]?.avg_weight">
                {{ `${$t('Peso medio estimado')}:` }}
                <span>
                  {{
                    `${selectedProduct.market_ingredients[0]?.avg_weight}${selectedProduct.measure_unit.abbreviation}`
                  }}
                </span>
              </p>
              <p v-if="selectedProduct.description">
                <span>
                  <div
                    v-html="
                      selectedProduct.description[timblo.lang] ||
                      selectedProduct.description['es']
                    "
                  ></div>
                </span>
              </p>
              <!-- <p v-if="selectedProduct.observation">Consejos:
                  <span>
                    {{selectedProduct.observation.observations}}
                  </span>
                </p> -->
            </div>
          </div>
        </div>

        <div class="countOrder">
          <ion-buttons>
            <ion-button @click="quantityRestar()">
              <img :src="`assets/icons/diff.svg`" />
            </ion-button>
          </ion-buttons>
          <span class="value">
            {{ quantity }}
          </span>
          <ion-buttons>
            <ion-button @click="quantityAñadir()">
              <img :src="`assets/icons/sum.svg`" />
            </ion-button>
          </ion-buttons>
        </div>

        <ion-item lines="none">
          <ion-textarea
            rows="3"
            v-model="selectedProduct.observationsOrder"
            placeholder="Observaciones"
          ></ion-textarea>
        </ion-item>
      </div>
      <div
        class="card-actions fixed-footer"
        style="padding-left: 24px; padding-right: 24px; margin-bottom: 6em"
      >
        <t-button @click="setProduct = false">{{ $t('Cancelar') }}</t-button>
        <t-button
          style="background-color: var(--ion-color-secondary); color: black"
          @click="addProductToOrder(selectedProduct)"
        >
          {{ $t('Añadir') }}
        </t-button>
      </div>
    </ion-content>
  </ion-modal>

  <!-- Markets List Modal -->
  <ion-modal
    :is-open="marketsFilter"
    @didDismiss="marketsFilter = false"
    :initialBreakpoint="1"
    :breakpoints="[0, 0.5, 1]"
  >
    <ion-content class="ion-padding">
      <ion-searchbar
        class="ion-no-padding"
        :debounce="1000"
        :search-icon="`assets/home/search.svg`"
        placeholder="Buscar tiendas"
        @ionInput="applyFiltersMarkets(true)"
        v-model="marketSearch"
      />

      <ion-list class="recipesList">
        <ion-item lines="inset" class="ion-no-margin">
          <ion-label
            @click="
              () => {
                selectedMarket = 0;
                marketsFilter = false;
                applyFilters(true);
              }
            "
          >
            Todos los mercados
          </ion-label>
        </ion-item>
        <template v-for="marketL in marketList">
          <ion-item
            lines="inset"
            class="ion-no-margin"
            v-for="market in marketL"
            :key="`market-${market.id}`"
          >
            <ion-label
              @click="
                () => {
                  selectedMarket = market;
                  marketsFilter = false;
                  applyFilters(true);
                }
              "
            >
              {{ market?.name }}
            </ion-label>
            <ion-buttons>
              <ion-button
                @click="
                  () => {
                    selectedMarket = market;
                    marketsFilter = false;
                    applyFilters(true);
                  }
                "
              >
                <ion-icon
                  :icon="eyeOutline"
                  style="color: black; font-size: 24px"
                ></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </template>
      </ion-list>

      <ion-infinite-scroll
        threshold="10px"
        id="infinite-scroll"
        @ionInfinite="onLoadMarkets($event)"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-modal>

  <!-- Next Orders Modal -->
  <ion-modal
    :is-open="nextOrderFilter"
    @didDismiss="nextOrderFilter = false"
    :initialBreakpoint="1"
    :breakpoints="[0, 0.5, 1]"
  >
    <ion-content class="ion-padding">
      <ion-list class="recipesList">
        <ion-item
          lines="inset"
          class="ion-no-margin"
          v-for="order in nextOrders"
          :key="`order-${order.id}`"
        >
          <ion-label
            @click="
              () => {
                selectedOrder = order;
                nextOrderFilter = false;
                applyFilters(true);
              }
            "
          >
            {{ this.$moment.unix(order.delivery_date).format('ll') }}
          </ion-label>
          <ion-buttons>
            <ion-button
              @click="
                () => {
                  nextOrderFilter = false;
                  $router.push({ name: 'order', params: { id: order.id } });
                }
              "
            >
              <ion-icon
                :icon="eyeOutline"
                style="color: black; font-size: 24px"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonList,
  IonItem,
  IonSearchbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonTextarea,
  alertController,
  toastController,
} from '@ionic/vue';

import {
  chevronBack,
  chevronForward,
  eyeOutline,
  informationCircle,
} from 'ionicons/icons';

import { ref, defineComponent } from 'vue';
import { useTimbloStore } from '@/stores/timblo';
import TButton from '@/components/TButton.vue';

export default defineComponent({
  name: 'TProductFinder',

  props: {
    modelValue: {
      required: true,
      type: Boolean,
    },

    tag: {
      type: String,
    },

    market: {
      type: Object,
    },

    timblo_fee: {
      type: Number,
    },
  },

  components: {
    IonModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonList,
    IonItem,
    IonInput,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    TButton,
    IonTextarea,
  },

  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue) {
          this.selectedMarket = this.market || {};
          this.applyFilters(true);
          this.getOrders();
        } else {
          this.productSearch = null;
          this.searchData = [];
          this.selectedMarket = {};
          this.selectedOrder = {};

          this.$emit('dismiss');
        }
      },
      deep: true,
    },
    prdQuant: {
      handler(val) {
        const maxQuant =
          this.selectedProduct.measure_unit.abbreviation == 'ud' ? 20 : 3000;
        const minQuant =
          this.selectedProduct.measure_unit.abbreviation == 'ud'
            ? 1
            : this.minQuantity || this.avgQuantity / this.avgQuantity || 1;
        // console.log('Watch: ', maxQuant, minQuant, val);
        if (minQuant > (this.avgQuantity ? +val * this.avgQuantity : +val)) {
          this.openToastOptions(
            this.$t('Aviso!'),
            this.$t('No puede pedir menos de {quantity}', {
              quantity:
                (this.avgQuantity || this.minQuantity
                  ? minQuant * (this.avgQuantity || this.minQuantity)
                  : minQuant) + this.selectedProduct.measure_unit.abbreviation,
            })
          );
          this.prdQuant = this.avgQuantity ? minQuant / this.avgQuantity : minQuant;
        } else if (+maxQuant < (this.avgQuantity ? +val * this.avgQuantity : +val)) {
          this.prdQuant = this.avgQuantity ? maxQuant / this.avgQuantity : maxQuant;
          this.openToastOptions(
            this.$t('Aviso!'),
            this.$t('No puede pedir mas de {quantity}', {
              quantity: maxQuant + this.selectedProduct.measure_unit.abbreviation,
            })
          );
        }
      },

      deep: true,
    },
  },

  setup() {
    const timblo = useTimbloStore();
    return {
      // STORE
      timblo,

      // VARS
      pagination: ref({
        rowsPerPage: ref(30),
      }),
      searchData: ref([]),
      selectedProduct: ref({}),
      productSearch: ref(null),
      setProduct: ref(false),
      prdQuant: ref(1),
      moreThanLimit: ref(false),
      minQuantity: ref(null),
      avgQuantity: ref(null),
      quantity: ref(1),

      marketsFilter: ref(false),
      marketSearch: ref(null),
      marketList: ref([]),
      marketsData: ref([]),
      selectedMarket: ref({}),

      nextOrderFilter: ref(false),
      nextOrders: ref([]),
      selectedOrder: ref({}),

      // PAGINATION
      loading: ref(false),
      current_page: ref(1),
      last_page: ref(null),
      total: ref(null),

      current_page_markets: ref(1),
      last_page_markets: ref(null),
      total_markets: ref(null),

      // ICONS
      chevronBack,
      chevronForward,
      eyeOutline,
      informationCircle,
    };
  },

  methods: {
    async sumQuant() {
      const actualQuant = this.prdQuant;
      const adder =
        this.selectedProduct.measure_unit.abbreviation == 'ud'
          ? 1
          : this.avgQuantity / this.avgQuantity || this.minQuantity || 1;

      const maxQuant = this.selectedProduct.measure_unit.abbreviation == 'ud' ? 20 : 3000;

      if (actualQuant + adder > maxQuant / 2 && !this.moreThanLimit) {
        await this.presentAlertProduct(
          this.$t('¿Estás seguro que quieres pedir más de {quantity} de', {
            quantity:
              (this.avgQuantity ? maxQuant * this.avgQuantity : maxQuant) / 2 +
              this.selectedProduct.measure_unit.abbreviation,
          }) +
            ` ${
              this.selectedProduct.name[this.timblo.lang] ||
              this.selectedProduct.name['es']
            }?`
        );
      } else if (actualQuant + adder > maxQuant) {
        this.openToastOptions(
          this.$t('Aviso!'),
          this.$t('No puede pedir mas de {quantity}', {
            quantity:
              (this.avgQuantity ? maxQuant * this.avgQuantity : maxQuant) +
              this.selectedProduct.measure_unit.abbreviation,
          })
        );
      } else {
        this.prdQuant += adder;
      }
    },

    async subQuant() {
      const actualQuant = this.prdQuant;
      const adder =
        this.selectedProduct.measure_unit.abbreviation == 'ud'
          ? 1
          : this.avgQuantity / this.avgQuantity || this.minQuantity || 1;
      const minQuant =
        this.selectedProduct.measure_unit.abbreviation == 'ud'
          ? 1
          : this.minQuantity || this.avgQuantity / this.avgQuantity || 1;
      console.log(
        'actual: ' +
          actualQuant +
          '| min: ' +
          minQuant +
          '| adder:' +
          adder +
          '| avg: ' +
          this.avgQuantity
      );
      if ((actualQuant - adder) * this.avgQuantity < minQuant) {
        this.openToastOptions(
          this.$t('Aviso!'),
          this.$t('No puede pedir menos de {quantity}', {
            quantity:
              (minQuant ? minQuant : this.avgQuantity) +
              this.selectedProduct.measure_unit.abbreviation,
          })
        );
      } else {
        this.prdQuant -= adder;
      }
    },

    // changeQuant(ev) {
    //   const quant = +ev.target.value;
    //   const maxQuant = this.selectedProduct.measure_unit.abbreviation == 'ud' ? 20 : 3000;
    //   const minQuant = this.selectedProduct.measure_unit.abbreviation == 'ud'
    //     ? 1
    //     : this.minQuantity || this.avgQuantity || 1;

    //   if (minQuant > quant) {
    //     this.openToastOptions(
    //       this.$t('Aviso!'),
    //       this.$t('No puede pedir menos de {quantity}', {
    //         quantity: minQuant + this.selectedProduct.measure_unit.abbreviation,
    //       })
    //     );
    //     this.prdQuant = minQuant;
    //   } else if (maxQuant < +ev.target.value) {
    //     this.openToastOptions(
    //       this.$t('Aviso!'),
    //       this.$t('No puede pedir mas de {quantity}', {
    //         quantity: maxQuant + this.selectedProduct.measure_unit.abbreviation,
    //       })
    //     );
    //     this.prdQuant = maxQuant;
    //   }
    //   console.log('Quant:', this.prdQuant);
    // },

    openMarketsFilter() {
      this.applyFiltersMarkets(true);
      this.marketsFilter = true;
    },

    openOrdersFilter() {
      // this.getOrders(true)
      this.nextOrderFilter = true;
    },

    applyFilters(reset) {
      if (reset) {
        this.searchData = [];
        this.current_page = 1;
      }
      return this.getIngredients(this.current_page);
    },

    onLoad(event) {
      if (!this.loading && this.current_page < this.last_page) {
        this.current_page += 1;
        this.applyFilters().then(() => {
          event.target.complete();
        });
      } else {
        event.target.complete();
      }
    },

    applyFiltersMarkets(reset) {
      if (reset) {
        this.marketList = [];
        this.current_page_markets = 1;
      }
      return this.getMarkets(this.current_page_markets);
    },

    onLoadMarkets(event) {
      if (!this.loading_markets && this.current_page_markets < this.last_page_markets) {
        this.current_page_markets += 1;
        this.applyFiltersMarkets().then(() => {
          event.target.complete();
        });
      } else {
        event.target.complete();
      }
    },

    async getIngredients() {
      let productType = null;
      if (this.tag?.length) {
        if (+this.tag == 0) {
          productType = null;
        } else productType = this.tag.split(',');
      }
      return await this.$axios
        .get('/ingredients', {
          params: {
            ...this.pagination,
            filter: this.productSearch,
            page: this.current_page,
            type: productType,
            // disponibility: this.timblo.user.zip_code,
            marketID: this.selectedMarket.id,
          },
        })
        .then(({ data }) => {
          this.searchData.push(data.data);

          this.current_page = data.current_page;
          this.last_page = data.last_page;
          this.total = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getMarkets() {
      return this.$axios
        .get('markets', {
          params: {
            pagination: 1,
            search: this.marketSearch,
            favs: 1,
            zip: this.timblo.user?.zip_code,
            ...this.pagination,
            ...this.filters,
          },
        })
        .then(({ data }) => {
          this.marketList.push(data.data);

          this.current_page_markets = data.current_page;
          this.last_page_markets = data.last_page;
          this.total_markets = data.total;
          return data;
        });
    },

    getOrders() {
      this.$axios
        .get('/orders', {
          params: {
            user: this.timblo.user?.id,
            nextOrders: 1,
          },
        })
        .then(({ data }) => (this.nextOrders = data.orders));
    },

    selectProduct(product) {
      if (this.nextOrders.length) {
        this.selectedProduct = product;
        this.selectedProduct.observationsOrder =
          product?.observation?.observations || null;
        this.minQuantity = product.market_ingredients[0]?.min_weight;
        this.avgQuantity = product.market_ingredients[0]?.avg_weight;

        this.prdQuant = this.avgQuantity / this.avgQuantity || this.minQuantity || 1;
        this.setProduct = true;
      } else {
        this.openToastOptions(
          this.$t('Aviso!'),
          this.$t('Tiene que tener un pedido seleccionado')
        );
      }
    },

    addProductToOrder(product) {
      const favMarketIDs = this.timblo.user?.favorite_markets?.map((f) => f.id);
      let ingredientDisponibility;
      if (this.selectedMarket.id) {
        ingredientDisponibility = product.market_ingredients.find(
          (m) => m.ingredient_id == product.id && m.market_id == this.selectedMarket.id
        );
      } else if (
        favMarketIDs?.length &&
        product.market_ingredients.find((m) => favMarketIDs.includes(m.market_id))
      ) {
        ingredientDisponibility = product.market_ingredients.find((m) =>
          favMarketIDs.includes(m.market_id)
        );
      } else {
        ingredientDisponibility = product.market_ingredients[0]; // Cogemos la primera tienda en la que esté disponible el ingrediente
      }
      this.$axios
        .post('/orders/add_product', {
          params: {
            user: this.timblo.user?.id,
            prdQuant:
              ingredientDisponibility.avg_weight || ingredientDisponibility.min_weight,
            quantity: this.quantity,
            product: {
              ...product,
              disponibility: ingredientDisponibility,
            },
          },
        })
        .then(async () => {
          this.openToastOptions(
            this.$t('gracias'),
            this.$t('producto-anadido-a-su-pedido'),
            'success'
          );

          this.timblo.products = await this.$axios
            .get('/orders/products')
            .then(({ data }) => data);

          this.setProduct = false;
          this.$emit('selected');
        });
    },

    quantityAñadir() {
      this.quantity++;
    },
    quantityRestar() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        icon: informationCircle,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
    async presentAlertProduct(headerMsg) {
      const alert = await alertController.create({
        header: headerMsg,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => {
              this.moreThanLimit = true;
            },
          },
        ],
      });

      await alert.present();
    },
  },
});
</script>

<style lang="scss" scoped>
.productCard {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;

  & .card {
    &-header {
      text-align: center;
    }
    &-image {
      margin-top: 15px;
      margin-bottom: 15px;
      & img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    &-image.no-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 300px;
      border-radius: 10px;
      background-color: #cfd3cf;
      color: white;
    }
    &-content {
      &-market {
        display: flex;
        flex-direction: column;

        &-header {
        }
        &-content {
          p {
            font-weight: 600;

            span {
              font-weight: normal;
              font-size: 14px;
            }
          }
        }
      }
    }
    &-inputs {
      margin-top: 10px;
      margin-bottom: 10px;

      .input-quantity {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        min-height: 45px;
        margin-bottom: 10px;

        ion-input {
          text-align: center;
        }
      }

      & > ion-item::part(native) {
        margin-bottom: 15px;
        background-color: white;
        border-radius: 10px;
        padding-left: 10px;
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      .t-button {
        width: 100%;
      }
      .t-button:nth-child(1) {
        background-color: transparent;
        border: 1px solid black;
        color: black;
      }
    }
  }
}
.productFilter {
  margin-top: 20px;
  ion-item > ion-label {
    font-weight: 400;
  }
  ion-item > ion-input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > ion-item::part(native) {
    background-color: transparent;
    padding-left: 5px;
  }
}

ion-toolbar {
  --background: var(--ion-color-tertiary-shade);
  --border-color: transparent;
}
ion-modal > ion-content::part(background) {
  --background: var(--ion-color-tertiary-shade);
}

ion-modal > ion-content > ion-list {
  background-color: #fff;
  border-radius: 10px;
  & > ion-item > ion-label {
    font-family: 'Nunito Sans' !important;
    font-size: 16px !important;
    font-weight: 40 !important;
    white-space: normal !important;
  }

  & > ion-item::part(native) {
    padding-left: 10px;
  }
}

.recipesList {
  margin-top: 25px;

  & > ion-card > ion-img {
    width: 80px;
    height: 140px;
    object-fit: cover;
  }
}
.countOrder {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f4f8;
  border-radius: 24px;
  height: 36px;

  .value {
    text-align: center;
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
  }
}
</style>
