<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          tab="hogar"
          href="/home"
          :class="{ 'timblo-tab-selected': selectedRoute == 'home' }"
        >
          <ion-icon :icon="`assets/icons/Hogar.svg`" />
          <ion-label>{{ $t('home') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="nutrition"
          href="/nutrition"
          :class="{ 'timblo-tab-selected': selectedRoute == 'nutrition' }"
        >
          <ion-icon :icon="`assets/icons/Nutricion.svg`" />
          <ion-label>{{ $t('nutricion') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="wellness"
          href="/wellness"
          v-if="!oncologico"
          :class="{ 'timblo-tab-selected': selectedRoute == 'wellness' }"
        >
          <ion-icon :icon="`assets/icons/Bienestar.svg`" />
          <ion-label>{{ $t('bienestar') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="account"
          href="/order_list"
          :class="{ 'timblo-tab-selected': selectedRoute == 'order' }"
        >
          <ion-icon :icon="`assets/icons/CartIcon.svg`" />
          <ion-label>{{ $t('pedido') }}</ion-label>
          <ion-badge color="secondary" class="timblo-badge" v-if="timblo?.products">
            {{ timblo?.products }}
          </ion-badge>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  // IonBadge,
} from '@ionic/vue';
import { useTimbloStore } from '@/stores/timblo';
import { ellipse, square, triangle } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    // IonBadge,
  },

  setup() {
    const timblo = useTimbloStore();
    const oncologico = timblo?.user.oncological;
    return {
      ellipse,
      square,
      triangle,
      timblo,
      oncologico,
    };
  },

  computed: {
    selectedRoute() {
      return this.$route?.meta?.tab;
    },
  },
});
</script>

<style lang="scss" scoped>
ion-badge {
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-primary);
  font-weight: 700;
  display: flex;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 7px;
  font-size: 1em;
}
ion-tab-bar {
  height: 80px;
  --background: white;
  -webkit-box-shadow: 0px -2px 10px -2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px -2px 10px -2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px -2px 10px -2px rgba(0, 0, 0, 0.25);
  border: none;

  .timblo-tab-selected {
    position: relative;
    color: var(--ion-color-primary);
  }

  .timblo-tab-selected::before {
    content: '';
    display: block;
    height: 4px;
    width: 100%;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary);
    position: absolute;
  }
}
ion-tab-button {
  --color-selected: var(--ion-color-primary);

  ion-icon {
    font-size: 28px;
    margin: 0;

    svg {
      fill: #b7b7b7;
    }
  }

  ion-label {
    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    font-style: normal;
  }
}
</style>
