<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="card-toolbar">
        <ion-buttons slot="start">
          <ion-button @click="$router.go(-1)">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>{{ $t('direcciones') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <template v-if="addresses.length">
        <div style="margin: 120px 23px 0">
          <h1>Dirección</h1>
        </div>

        <template :key="`addresses-${index}`" v-for="(address, index) in addresses">
          <ion-card @click="defaultCart(address.id)">
            <ion-card-header>
              <b>{{ address.name }}</b>
              <img
                v-if="timblo.user.current_address == address.id"
                style="float: right"
                src="assets/icons/CartIcon.svg"
              />
            </ion-card-header>
            <ion-card-content>
              <img
                style="float: right"
                src="assets/icons/EditIcon.svg"
                @click.stop="
                  (row) => $router.push({ name: 'address', params: { id: address.id } })
                "
              />
              <h5>{{ address.address_line_1 }}</h5>
              <p>{{ address.address_line_2 }}</p>
              <p>{{ address.city }} ({{ address.zip_code }})</p>
              <p>{{ address.phone }}</p>
            </ion-card-content>
          </ion-card>
        </template>
      </template>

      <template v-else>
        <div style="margin: 2em; text-align: center">
          <h1>¡Ooops!</h1>
          <h1>{{ $t('no-hay-direccion-guardada') }}</h1>
        </div>

        <img
          style="
            display: block;
            margin-bottom: 2em;
            margin-top: 2em;
            margin-left: auto;
            margin-right: auto;
          "
          src="assets/images/NoAddress.svg"
        />
      </template>

      <t-button
        style="width: 90%; height: 43px; margin: 1em"
        @click="$router.push({ name: 'address' })"
        >{{ $t('anade-direccion') }}</t-button
      >

      <!-- <div style="display: flex; flex-direction: column;">
      <button>Regístrate con E-mail</button>
      <button>Regístrate con E-mail</button>
    </div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { ellipse, square, triangle, chevronBack } from 'ionicons/icons';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';

export default defineComponent({
  name: 'AddressesPage',

  components: {
    IonPage,
    IonContent,
    TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      ellipse,
      square,
      triangle,
      addresses: ref({}),
      timblo,
      chevronBack,
    };
  },

  ionViewWillEnter() {
    this.getAddresses().then(() => {
      if (this.addresses.length == 0) this.$router.push({ name: 'address' });
      return this.addresses;
    });
  },

  methods: {
    async getAddresses() {
      await this.$axios.get('addresses').then(({ data }) => {
        return this.addresses = data.data;
      });
    },

    defaultCart(address) {
      this.timblo.user.current_address = address;
      this.timblo.user.current_market = null;
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { full_name, ...x } = this.timblo.user;
      this.$axios
        .put('/users/', {
          user: x,
        })
        .then(() => {
          // this.$router.replace({ name:'address', params: {id: data.id}})
          // this.timblo.user.current_address = address;
          // this.timblo.user.current_market = null;
        });
    },
  },
});
</script>

<style scoped>
h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: var(--ion-color-primary);
}

h3 {
  color: var(--ion-color-primary);
}

h5 {
  margin-top: 20px !important;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

hr {
  border: 0.5px solid #d6d6d6 !important;
}

.itemAccount {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-top: 20px;
  align-items: center;
}
</style>
