<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="card-toolbar">
        <ion-buttons slot="start">
          <ion-button @click="$router.go(-1)">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>{{ $t('gasto-energetico') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <div style="text-align: center; margin: 40px 0 40px 0">
        ¡{{ $t('hola') }} {{ this.mealplan.name }}! {{ $t('timblo-te-ayuda-a-vivir-mejor-y-mas-sano-calculamos-el-gasto-energetico-con-la-informacion-que-nos-proporcionas-y-siguiendo-las-directrices-del-ministerio-de-sanidad-consumo-y-bienestar-social-del-gobierno-de-espana-y-de-la-autoridad-europea-de-salud-alimentaria-aqui-puedes-si-lo-deseas-modificar-tus-parametros') }}
      </div>

      <div
        style="
          background-color: #38518e;
          height: 100px;
          margin-bottom: 30px;
          padding: 40px 0 0 20px;
          color: white;
        "
      >
        {{ $t('gasto-energetico') }}
      </div>

      <ion-list>
        <ion-item>
          <ion-label>
            <div>{{ $t('total-calorias-recomendadas') }}</div>
            <div style="color: grey">{{ ~~dailyEnergyExpenditure }} kcal</div>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <div>{{ $t('nivel-de-actividad-fisica') }}</div>
            <div style="color: grey">
              {{ ~~(dailyEnergyExpenditure - basalMetabolicRate) }} kcal
            </div>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <div>{{ $t('metabolismo-basal') }}</div>
            <div style="color: grey">{{ ~~basalMetabolicRate }} kcal</div>
          </ion-label>
        </ion-item>
      </ion-list>

      <div
        style="
          background-color: #38518e;
          height: 100px;
          margin-bottom: 30px;
          padding: 40px 0 0 20px;
          color: white;
        "
      >
        {{ $t('nutrientes') }}
      </div>

      <ion-list>
        <ion-item :key="`proportion-${index}`" v-for="(p, index) in proportions">
          <ion-label>
            <div>
              {{ p?.nutrition_type?.name[timblo.lang] }}
            </div>
            <div style="color: grey">
              {{ ~~((dailyEnergyExpenditure * p?.percentage) / 100) }} kcal /
              {{ p?.percentage }}% balance
            </div>
          </ion-label>
        </ion-item>
      </ion-list>

      <!-- <t-button style="width: 90%; height: 43px; margin: 0px 0px 35px 35px;" @click="$router.push({ name: 'address'})">AÑADE DIRECCIÓN</t-button> -->

      <!-- <div style="display: flex; flex-direction: column;">
      <button>Regístrate con E-mail</button>
      <button>Regístrate con E-mail</button>
    </div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { ellipse, square, triangle, chevronBack } from 'ionicons/icons';
import { useTimbloStore } from '@/stores/timblo';

export default defineComponent({
  name: 'EnergyExpenditurePage',

  components: {
    IonPage,
    IonContent,
    // TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      ellipse,
      square,
      triangle,
      mealplan: ref({}),
      proportions: ref([]),
      timblo,
      chevronBack,
    };
  },

  created() {
    this.getMealplan();
  },

  computed: {
    //       public function getDailyEnergyExpenditureAttribute() {
    //     $week_value = 0;
    //     switch($this->week_workout) {
    //       case 1: $week_value = 0.06;
    //       case 2: case 3: $week_value = 0.13;
    //       case 4: case 5: $week_value = 0.22;
    //       case 6: case 7: $week_value = 0.30;
    //     }

    //     $energyExpenditure = $this->basal_metabolic_rate * ($this->activityLevel->factor + $week_value);

    //     switch($this->goal_id) {
    //       case 2: // Perder peso
    //       $energyExpenditure -= 500;
    //       break;

    //       case 3: // Ganar peso
    //       $energyExpenditure *= 1.15;
    //       break;
    //     }

    //     return $energyExpenditure;
    //   }

    //   TRAER , MEALPLAN,
    dailyEnergyExpenditure() {
      let $week_value = 0;
      switch (this.mealplan.week_workout) {
        case 1:
          $week_value = 0.06;
          break;
        case 2:
        case 3:
          $week_value = 0.13;
          break;
        case 4:
        case 5:
          $week_value = 0.22;
          break;
        case 6:
        case 7:
          $week_value = 0.3;
          break;
      }
      let $energyExpenditure;
      $energyExpenditure =
        this.basalMetabolicRate * (+this.mealplan.activity_level?.factor + $week_value);

      switch (this.mealplan.goal_id) {
        case 2: //perder peso
          $energyExpenditure -= 500;
          break;
        case 3: //Ganar peso
          $energyExpenditure += 1.15;
          break;
      }
      return $energyExpenditure;
      //   $energyExpenditure = $this->basal_metabolic_rate * ($this->activityLevel->factor + $week_value);
    },
    basalMetabolicRate() {
      return this.mealplan.gender_id == 1
        ? 655.0955 +
            9.5634 * this.mealplan.weight +
            1.8496 * this.mealplan.height -
            4.6756 * this.mealplan.age
        : 66.473 +
            13.7516 * this.mealplan.weight +
            5.0033 * this.mealplan.height -
            6.755 * this.mealplan.age;
    },
  },

  methods: {
    getMealplan() {
      this.$axios
        .get(`mealplans/mealplan/${this.$route.params.id}`, {})
        .then(({ data }) => {
          this.mealplan = data;
          this.getProportions({
            goal: data.goal_id,
            age: data.age,
            gender: data.gender_id,
          });
        });
    },

    getProportions(params) {
      this.$axios
        .get(`proportions`, {
          params,
        })
        .then(({ data }) => {
          this.proportions = data;
        });
    },
  },
});
</script>

<style scoped lang="scss">
h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: var(--ion-color-primary);
}

h3 {
  color: var(--ion-color-primary);
}

h5 {
  margin-top: 20px !important;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

hr {
  border: 0.5px solid #d6d6d6 !important;
}

.itemAccount {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-top: 20px;
  align-items: center;
}
</style>
