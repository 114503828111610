import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4bfb56e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    class: _normalizeClass(['t-button', { 't-outline': _ctx.outline }, {'t-disabled': _ctx.disabled}])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_ion_img, {
          key: 0,
          src: `assets/icons/${_ctx.icon}.svg`,
          style: {"margin-right":"3px"}
        }, null, 8, ["src"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}