import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* i18n */
import { createI18n } from 'vue-i18n';

import messages from '@/i18n';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const i18n = createI18n({
  locale: navigator.language,
  fallbackLocale: 'es',
  messages,
  legacy: false,
});

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Platform */
import { isPlatform } from '@ionic/vue';

/* Theme variables */
import './theme/variables.scss';
import './theme/timblo.scss';

/* Axios */
import axios from 'axios';
const api = axios.create({
  baseURL: 'https://api.timblo.es/api',
  // baseURL: 'http://timblo-api.test/api',
  headers: {
    Authorization: localStorage?.token
  }
});

/* Moment */
import moment from 'moment';
moment.locale('es');

/* Pinia */
import { createPinia } from 'pinia'
const pinia = createPinia();

/* Onesignal */
import OneSignal from 'onesignal-cordova-plugin';
document.addEventListener("deviceready", OneSignalInit, false);

function OneSignalInit(): void {
  OneSignal.setAppId("7d0cfd42-e351-457b-aef6-3131210ed628");
  OneSignal.setNotificationOpenedHandler(function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
  });

  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      console.log("User accepted notifications: " + accepted);
  });

  OneSignal.getDeviceState((state) => {
    app.config.globalProperties.$oneSignalState = state;
  });
}

/* UXCam */
declare const UXCam:any;

/* Full Story */
import * as FullStory from '@fullstory/browser';
FullStory.init({ orgId: 'o-1GNDP8-na1' });

const app = createApp(App)
  .use(IonicVue)
  .use(i18n)
  .use(pinia)
  .use(router)

api.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    router.push({ name: 'login' });
  }

  return Promise.reject(error);
});

// app.provide('axios', axios);

app.config.globalProperties.$axios = api;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$oneSignal = OneSignal;
app.config.globalProperties.$FullStory = FullStory;
app.config.globalProperties.$utils = {
  calculateNutrition: (ingredients: any, type = 'calorie') => {
    return ~~ingredients.reduce(
      (a: number, v: any) =>
        (+(v.nutrition_types.find((nt: any) => nt.slug == type)?.pivot?.value || 0) *
          v?.pivot?.quantity) /
          100 +
        a,
      0
    );
  },
};

Sentry.init({
  app,
  dsn: "https://94dd0ac4ad424a61a9aa517792135fb9@sentry.tecneca.com/8",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "web.timblo.es", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

router.isReady().then(() => {
  // UXCAM AND FULL STORY INSTANCE
  if(isPlatform('hybrid')){
    UXCam.optIntoSchematicRecordings();

    const configuration = {
        userAppKey: 'k1slv9tgle85u5o',
        enableAutomaticScreenNameTagging: false,
        enableImprovedScreenCapture: true,
    }

    UXCam.startWithConfiguration(configuration);
  }

  app.mount('#app');
});