<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-buttons>
        <ion-button @click="$router.go(-1)">
          <ion-icon :icon="chevronBack" style="color: black; font-size: 30px"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-searchbar
        class="ion-no-padding set_padding"
        :search-icon="`assets/home/search.svg`"
        :placeholder="$t('buscar-productos')"
        @click="
          () => {
            searchProductModal = true;
            productTag = '';
          }
        "
      />

      <div class="nutrition_types set_padding">
        <template v-for="btn in filterButtons" :key="`tag-${btn.value}`">
          <ion-card
            v-if="!userMPAllergens?.includes(btn.value.split(',')[0])"
            @click="
              () => {
                searchProductModal = true;
                productTag = btn.value;
              }
            "
          >
            <!-- <img :src="`assets/nutrition/${btn.icon}`" alt="Icon" /> -->
            <ion-icon :class="btn.icon" />
            <ion-card-header class="ion-no-padding">
              <ion-card-title>{{ $t(`${btn.label}`) }}</ion-card-title>
            </ion-card-header>
          </ion-card>
        </template>
      </div>

      <!-- Ofertas -->
      <div class="separator"></div>

      <div class="my_order set_padding">
        <div style="display: flex; justify-content: space-between; align-items: center">
          <h5 class="slider_header">
            {{ $t('ofertas') }}
          </h5>
        </div>

        <div class="my_order_list" v-if="featuredProductLoaded">
          <ion-card
            @click="
              $router.push({
                name: 'offer',
                params: { id: r.id },
                query: { portions: 0 },
              })
            "
            v-for="r in offers"
            :key="`new-recipe-${r.ingredient_market.ingredient.id}`"
            class="ion-no-margin"
          >
            <ion-img
              :src="`https://api.timblo.es/images/ingredients/${
                r?.ingredient_market?.image || r?.ingredient_market.ingredient.image
              }`"
              :alt="`recipe-${
                r?.ingredient_market?.image || r?.ingredient_market.ingredient.image
              }`"
            />
            <ion-card-header class="ion-no-padding">
              <h5>
                {{
                  r.ingredient_market.alias ||
                  r.ingredient_market.ingredient.name[timblo.lang]
                }}
              </h5>
            </ion-card-header>
            <ion-card-content class="card_content">
              <p>
                <!-- {{
                  `${
                    (r.offered_cost *
                      (r.ingredient_market.avg_weight ||
                        r.ingredient_market.ingredient.avg_weight)) /
                    1000
                  }€/${
                    !(
                      r.ingredient_market.avg_weight ||
                      r.ingredient_market.ingredient.avg_weight
                    ) && r.ingredient_market.ingredient.measure_unit.abbreviation == 'kg'
                      ? 'kg'
                      : r.ingredient_market.ingredient.measure_unit.abbreviation == 'g'
                      ? 'kg'
                      : r.ingredient_market.ingredient.measure_unit.abbreviation == 'ml'
                      ? 'l'
                      : r.ingredient_market.ingredient.measure_unit.abbreviation
                  }`
                }} -->
                {{
                  ` (${(
                    (+r.ingredient_market.cost *
                      (1 + percentages.percentage_timblo / 100) +
                      +r.ingredient_market.cost * percentages.percentage_timblo * 0.21) *
                    (r.ingredient_market.ingredient.measure_unit_id == 3
                      ? 1
                      : (r.ingredient_market.avg_weight || 1000) / 1000)
                  ).toFixed(2)}€/${
                    (r.ingredient_market.ingredient.avg_weight || 1000) &&
                    ['kg', 'g', 'ml'].includes(
                      r.ingredient_market.ingredient.measure_unit.abbreviation
                    )
                      ? 'ud'
                      : r.ingredient_market.ingredient.measure_unit.abbreviation
                  })`
                }}
              </p>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="my_order_list" v-if="!featuredProductLoaded">
          <TSkeleton />
        </div>
      </div>

      <!-- Productos detacados -->

      <div class="separator"></div>

      <div class="my_order set_padding">
        <div style="display: flex; justify-content: space-between; align-items: center">
          <h5 class="slider_header">
            {{ $t('Productos destacados') }}
          </h5>

          <a
            @click="
              () => {
                searchProductModal = true;
                productTag = '';
              }
            "
            >{{ $t('descubre-mas') }}</a
          >
        </div>

        <div class="my_order_list" v-if="featuredProductLoaded">
          <ion-card
            @click="
              $router.push({
                name: 'product',
                params: { id: r.id },
                query: { portions: 0 },
              })
            "
            v-for="r in featuredProduct"
            :key="`new-product-${r.id}`"
            class="ion-no-margin"
          >
            <ion-img
              :src="`https://api.timblo.es/images/ingredients/${r?.image}`"
              :alt="`product-${r.id}`"
            />
            <ion-card-header class="ion-no-padding">
              <h5>{{ r.name[timblo.lang] }}</h5>
            </ion-card-header>
            <ion-card-content class="card_content">
              <p>
                {{ `${productCost(r)}€ kg/ud` }}

                <!-- {{
                  `${r.base_cost}€/
                  ${
                    !r.avg_weight && r.measure_unit.abbreviation == 'kg'
                      ? 'kg'
                      : r.measure_unit.abbreviation == 'g'
                      ? 'kg'
                      : r.measure_unit.abbreviation == 'ml'
                      ? 'l'
                      : r.measure_unit.abbreviation
                  }`
                }} -->
              </p>
            </ion-card-content>
          </ion-card>
        </div>
        <div class="my_order_list" v-if="!featuredProductLoaded">
          <TSkeleton />
        </div>
      </div>
    </ion-content>

    <TProductFinder
      v-model="searchProductModal"
      :timblo_fee="+percentages.percentage_timblo"
      :tag="productTag"
    />
  </ion-page>
</template>

<script>
import { ref, defineComponent } from 'vue';

// Components
import TProductFinder from '@/components/TProductFinder.vue';
import TSkeleton from '@/components/TSkeleton.vue';

import { useTimbloStore } from '@/stores/timblo';

import { IonPage, IonContent, IonSearchbar, IonCard, IonCardHeader } from '@ionic/vue';

import { chevronBack } from 'ionicons/icons';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ProductsPage',
  components: {
    IonContent,
    IonPage,
    IonSearchbar,
    IonCard,
    IonCardHeader,
    TProductFinder,
    TSkeleton,
    // IonCardContent,
  },

  setup() {
    const timblo = useTimbloStore();
    const { t } = useI18n();

    const filterButtons = [
      {
        icon: 'icon ico-ingredient-fruit',
        label: t('fruta-y-verdura'),
        value: 'fruta,verdura',
      },
      {
        icon: 'icon ico-cart',
        label: t('descubre-mas'),
        value: 'hogar,persona',
      },
      {
        icon: 'icon ico-ingredient-protein-red',
        label: t('carne'),
        value: 'carne',
      },
      {
        icon: 'icon ico-ingredient-seafood-fish',
        label: t('pescado'),
        value: 'pescado',
      },
    ];

    return {
      searchProductModal: ref(false),
      featuredProductLoaded: ref(false),
      featuredProduct: ref([]),
      productTag: ref(null),
      offers: ref({}),
      percentages: ref({
        percentage_timblo: ref(0.0),
      }),
      filterButtons,

      // ICONS
      chevronBack,

      // STORES
      timblo,
    };
  },

  created() {
    this.getProducts();
    this.getOffers();
    this.getPreferences();
  },

  computed: {
    userMPAllergens() {
      return this.timblo.user?.mealplans
        ?.map((m) => m.allergens)
        .flat()
        .map((m) => m.name.es.toLowerCase());
    },
  },

  methods: {
    async getProducts() {
      const allergens = this.timblo.user?.mealplans
        ?.map((m) => m.allergens)
        .flat()
        .map((m) => m.id);
      return await this.$axios
        .get('ingredients', {
          params: {
            allergens,
            disponibility: this.timblo.user?.zip_code,
            featured: 1,
            limit: 6,
            rand: this.rand,
          },
        })
        .then(({ data }) => {
          this.featuredProduct = data;
        })
        .finally(() => {
          this.featuredProductLoaded = true;
        });
    },

    getPreferences() {
      this.$axios
        .get('settings/percentage', {
          params: {
            tab: 'benefit',
          },
        })
        .then(({ data }) => {
          this.percentages.percentage_timblo = data.percentage_timblo;
        });
    },

    getOffers() {
      return this.$axios
        .get('/offers')
        .then(({ data }) => {
          this.offers = data;
        })
        .finally(() => {
          this.offersLoaded = true;
        });
    },

    productCost(product) {
      const coste = product.market_user ? product.market_user.cost : product.base_cost;
      const iva = coste * (+this.timblo.user.benefit_percentage / 100) * 0.21;
      return (
        (coste * (1 + this.timblo.user.benefit_percentage / 100) + iva) *
        (product.measure_unit_id == 3
          ? 1
          : (product.market_ingredients[0]?.avg_weight || 1000) / 1000)
      ).toFixed(2);
    },

    offerCost(product) {
      console.log(product);
      const coste = product.market_user ? product.market_user.cost : product.offered_cost;
      const iva = coste * (+this.timblo.user.benefit_percentage / 100) * 0.21;
      return (
        (coste * (1 + this.timblo.user.benefit_percentage / 100) + iva) *
        (product.measure_unit_id == 3
          ? 1
          : (product.ingredient_market[0]?.avg_weight || 1000) / 1000)
      ).toFixed(2);
    },
  },
});
</script>

<style lang="scss" scoped>
ion-content {
  --background: var(--ion-color-tertiary-shade);
  // --padding-start: 24px;
  // --padding-end: 24px;
  --padding-top: 24px;
}
.set_padding {
  padding-left: 24px;
  padding-right: 24px;
}
ion-searchbar {
  --background: #fff;
  --border-radius: 10px;
  margin-bottom: 20px;
  & > div > input {
    border: 1px solid var(--ion-color-medium);
  }
}
.page_header {
  margin-bottom: 20px;
}

.separator {
  display: block;
  width: 100%;
  height: 0.5px;
  background-color: #d6d6d6;
  margin-bottom: 20px;
  left: 0;
}

// Nutrition Types
.nutrition_types {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 27px;
  row-gap: 10px;
  column-gap: 20px;

  & > ion-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --background: var(--ion-color-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-width: 150px;
    min-height: 55px;
    width: 100%;
    height: 100%;
    flex: 25%;

    & > img {
      width: 24px;
      height: 24px;
    }

    ion-icon {
      font-size: 24px;
    }

    & > ion-card-header > ion-card-title {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

// My order
.my_order {
  margin-bottom: 27px;

  .slider_header {
    & > .edit_order {
      float: right;
      font-size: 16px;
    }
    margin-bottom: 17px;
  }
  .my_order_list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    & ion-card {
      flex: 0 0 auto;
      margin-right: 16px;
      width: 200px;
      box-shadow: none;

      ion-img {
        height: 150px;
        max-height: 200px;
        width: 100%;
        object-fit: cover;
      }

      ion-card-header {
        padding: 10px 10px 0px 10px;
        & > h5 {
          font-size: 18px;
          margin: 0;
        }
      }

      ion-card-content.card_content {
        padding: 0px 10px;
        & > p {
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
}
</style>
