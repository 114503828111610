<template>
  <ion-page>
    <ion-content fullscreen>
      <!-- <img
        @click="this.$router.go(-1)"
        style="float: left; margin-top: 60px; margin-left: 20px"
        src="assets/icons/ChevronLeft.svg"
      /> -->

      <div class="timblo-card-photo">
        <ion-img
          v-if="market.image"
          :src="`https://api.timblo.es/images/markets/${market.image}`"
        />

        <ion-toolbar class="card-toolbar">
          <ion-button shape="round" @click="$router.go(-1)">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>

          <ion-button
            @click="toggleFav(market)"
            slot="end"
            shape="round"
            style="--color: #e28e9d"
          >
            <ion-icon :icon="market.favourites_count ? heart : heartOutline"></ion-icon>
          </ion-button>
        </ion-toolbar>
      </div>

      <!-- <ion-item
        class="ion-no-padding"
        style="margin-left: 27px; margin-top: 130px"
        lines="none"
      >
        <b>{{ market.name }}</b>
        <img width="186" height="150" slot="end" src="assets/home/recipe_sample.jpeg" />
      </ion-item> -->
      <div class="ion-padding">
        <h1>{{ market.name }}</h1>
      </div>

      <div class="ion-padding">
        <label>{{ $t('biografia') }}</label>
        <p>{{ market.description }}</p>
      </div>

      <div class="ion-padding">
        <label>{{ $t('Especialidades') }}</label>
        <p>{{ market.specialities }}</p>
      </div>

      <div class="ion-padding">
        <label>{{ $t('pasa-a-conocernos') }}</label>
        <p> {{market.address}} </p>
        <ul>
          <li v-for="(s, index) in market.schedule" :key="`schedule-${index}`">
            {{ s.day }}
          </li>
        </ul>
      </div>

      <div class="ion-padding">
        <t-button @click="addingProducts = true">{{ $t('comprar-en-esta-tienda') }}</t-button>
      </div>

      <!-- <div style="display: flex; flex-direction: column;">
      <button>Regístrate con E-mail</button>
      <button>Regístrate con E-mail</button>
    </div> -->
    </ion-content>

    <TProductFinder v-model="addingProducts" :timblo_fee="+this.percentages.percentage_timblo" :market="market"></TProductFinder>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { IonContent, IonPage, IonImg } from '@ionic/vue';

import TButton from '@/components/TButton.vue';
import TProductFinder from '@/components/TProductFinder.vue';

import { heartOutline, heart, chevronBack } from 'ionicons/icons';

export default defineComponent({
  name: 'MarketPage',

  components: {
    IonPage,
    IonContent,
    IonImg,
    TButton,
    TProductFinder,
  },

  setup() {
    return {
      market: ref({}),
      addingProducts: ref(false),
      percentages: ref({
        percentage_timblo: ref(0.00),
      }),

      chevronBack,
      heart,
      heartOutline,
    };
  },

  created() {
    const id = this.$route.params.id;
    this.getMarket(id);
    this.getPreferences();
  },

  methods: {
    getMarket(id) {
      this.$axios.get(`/markets/${id}`).then(({ data }) => {
        this.market = data;
      });
    },

    toggleFav(market) {
      market.favourites_count = 1 - market.favourites_count;
      this.$axios.post('/markets/toggle_fav', {
        market,
      });
    },

    getPreferences() {
      this.$axios
        .get('settings/percentage', {
          params: {
            tab: 'benefit',
          },
        })
        .then(({ data }) => {
          this.percentages.percentage_timblo = data.percentage_timblo;
        });
    }
  },
});
</script>

<style scoped lang="scss">
h1 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  color: var(--ion-color-primary);
}

h3 {
  color: var(--ion-color-primary);
}

h5 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.discover_more {
  font-family: 'Nunito Sans';
  font-style: normal;
  margin-left: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.slider_header {
  margin-bottom: 17px;
  & > span {
    float: right;
  }
}

.news {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  & ion-card {
    flex: 0 0 auto;
    margin-right: 16px;
    max-width: 200px;
    box-shadow: none;
    background-color: transparent;

    img {
      width: 110px;
      height: 94px;
      object-fit: cover;
      object-position: top;
    }

    ion-card-header {
      & > h5 {
        font-size: 18px;
        margin: 0;
        text-align: center;
      }
    }

    ion-card-content.card_content {
      padding: 0px 10px;
      & > p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
      }
    }
  }
}

.timblo-card-photo {
  position: relative;
  overflow: hidden;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-img {
    width: 100%;
  }

  & .card-toolbar {
    position: fixed;
    top: 1.2em;
    --background: transparent;
    --ion-color-base: transparent !important;

    & ion-button {
      --background: #fff;

      &::part(native) {
        padding: 12px;
      }
    }
  }
}
</style>
