<template>
<ion-page>
    <ion-content fullscreen>
          <img
          @click="this.$router.go(-1)"
          style="float:left; margin-top: 60px; margin-left: 20px" src="assets/icons/ChevronLeft.svg"/>

    <div style="margin: 120px 23px 0;">
      <h1>{{ $t('que-ha-pasado-con-tu-pedido') }}</h1>
      <label>{{ $t('elije-una-de-las-opciones-aqui-abajo') }}</label>

    </div>

    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('no-ha-llegado-algo-de-lo-que-he-pedido') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('me-ha-llegado-producto-en-mal-estado') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('no-estoy-satisfecho-a-con-la-calidad-del-producto') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('quiero-cambiar-proveedor-de-alimentos') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('los-ingredientes-no-me-gustan') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('quiero-anadir-usuario-a-mi-timblo') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('no-he-realizado-ningun-pedido-y-he-recibido-productos-en-casa') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('he-comprado-un-producto-en-oferta-y-no-ha-llegado') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('mi-pedido-tiene-productos-de-otro-usuario-a') }}</t-button>
    <t-button style="font-size:14px; width: 344px; height: 59px; margin: 0px 24px 16px;" @click="null">{{ $t('mi-pedido-no-ha-llegado') }}</t-button>
    </ion-content>
    </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { ellipse, square, triangle } from 'ionicons/icons';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';


export default defineComponent({
  name: 'HelpQuestionsPage',

  components: {
    IonPage,
    IonContent,
    TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      ellipse,
      square,
      triangle,
      timblo,

    }
  },

    created() {
    // this.comprobarCodigo();
  },

  methods: {
    // comprobarCodigo() {
    //   this.$axios
    //     .post('discount', {
    //       discount_code: this.timblo.user?.active_coupon?.coupon?.code,
    //       id_usuario: this.timblo.user.id
    //     })
    //     .then(({ data }) => {
    //         this.newCode = data;
    //     });
    // },
  }

});
</script>

<style scoped>

h1 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;

    color: var(--ion-color-primary);
    margin: 19px 73px 15px 16px;

}

h3 {
    color: var(--ion-color-primary);
}

h5 {
    margin-top: 20px !important;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}


</style>