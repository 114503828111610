<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div>
        <ion-img
          style="margin: 33px 108px 0px 129px"
          src="assets/logo/logo.svg"
        ></ion-img>
      </div>

      <div class="subtitulo">
        <p>{{ $t('tu-comunidad-de-bienestar-te-esta-esperando') }}</p>
        <p style="margin-top: -10px; color: black">LOGIN</p>
      </div>

      <div>
        <ion-img
          style="margin: 24px 62px 18px 65px"
          src="assets/images/workout.svg"
        ></ion-img>
      </div>

      <div class="subtitulo2">
        <span> {{ $t('sumate-a-la-comunidad-de-bienestar') }}</span>
        <span
          @click="$router.push({ name: 'signup' })"
          style="color: var(--ion-color-secondary)"
        >
          SIGN UP</span
        >
      </div>
      <div style="margin: 10px 24px 0">
        <div>
          <input
            v-model="credentials.email"
            :placeholder="$t('direccion-email')"
            name="name"
            type="text"
            style="width: 100%"
          />
        </div>

        <div style="margin-top: 15px">
          <input
            v-model="credentials.password"
            :placeholder="$t('contrasena')"
            name="password"
            type="password"
            style="width: 100%"
          />
        </div>
        <div style="margin-top: 10px">
          <p
            @click="$router.push({ name: 'reset' })"
            style="color: var(--ion-color-primary)"
          >
            <b>{{ $t('olvidaste-tu-contrasena') }}</b>
          </p>
        </div>
        <t-button style="margin-top: 16px" @click="login()">LOG IN</t-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';

import { IonPage, IonContent, toastController, getPlatforms } from '@ionic/vue';

export default defineComponent({
  name: 'LoginPage',

  components: {
    IonPage,
    IonContent,
    TButton,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      timblo,
      lang: timblo?.lang,
      toastController,

      credentials: ref({}),
    };
  },

  created() {
    //
  },

  methods: {
    login() {
      this.$axios
        .post('/login', {
          ...this.credentials,
          platforms: getPlatforms(),
          lang: this.lang,
        })
        .then(async ({ data }) => {
          if (data.message == 'User fail') {
            this.openToastOptions(
              this.$t('Error al iniciar sesion'),
              'El usuario no existe'
            );
          } else if (data.message == 'Password fail') {
            this.openToastOptions(
              this.$t('Error al iniciar sesion'),
              'Contraseña incorrecta'
            );
          } else {
            this.timblo.user = data.user;
            this.timblo.token = `${data.token_type} ${data.access_token}`;
            this.timblo.available = data.available;
            this.$axios.defaults.headers = {
              common: { Authorization: `${data.token_type} ${data.access_token}` },
            };

            this.timblo.products = await this.$axios
              .get('/orders/products')
              .then(({ data }) => data);

            this.$router.replace({ name: 'home' });
          }
        });
    },

    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        header,
        message,
        position: 'top',
        color,
        duration: '4000',
        buttons: [
          {
            text: this.$t('cerrar'),
            role: 'cancel',
          },
        ],
      });

      await toast.present();
    },
  },
});
</script>

<style lang="scss" scoped>
h1 {
  color: var(--ion-color-primary);
}

input {
  width: 90%;
  padding-left: 16px;
  height: 48px;
  border: 1px solid #7a7a7a;
  border-radius: 5px;
}

input::placeholder {
  font-family: 'Nunito Sans';
  color: var(--ion-color-primary-shade);
}

.subtitulo {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--ion-color-primary);
  padding-left: 45px;
  padding-right: 35px;
}
.subtitulo2 {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  text-align: left;
  color: black;
  padding-left: 45px;
  padding-right: 35px;
}
</style>
