<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="ion-margin" style="text-align: center">
        <div class="register-header">
          <ion-buttons>
            <ion-button shape="round" @click="$router.go(-1)">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
          </ion-buttons>
          <h1>{{ $t('crear-cuenta') }}</h1>
        </div>
      </div>

      <div style="margin: 0 24px 0">
        <div>
          <label for="name"><h5>{{ $t('nombre') }}</h5></label>
          <input
            v-model="register.name"
            :placeholder="$t('nombre')"
            name="name"
            type="text"
            style="width: 100%"
          />
        </div>

        <div>
          <label for="email"><h5>Email</h5></label>
          <input
            v-model="register.email"
            :placeholder="$t('email')"
            name="email"
            type="email"
            style="width: 100%"
          />
        </div>

        <div>
          <label for="password"><h5>{{ $t('contrasena') }}</h5></label>
          <input
            v-model="register.password"
            :placeholder="$t('contrasena')"
            name="password"
            type="password"
            style="width: 100%"
          />
        </div>

        <div>
          <label for="zip_code"><h5>{{ $t('codigo-postal') }}</h5></label>
          <input
            v-model="register.zip_code"
            :placeholder="$t('codigo-postal')"
            name="zip_code"
            type="text"
            style="width: 100%"
          />
        </div>

        <div>
          <label for="zip_code"><h5>{{ $t('tienes-codigo-descuento') }}</h5></label>
          <input
            v-model="register.code"
            :placeholder="$t('codigo')"
            type="text"
            style="width: 100%"
          />
        </div>
      </div>

      <div style="margin: 16px 16px">
        <!-- <ion-item lines="none">
          <ion-label>¿Quieres abrir una cuenta PRO?</ion-label>
          <ion-checkbox v-model="register.pro" slot="end"></ion-checkbox>
        </ion-item> -->

        <div class="texts" lines="none">
          <p @click="texts('terms')" style="text-decoration: underline">
            {{ $t('acepto-los-terminos-de-timblo') }}
          </p>
          <ion-checkbox v-model="register.terms" slot="end"></ion-checkbox>
        </div>

        <div class="texts" lines="none">
          <p @click="texts('policy')" style="text-decoration: underline">
            {{ $t('aceptas-privacidad') }}
          </p>
          <ion-checkbox v-model="register.privacy" slot="end"></ion-checkbox>
        </div>
      </div>

      <div style="margin: 16px 24px">
        <t-button @click="registerAccount(register)">{{ $t('crear-cuenta') }}</t-button>
      </div>

      <ion-modal :is-open="isOpen">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ info.title }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="isOpen = false">Cerrar</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <span v-html="info.content"></span>
        </ion-content>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import {
  ellipse,
  square,
  triangle,
  closeCircleOutline,
  chevronBack,
} from 'ionicons/icons';

import TButton from '@/components/TButton.vue';
import { useTimbloStore } from '@/stores/timblo';

import {
  IonPage,
  IonModal,
  IonContent,
  IonCheckbox,
  loadingController,
  toastController,
  getPlatforms,
} from '@ionic/vue';

export default defineComponent({
  name: 'RegisterPage',

  components: {
    TButton,
    IonPage,
    IonContent,
    IonCheckbox,
    IonModal,
  },

  setup() {
    const timblo = useTimbloStore();

    return {
      isOpen: ref(false),
      timblo,
      info: ref({}),

      ellipse,
      square,
      triangle,
      closeCircleOutline,
      chevronBack,

      register: ref({
        pro: ref(false),
        terms: ref(false),
        privacy: ref(false),
      }),
    };
  },

  methods: {
    async openToastOptions(header, message, color = 'danger') {
      const toast = await toastController.create({
        message: `${header} ${message}`,
        position: 'top',
        color: color,
        duration: 3000,
        icon: closeCircleOutline,
      });

      await toast.present();
    },

    texts(type) {
      this.$axios.get('text').then(({ data }) => {
        this.info = data.find((d) => d.slug === type);
      });

      this.isOpen = true;
    },

    async registerAccount(register) {
      register.platforms = getPlatforms();
      register.password_confirmation;
      register.address;
      register.city;
      if (!this.register.name) {
        this.openToastOptions(
          'Error al registrar el usuario',
          'Tiene que introducir el Nombre para continuar.'
        );
      } else if (!this.register?.email) {
        this.openToastOptions(
          'Error al registrar el usuario',
          'Tiene que introducir el Email para continuar.'
        );
      } else if (!this.register?.password) {
        this.openToastOptions(
          'Error al registrar el usuario',
          'Tiene que introducir el Contraseña para continuar.'
        );
      } else if (!this.register?.zip_code || this.register?.zip_code.length != 5) {
        this.openToastOptions(
          'Error al registrar el usuario',
          'Introduzca un Código postal valido para continuar.'
        );
      } else if (!this.register?.terms) {
        this.openToastOptions(
          'Error al registrar el usuario',
          'Tiene que aceptar los terminos y condiciones'
        );
      } else if (!this.register?.privacy) {
        this.openToastOptions(
          'Error al registrar el usuario',
          'Tiene que aceptar los terminos de privacidad'
        );
      } else {
        const loading = await loadingController.create({
          message: 'Registrando usuario...',
        });

        loading.present();
        this.$axios
          .post('register', register)
          .then(({ data }) => {
            this.timblo.user = data.user;
            this.timblo.token = `${data.token_type} ${data.access_token}`;
            this.timblo.available = data.available;
            this.$axios.defaults.headers = {
              common: { Authorization: `${data.token_type} ${data.access_token}` },
            };

            this.$router.push({ name: 'home' });
          })
          .catch(async (error) => {
            const message = error.response.data.message;
            let column = '';
            if (message.search('1048') > 0) {
              column = message?.split('1048')[1]?.split('(')[0].split("'")[1]; // Null Keys
            } else if (message.search('1062') > 0) {
              column = column = message?.split('1062')[1]?.split('(')[0].split("'")[1]; // Duplicate Keys
            }
            switch (column) {
              case 'name':
                column = 'Nombre';
                break;
              case 'address_line_1':
                column = 'Dirección';
                break;
              case 'city':
                column = 'Ciudad';
                break;
              case 'zip_code':
                column = 'Código postal';
                break;
              default:
                break;
            }
            this.openToastOptions(
              'Error al registrar el usuario',
              `${
                column.length <= 0
                  ? this.$t(message)
                  : message.search('1048') > 0
                  ? this.$t(`El campo ${column} no puede estar vacio`)
                  : this.$t(`El usuario con el email '${column}' ya existe`)
              }`
            );
          })
          .finally(() => {
            loading.dismiss();
          });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.texts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
h1 {
  color: var(--ion-color-primary);
}

input::placeholder {
  font-family: 'Nunito Sans';
  color: var(--ion-color-primary-shade);
}
.register-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
