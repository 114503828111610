<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="card-toolbar">
        <ion-buttons slot="start">
          <ion-button @click="$router.go(-1)">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ $t('familiares') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <div style="float: right; margin: 50px 23px 0">
        <h4 @click="mealplanModal = true" style="text-decoration: underline">
          {{ $t('agregar-familiar') }}
        </h4>
      </div>

      <div style="margin: 115px 23px 0">
        <h3></h3>
      </div>

      <template :key="`family-${index}`" v-for="(member, index) in family">
        <div style="margin-left: 23px; margin-bottom: 16px">
          <ion-icon
            v-if="!member.is_admin"
            size="large"
            @click="deleteMealplan(member.id)"
            style="float: right; margin-right: 21px"
            class="icon ico-delete"
          ></ion-icon>
          <ion-icon
            size="large"
            @click="(selectedMealPlan = member), (mealplanModal = true)"
            style="float: right; margin-top: 2px; margin-right: 21px"
            :icon="createOutline"
          ></ion-icon>
          <!-- <img @click="selectedMealPlan = member , mealplanModal = true" style="float:right;margin-top:2px ;margin-right: 21px" src="assets/icons/EditIcon.svg"> -->
          <h4>{{ member.name + ' ' + (member.last_name ?? '') }}</h4>
        </div>
        <div style="margin-left: 23px">
          <div
            @click="
              $router.push({ name: 'energy_expenditure', params: { id: member.id } })
            "
            class="itemAccount"
          >
            <label> {{ $t('calculo-energetico') }}</label>
            <ion-icon :icon="chevronForwardOutline" />
          </div>
          <div
            @click="$router.push({ name: 'feeding', params: { id: member.id } })"
            class="itemAccount"
          >
            <label> {{ $t('alimentacion') }}</label>
            <ion-icon :icon="chevronForwardOutline" />
          </div>
        </div>
      </template>

      <!-- <div style="display: flex; flex-direction: column;">
      <button>Regístrate con E-mail</button>
      <button>Regístrate con E-mail</button>
    </div> -->
    </ion-content>
    <ion-modal :is-open="mealplanModal" :initial-breakpoint="1">
      <ion-content>
        <t-swiper
          @closeSwiper="mealplanModal = false"
          relatives
          :mealplan_prop="selectedMealPlan"
          :age-check="false"
          @done="saveMealplan"
        ></t-swiper>
      </ion-content>
    </ion-modal>

    <ion-modal id="success" :is-open="successModal" :initial-breakpoint="1">
      <ion-icon
        @click="successModal = false"
        size="large"
        style="margin: 23px 39px 0 264px"
        :icon="closeOutline"
      ></ion-icon>
      <ion-content class="ion-padding">
        <div class="ion-margin-top" style="position: center; margin: 0px 0 37px 100px">
          <h4>Que bien!</h4>
        </div>
        <div>
          <h5>{{ $t('acabas-de-agregar-a-un-familiar') }}</h5>
        </div>
        <div @click="successModal = false" style="margin: 36px 0 0 105px">
          <a>Continuar</a>
        </div>
        <img
          @click="this.$router.go(-1)"
          style="margin: 17px 30px 0px"
          src="assets/images/eatingTogether.svg"
        />
      </ion-content>
    </ion-modal>

    <ion-modal id="success" :is-open="errorModal" :initial-breakpoint="1">
      <ion-icon
        @click="errorModal = false"
        size="large"
        style="margin: 23px 39px 0 264px"
        :icon="closeOutline"
      ></ion-icon>
      <ion-content class="ion-padding ion-text-center">
        <div class="ion-margin-top">
          <h4>{{ $t('algo-no-ha-ido-bien') }}</h4>
        </div>
        <div>
          <h5>{{ $t('parece-que-ya-tienes-un-familiar-igual-anadido') }}</h5>
        </div>
        <div @click="errorModal = false">
          <a>{{ $t('continuar') }}</a>
        </div>
        <img
          @click="this.$router.go(-1)"
          style="margin: 17px 30px 0px"
          src="assets/images/EatingTogether.svg"
        />
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import {
  trashOutline,
  createOutline,
  chevronForwardOutline,
  closeOutline,
  chevronBack,
} from 'ionicons/icons';
import { IonContent, IonPage, IonModal, IonIcon } from '@ionic/vue';
import { useTimbloStore } from '@/stores/timblo';
import TSwiper from '@/components/TSwiper.vue';

export default defineComponent({
  name: 'RelativesPage',

  components: {
    IonPage,
    IonContent,
    IonModal,
    IonIcon,
    TSwiper,
  },

  watch: {
    mealplanModal(val) {
      if (!val) {
        this.selectedMealPlan = {};
      }
    },
  },
  setup() {
    const timblo = useTimbloStore();

    return {
      family: ref({}),
      mealplanModal: ref(false),
      selectedMealPlan: ref({}),
      successModal: ref(false),
      errorModal: ref(false),
      timblo,
      trashOutline,
      createOutline,
      chevronForwardOutline,
      closeOutline,
      chevronBack,
    };
  },

  ionViewWillEnter() {
    this.getFamily();
  },

  methods: {
    getFamily() {
      this.$axios.get(`/mealplans/${this.timblo.user.id}`).then(({ data }) => {
        this.family = data;
      });
    },

    saveMealplan(mealplan) {
      if (mealplan.id) {
        this.$axios
          .put('/mealplans/update_relative', {
            mealplan: {
              ...mealplan.data,
              id: mealplan.id,
            },
            exercise: mealplan?.exercises?.selected,
            goal: mealplan?.goals?.selected,
            level: mealplan?.levels?.selected,
            lifestyle: mealplan?.lifestyles?.selected,
            food_number: mealplan?.food_number,
            food_times: mealplan?.food_times,
          })
          .finally(() => {
            this.mealplanModal = false;
            this.getFamily();
          });
      } else {
        this.$axios
          .post('/mealplans/new_relative', {
            user: this.timblo.user.id,
            ...mealplan.data,
            exercise: mealplan?.exercises?.selected,
            goal: mealplan?.goals?.selected,
            level: mealplan?.levels?.selected,
            lifestyle: mealplan?.lifestyles?.selected,
            food_number: mealplan?.food_number,
            food_times: mealplan?.food_times,
          })
          .then(({ data }) => {
            this.mealplanModal = false;
            this.successModal = true;
            this.getFamily();
            console.log(data); //FIXME: Eslint
          })
          .catch(() => {
            this.errorModal = true;
          });
      }
    },

    deleteMealplan(mealplan_id) {
      this.$axios.delete(`mealplans/mealplan/${mealplan_id}`).then(() => {
        this.getFamily();
      });
    },
  },
});
</script>

<style scoped lang="scss">
h3 {
  color: var(--ion-color-primary);
}

h5 {
  margin-top: 20px !important;
}

label {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

hr {
  border: 0.5px solid #d6d6d6 !important;
}

.itemAccount {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-top: 20px;
  align-items: center;
}

ion-modal#success {
  --width: 318px;
  --height: 495px;
  h4 {
    color: var(--ion-color-primary);
  }

  h5 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* or 120% */

    text-align: center;
  }
}
</style>
