<template>
<div class="skeleton-content">
  <ion-card
    v-for="r in [1,2,3,4,5,6]"
    :key="`skeleton-${r}`"
    class="ion-no-margin skeleton-card"
    >
    <div class="skeleton-card-image skeleton"></div>
    <ion-card-header class="ion-no-padding">
        <div class="skeleton-card-header skeleton"></div>
    </ion-card-header>
    <ion-card-content class="skeleton-card_content">
        <div class="skeleton-card-body skeleton"></div>
        <div class="skeleton-card-body skeleton"></div>
        <div class="skeleton-card-body skeleton"></div>
        <div class="skeleton-card-body skeleton"></div>
    </ion-card-content>
  </ion-card>
</div>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
} from '@ionic/vue';
export default {
  name: 'TSkeleton',

  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
  }
}
</script>