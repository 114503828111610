import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { useI18n } from 'vue-i18n';

const supportedLangs = ['en', 'es', 'ca', 'it'];

export const useTimbloStore = defineStore('timblo', {
    state: () => {
        return {
            user: useStorage('user', {}),
            token: useStorage('token', null),
            available: useStorage('available', 0), // Número de tiendas disponibles
            products: useStorage('products', 0), // Número de productos en la cesta
            aboutMe_nutritionModal: useStorage('aboutMe_nutritionModal', 0), // [Vista Sobre Mi] Aviso modal plan nutricional
            aboutMe_allergensModal: useStorage('aboutMe_allergensModal', 0), // [Vista Sobre Mi] Aviso modal alergenos
            order_ExtraImprescendibles: useStorage('order_ExtraImprescendibles', 0), // [Vista Sobre Mi] Aviso modal alergenos
        }
    },

    getters: {
        lang: () => {
            const { locale } = useI18n();
            const navigatorLang = locale.value.split('-')[0];

            return supportedLangs.includes(navigatorLang) ? navigatorLang : 'es';
        },
    },
})